<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Deals Page CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Deals Page CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body" style="display: flex; align-items: center;">
          <h5 style="margin: 0;">{{stateName}}</h5>
          <div
            style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
            @click="$router.push({ path: '/deals-page-state-list'})"
          >
            <span style="margin-right: 5px;">
              <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
            </span>
            <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
          </div>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Deals Page CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <h5 class="card-title">Section One CMS</h5>
                  <hr />
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row">
                      <div class="col-6 mb-3">
                        <label class="form-label">Section one title:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealPageTitle"
                          class="form-control"
                          placeholder="Enter title"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Section one sub title:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealPageSubTitle"
                          class="form-control"
                          placeholder="Enter sub title"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Section one category title:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealCategoryTitle"
                          class="form-control"
                          placeholder="Enter category title"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Section one button one text:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealCategoryButtonOneTitle"
                          class="form-control"
                          placeholder="Enter button text"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Section one button one link:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealCategoryButtonOneLink"
                          class="form-control"
                          placeholder="Enter button link"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Section one button two text:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealCategoryButtonTwoTitle"
                          class="form-control"
                          placeholder="Enter button text"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Section one button two link:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealCategoryButtonTwoLink"
                          class="form-control"
                          placeholder="Enter button link"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label for="inputProductTitle" class="form-label">
                          Section one background image:
                          <strong>
                            [Preferred Image Size: 1366X607, Max Upload Limit:
                            1MB]
                          </strong>
                        </label>
                        <input
                          class="form-control mb-3"
                          type="file"
                          ref="shopDealSectionOneBackgroundImage"
                          accept="image/*"
                          @change="handleUploadImage('shopDealSectionOneBackgroundImage','shopDealSectionOneBackgroundImage','shopDealSectionOneBackgroundImage', $event)"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-if="preview.shopDealSectionOneBackgroundImage"
                          :src="preview.shopDealSectionOneBackgroundImage"
                          height="70"
                          width="100"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-else-if="cmsData.shopDealSectionOneBackgroundImage"
                          :src="cmsData.shopDealSectionOneBackgroundImage"
                          height="70"
                          width="100"
                        />
                      </div>
                    </div>
                  </div>

                  <h5 class="card-title">Section Two CMS</h5>
                  <hr />
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row">
                      <div class="col-6 mb-3">
                        <label class="form-label">Section two title:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealSpecialCategoryTitle"
                          class="form-control"
                          placeholder="Enter title"
                        />
                      </div>
                    </div>
                  </div>

                  <h5 class="card-title">Section Three CMS</h5>
                  <hr />
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row">
                      <div class="col-6 mb-3">
                        <label for="inputProductTitle" class="form-label">
                          Banner one image:
                          <strong>
                            [Preferred Image Size: 703X311, Max Upload Limit:
                            1MB]
                          </strong>
                        </label>
                        <input
                          class="form-control mb-3"
                          type="file"
                          ref="shopDealBannerOneImage"
                          accept="image/*"
                          @change="handleUploadImage('shopDealBannerOneImage','shopDealBannerOneImage','shopDealBannerOneImage', $event)"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-if="preview.shopDealBannerOneImage"
                          :src="preview.shopDealBannerOneImage"
                          height="70"
                          width="100"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-else-if="cmsData.shopDealBannerOneImage"
                          :src="cmsData.shopDealBannerOneImage"
                          height="70"
                          width="100"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Banner one link:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealBannerOneLink"
                          class="form-control"
                          placeholder="Enter link"
                        />
                      </div>
                      <hr />
                      <div class="col-6 mb-3">
                        <label for="inputProductTitle" class="form-label">
                          Banner two desktop image:
                          <strong>
                            [Preferred Image Size: 215X311, Max Upload Limit:
                            1MB]
                          </strong>
                        </label>
                        <input
                          class="form-control mb-3"
                          type="file"
                          ref="shopDealBannerTwoDesktopImage"
                          accept="image/*"
                          @change="handleUploadImage('shopDealBannerTwoDesktopImage','shopDealBannerTwoDesktopImage','shopDealBannerTwoDesktopImage', $event)"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-if="preview.shopDealBannerTwoDesktopImage"
                          :src="preview.shopDealBannerTwoDesktopImage"
                          height="70"
                          width="100"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-else-if="cmsData.shopDealBannerTwoDesktopImage"
                          :src="cmsData.shopDealBannerTwoDesktopImage"
                          height="70"
                          width="100"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label for="inputProductTitle" class="form-label">
                          Banner two mobile image:
                          <strong>
                            [Preferred Image Size: 215X311, Max Upload Limit:
                            1MB]
                          </strong>
                        </label>
                        <input
                          class="form-control mb-3"
                          type="file"
                          ref="shopDealBannerTwoMobileImage"
                          accept="image/*"
                          @change="handleUploadImage('shopDealBannerTwoMobileImage','shopDealBannerTwoMobileImage','shopDealBannerTwoMobileImage', $event)"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-if="preview.shopDealBannerTwoMobileImage"
                          :src="preview.shopDealBannerTwoMobileImage"
                          height="70"
                          width="100"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-else-if="cmsData.shopDealBannerTwoMobileImage"
                          :src="cmsData.shopDealBannerTwoMobileImage"
                          height="70"
                          width="100"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Banner two link:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealBannerTwoLink"
                          class="form-control"
                          placeholder="Enter link"
                        />
                      </div>
                      <!-- <div class="col-6 mb-3">
                        <label class="form-label">Banner two button text:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealBannerTwoButtonText"
                          class="form-control"
                          placeholder="Enter button text"
                        />
                      </div> -->
                      <hr />
                      <div class="col-6 mb-3">
                        <label for="inputProductTitle" class="form-label">
                          Banner three image:
                          <strong>
                            [Preferred Image Size: 935X205, Max Upload Limit:
                            1MB]
                          </strong>
                        </label>
                        <input
                          class="form-control mb-3"
                          type="file"
                          ref="shopDealBannerThreeImage"
                          accept="image/*"
                          @change="handleUploadImage('shopDealBannerThreeImage','shopDealBannerThreeImage','shopDealBannerThreeImage', $event)"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-if="preview.shopDealBannerThreeImage"
                          :src="preview.shopDealBannerThreeImage"
                          height="70"
                          width="100"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-else-if="cmsData.shopDealBannerThreeImage"
                          :src="cmsData.shopDealBannerThreeImage"
                          height="70"
                          width="100"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Banner three link:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealBannerThreeLink"
                          class="form-control"
                          placeholder="Enter button link"
                        />
                      </div>
                      <!-- <div class="col-6 mb-3">
                        <label class="form-label">Banner three button text:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealBannerThreeButtonText"
                          class="form-control"
                          placeholder="Enter button text"
                        />
                      </div> -->
                    </div>
                  </div>

                  <h5 class="card-title">First Purchase CMS</h5>
                  <hr />
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row">
                      <div class="col-6 mb-3">
                        <label class="form-label">Section title:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealFirstPurchaseTitle"
                          class="form-control"
                          placeholder="Enter title"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Section sub title:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealFirstPurchaseSubTitle"
                          class="form-control"
                          placeholder="Enter sub title"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Section button text:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealFirstPurchaseButtonText"
                          class="form-control"
                          placeholder="Enter button text"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Section button link:</label>
                        <input
                          type="text"
                          v-model="cmsData.shopDealFirstPurchaseButtonLink"
                          class="form-control"
                          placeholder="Enter button link"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label for="inputProductTitle" class="form-label">
                          Section background image:
                          <strong>
                            [Preferred Image Size: 940X664, Max Upload Limit:
                            1MB]
                          </strong>
                        </label>
                        <input
                          class="form-control mb-3"
                          type="file"
                          ref="shopDealFirstPurchaseBackgoundImage"
                          accept="image/*"
                          @change="handleUploadImage('shopDealFirstPurchaseBackgoundImage','shopDealFirstPurchaseBackgoundImage','shopDealFirstPurchaseBackgoundImage', $event)"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-if="preview.shopDealFirstPurchaseBackgoundImage"
                          :src="preview.shopDealFirstPurchaseBackgoundImage"
                          height="70"
                          width="100"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-else-if="cmsData.shopDealFirstPurchaseBackgoundImage"
                          :src="cmsData.shopDealFirstPurchaseBackgoundImage"
                          height="70"
                          width="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/deals-page-cms.js"></script>