import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  data() {
    return {
      url: this.$serverURL + this.$api.cms.landing.landingThreeSectionFourUrl,
      cmsData: {
        title: "",
        listOneTitle: "",
        listOneDescription: "",
        listTwoTitle: "",
        listTwoDescription: "",
        listThreeTitle: "",
        listThreeDescription: "",
        image: "",
      },
      editor: ClassicEditor,
      editorConfig: {
        ckfinder: {
          uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
        },
        mediaEmbed: {
          previewsInData: true,
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
          ],
        },
      },
      preview: {
        imageOne: "",
      },
    };
  },
  async created() {
    document.title = "Jars - Landing Three Section Four Page CMS";
    this.getData();
  },
  methods: {
    async getData() {
      let response = await this.getCmsData(this.url);
      if (response.statusCode == 200) {
        this.cmsData = response.data.length > 0 ? response.data[0] : {};
      } else {
        this.cmsData = {};
      }
    },
    // handleAddress(locationData) {
    //     let location = locationData.formatted_address;
    //     let locationSplit = location.split(',');
    //     if (locationSplit[0].trim() != locationData.name) {
    //         location = locationData.name + ', ' + location;
    //     }
    //     this.cmsData.address = location;
    // },
    handleUploadImage(imageKey, previewImageKey, ref, event) {
      this.cmsData[imageKey] = event.target.files[0];
      let input = this.$refs[ref];
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.preview[previewImageKey] = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    async updateCms() {
      try {
        let fromData = new FormData();
        fromData.append("title", this.cmsData.title);
        fromData.append("listOneTitle", this.cmsData.listOneTitle);
        fromData.append("listOneDescription", this.cmsData.listOneDescription);
        fromData.append("listTwoTitle", this.cmsData.listTwoTitle);
        fromData.append("listTwoDescription", this.cmsData.listTwoDescription);
        fromData.append("listThreeTitle", this.cmsData.listThreeTitle);
        fromData.append(
          "listThreeDescription",
          this.cmsData.listThreeDescription
        );
        fromData.append("image", this.cmsData.image);
        this.$swal.showLoading();
        let response = await this.createUpdateCMS(this.url, fromData);
        if (response.statusCode == 200 || response.statusCode == 201) {
          this.$refs.imageOne.value = null;
          this.preview = {
            imageOne: "",
          };
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        }
        await this.getData();
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          text: "Something went wrong. Please try again!",
        });
      } finally {
        this.$swal.hideLoading();
      }
    },
  },
};
