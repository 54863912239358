// import { authHeader } from '../../../auth';
export default {
    name: "Store",
    data() {
        return {
            dataList: [],
            cmsData: {},
            setURL: this.$serverURL + this.$api.state.requestURL,
        }
    },
    async created() {
        document.title = "Jars - Pickup Delivery States";
        await this.getStateDataList(this.setURL);
        this.loadStyleAndCss();
    },
    computed: {
        filteredDataList() {
            return this.dataList.filter(data => data.stateName);
        }
    },
    methods: {
        loadStyleAndCss() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
        getStateDataList: async function (url) {
            try {
                let config = {
                    method: "GET",
                    url: url
                }
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.dataList = response.data;
                    }
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                console.log(error);
            }
        },
    }
}