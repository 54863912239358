<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Shop Page Filter Toggle</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Shop Page Filter Toggle CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Shop Page Filter Toggle CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-6">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <div class="form-check form-switch">
                      <label
                        class="form-label"
                      >Today's Specials</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.todaysSpecials"
                      />
                    </div>
                    <div class="form-check form-switch">
                      <label
                        class="form-label"
                      >Specials Offers</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.specialOffers"
                      />
                    </div>
                    <div class="form-check form-switch">
                      <label
                        class="form-label"
                      >Brands</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.brands"
                      />
                    </div>
                    <div class="form-check form-switch">
                      <label
                        class="form-label"
                      >Categories</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.categories"
                      />
                    </div>
                    <div class="form-check form-switch">
                      <label
                        class="form-label"
                      >Strain Types</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.strainTypes"
                      />
                    </div>
                    <div class="form-check form-switch">
                      <label
                        class="form-label"
                      >Effects</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.effects"
                      />
                    </div>
                    <div class="form-check form-switch">
                      <label
                        class="form-label"
                      >Potency All</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.potencyAll"
                      />
                    </div>
                    <div class="form-check form-switch">
                      <label
                        class="form-label"
                      >Potency THC</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.potencyTHC"
                      />
                    </div>
                    <div class="form-check form-switch">
                      <label
                        class="form-label"
                      >Potency CBD</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.potencyCBD"
                      />
                    </div>
                  </div>
                  <div class="d-grid">
                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/shop-page-filter-toggle-cms.js"></script>