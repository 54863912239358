import { authHeader } from "../../../auth";

export default {
    name: "PressReleaseCategory",
    data() {
        return {
            navList: []
        }
    },
    async created() {
        document.title = "JARS - Press Release Category";
        await this.getNavList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getNavList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.pressRelease.pressReleaseCategory,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.navList = response.data.data;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        deleteNav: function (id) { 
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: this.$serverURL + this.$api.pressRelease.pressReleaseCategory +'/' + id,
                                headers: authHeader()
                            }
                            await this.deleteData(config);
                            await this.getNavList(this.setURL);
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        }
                    }
            })
            
        },
    }
}