import { authHeader } from "../../../../auth";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "FAQ",
  data() {
    return {
      singleFaq: {},
      question: "",
      answer: "",
      setURL: this.$serverURL + this.$api.cms.faqURL,
      dataList: [],
      editor: ClassicEditor,
      addButtonText: "Create",
      addButtonIconClass: "fa-solid fa-rotate",
      addButtonClass: "btn btn-primary w-100",
      addButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Add FAQ";
    this.dataList = await this.getDataList(this.setURL);
    var scripts = [
      "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
      "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
      "/core/assets/js/table-datatable.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href =
      "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
    document.head.appendChild(style);
  },
  methods: {
    addFaq: async function () {
      if (!this.question) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter question!",
        });
      } else if (!this.answer) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter answer!",
        });
      } else {
        this.addButtonText = "Creating...";
        this.addButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.addButtonClass = "btn btn-secondary w-100";
        this.addButtonFlag = true;
        let data = {
          question: this.question,
          answer: this.answer,
        };
        let config = {
          method: "POST",
          url: this.setURL,
          data: data,
          headers: authHeader(),
        };
        let response = await this.postData(config);
        if (response.statusCode == 201) {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        }
        this.question = "";
        this.answer = "";
        this.dataList = await this.getDataList(this.setURL);
        this.$router.push("/faq");
        this.addButtonText = "Create";
        this.addButtonIconClass = "fa-solid fa-rotate";
        this.addButtonClass = "btn btn-primary w-100";
        this.addButtonFlag = false;
      }
    },
  },
};
