<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">User Page Banner</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">User Page Banner CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">User Page Banner CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="border border-3 p-4 rounded">
                                    <div class="col-12 mb-3">
                                        <label for="inputProductTitle" class="form-label">
                                        Background Image:
                                        <strong>
                                            [Preferred Image Size: 1286 × 120, Max Upload Limit:
                                            1MB]
                                        </strong>
                                        </label>
                                        <img
                                        style="position: relative; left: 11px, width: 100%"
                                        v-if="preview.image"
                                        :src="preview.image"
                                        height="70"
                                        width="520"
                                        />
                                        <img
                                        style="position: relative; left: 11px, width: 100%"
                                        v-else-if="cmsData.image"
                                        :src="cmsData.image"
                                        height="70"
                                        width="520"
                                        />
                                        <input
                                        class="form-control mt-3"
                                        type="file"
                                        ref="image"
                                        accept="image/*"
                                        @change="handleUploadImage('image','image','image', $event)"
                                        />
                                    </div>
                                    <div class="mb-3">
                                        <div class="input-group">
                                            <LvColorpicker label="Image Background Color:" v-model="cmsData.imageBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Title:</label>
                                        <input type="text" v-model="cmsData.title" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Sub Title:</label>
                                        <input type="text" v-model="cmsData.subTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Button Text:</label>
                                        <input type="text" v-model="cmsData.buttonText" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Button Link:</label>
                                        <input type="text" v-model="cmsData.buttonLink" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <div class="input-group">
                                            <LvColorpicker label="Background Color:" v-model="cmsData.bgColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="form-check form-switch">
                                            <label class="form-label">[If switch is active image will be show or switch is inactive background color is show]</label>
                                            <input class="form-check-input" type="checkbox" v-model="cmsData.isImageOrColor" id="flexSwitchCheckDefault"/>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="border border-3 p-4 rounded h-100">
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <div class="d-flex justify-content-center">
                                        <img style="width: 100%;" src="../../../../../../public/core/assets/images/user-page-banner-cms.png">
                                    </div>
                                    <h6 class="text-center pt-4"><b>User Page Banner</b></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/user-page-banner-cms.js"></script>