
export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.shopPageFilterToggleCms,
            dataLoaded: false,
            cmsData: {
                todaysSpecials: true,
                specialOffers: true,
                brands: true,
                categories: true,
                strainTypes: true,
                effects: true,
                potencyAll: true,
                potencyTHC: true,
                potencyCBD: true
            }
        }
    },
    async created() {
        document.title = "JARS - Shop Page Filter Toggle CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
                this.dataLoaded = true;
            }
            else {
                this.cmsData = {};
            }
        },
        async update() {
            try {
                let data = {
                    todaysSpecials: this.cmsData.todaysSpecials,
                    specialOffers: this.cmsData.specialOffers,
                    brands: this.cmsData.brands,
                    categories: this.cmsData.categories,
                    strainTypes: this.cmsData.strainTypes,
                    effects: this.cmsData.effects,
                    potencyAll: this.cmsData.potencyAll,
                    potencyTHC: this.cmsData.potencyTHC,
                    potencyCBD: this.cmsData.potencyCBD,
                    columnName: ""
                }
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}