import { authHeader } from "../../../../auth";
export default {
  name: "Update Event",

  data() {
    return {
      eventDaysUrl: this.$serverURL + this.$api.cms.event.days,
      eventDaysList: [],
      selectedEventDays: [],
      contentId: this.$route.params.id,
      contentData: {
        title: "",
        description: "",
        buttonText: "",
        buttonLink: "",
        eventDate: "",
        address: "",
        latitude: "",
        longitude: "",
        addressButtonText: "",
        image: "",
      },
      previewImage: "",
      updateButtonText: "Update",
      updateButtonIconClass: "fa-solid fa-rotate",
      updateButtonClass: "btn btn-primary",
      updateButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Update Event";
    await this.getContentData();
    await this.getEventDays();
    var scripts = [
      "/core/assets/js/jquery.min.js",
      "/core/assets/plugins/simplebar/js/simplebar.min.js",
      "/core/assets/plugins/metismenu/js/metisMenu.min.js",
      "./core/assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js",
      "/core/assets/plugins/smart-wizard/js/jquery.smartWizard.min.js",
      "/core/assets/js/form-wizard.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  methods: {
    async getContentData() {
      let config = {
        method: "GET",
        url:
          this.$serverURL + this.$api.cms.event.eventUrl + "/" + this.contentId,
        headers: authHeader(),
      };
      await this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.contentData =
              response.data.data.length > 0 ? response.data.data[0] : {};
            this.selectedEventDays = this.contentData.days.map((el) => {
              return el.eventDayId;
            });
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    },
    async getEventDays() {
      const res = await this.getDataList(this.eventDaysUrl);
      this.eventDaysList = res.map((el) => {
        return { value: el.id, label: el.title };
      });
    },
    onImageChange(event) {
      this.contentData.image = event.target.files[0];
      let input = this.$refs.imageFile;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    validate: function () {
      if (!this.contentData.title) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter title!",
        });
        return false;
      }
      if (!this.contentData.image) {
        this.$swal.fire({
          icon: "error",
          text: "Please upload image!",
        });
        return false;
      }
      return true;
    },
    handleAddress(locationData) {
      let location = locationData.formatted_address;
      let locationSplit = location.split(",");
      if (locationSplit[0].trim() != locationData.name) {
        location = locationData.name + ", " + location;
      }
      this.contentData.latitude = locationData.geometry.location.lat();
      this.contentData.longitude = locationData.geometry.location.lng();
      this.contentData.address = location;
    },
    save: async function () {
      if (this.validate() == true) {
        this.updateButtonText = "Updating...";
        this.updateButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.updateButtonClass = "btn btn-secondary";
        this.updateButtonFlag = true;
        let token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("title", this.contentData.title);
        formData.append("description", this.contentData.description);
        formData.append("buttonText", this.contentData.buttonText);
        formData.append("buttonLink", this.contentData.buttonLink);
        formData.append("eventDate", this.contentData.eventDate);
        formData.append("image", this.contentData.image);
        formData.append("address", this.contentData.address);
        formData.append("latitude", this.contentData.latitude);
        formData.append("longitude", this.contentData.longitude);
        formData.append(
          "addressButtonText",
          this.contentData.addressButtonText
        );
        formData.append("days", JSON.stringify(this.selectedEventDays));
        let config = {
          method: "PATCH",
          url:
            this.$serverURL +
            this.$api.cms.event.eventUrl +
            "/" +
            this.contentId,
          data: formData,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        await this.$axios(config)
          .then(async (response) => {
            if (response.status == 200) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }
            this.$refs.imageFile.value = "";
            await this.getContentData();
            this.updateButtonText = "Update";
            this.updateButtonIconClass = "fa-solid fa-rotate";
            this.updateButtonClass = "btn btn-primary";
            this.updateButtonFlag = false;
          })
          .catch((error) => {
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });
            this.updateButtonText = "Update";
            this.updateButtonIconClass = "fa-solid fa-rotate";
            this.updateButtonClass = "btn btn-primary";
            this.updateButtonFlag = false;
          });
      }
    },
  },
};
