<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Contact Reason
                        </li>
                    </ol>
                </nav>

                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                            <i class="bx bxs-plus-square"></i>Add Contact Reason
                        </button>
                    </div>
                    <!-- Add Modal -->

                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel"
                        aria-hidden="true">
                        <form method="post" action="javascript:void(0);" enctype="multipart/form-data">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add New Contact Reason ({{ stateName
                                            }})</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row mb-3">
                                            <div class="col-md-12">
                                                <label class="form-label" for="title">Reason Name:</label>
                                                <select v-model="contactReasonName" class="form-control">
                                                    <option value="">--Select Reason--</option>
                                                    <option v-for="reason in reasons" :key="reason.value"
                                                        :value="reason.value" :disabled="isDisabled(reason.value)">{{
                                            reason.label }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-12">
                                                <label class="form-label" for="title">Contact Email:</label>
                                                <input type="text" class="form-control" v-model="contactEmail"
                                                    id="title" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button @click="addContactReason()" type="button" :class="addButtonClass"
                                            :disabled="addButtonFlag == true"><i :class='addButtonIconClass'></i>{{
                                            addButtonText }}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Add Modal -->
                    <!-- Edit Role Modal -->

                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel"
                        aria-hidden="true">
                        <form method="post" action="javascript:void(0);" enctype="multipart/form-data">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Update Contact Reason ({{ stateName
                                            }})</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row mb-3">
                                            <div class="col-md-12">
                                                <label class="form-label" for="title">Reason Name:</label>
                                                <select v-model="singleData.contactReasonName" class="form-control"
                                                    disabled>
                                                    <option value="Marketing" disabled="true">Marketing</option>
                                                    <option value="Purchasing" disabled="true">Purchasing</option>
                                                    <option value="Customer Service" disabled="true">Customer Service
                                                    </option>
                                                    <option value="PR & Communications" disabled="true">PR &
                                                        Communications</option>
                                                    <option value="Community Service" disabled="true">Community Service
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-12">
                                                <label class="form-label" for="title">Contact Email:</label>
                                                <input type="text" class="form-control"
                                                    v-model="singleData.contactEmail" id="title" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="button" @click="updateContactReason()" :class="updateButtonClass"
                                            :disabled="updateButtonFlag == true"><i
                                                :class='updateButtonIconClass'></i>{{ updateButtonText }}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Edit Role Modal -->
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Contact Reason List</h6>
            <hr />
            <hr />
            <div class="card">
                <div class="card-body" style="display: flex; align-items: center;">
                    <h5 style="margin: 0;">{{ stateName }}</h5>
                    <div style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
                        @click="$router.push({ path: '/state-wise-contact-reason' })">
                        <span style="margin-right: 5px;">
                            <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
                        </span>
                        <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
                    </div>
                </div>
            </div>
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3" v-if="dataList.length > 0">
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" @click="handleSetDefault" :disabled="isDefault">
                            <i class="bx bxs-plus-square"></i>Set Default
                        </button>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Contact Reason Name</th>
                                    <th>Contact Email</th>
                                    <th>Hide/Show</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.contactReasonName }}</td>
                                    <td>{{ data.contactEmail }}</td>
                                    <td>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" v-model="data.isShow"
                                                id="flexSwitchCheckDefault" @change="handleUpdateHideShow(data)" />
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" v-on:click="editContactReason(data)"
                                                data-bs-toggle="modal" data-bs-target="#editModal"
                                                class="edit-button"><i class="bx bxs-edit text-white"></i></a>
                                            <a href="javascript:void(0);" v-on:click="deleteContactReason(data.id)"
                                                class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Contact Reason Name</th>
                                    <th>Contact Email</th>
                                    <th>Hide/Show</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/contact-reason.js'></script>


<style scoped>
.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
</style>