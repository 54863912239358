import { authHeader } from "../../../../auth";

export default {
  name: "ProductPerPageOptions",
  data() {
    return {
      contentList: [],
      setURL: this.$serverURL + this.$api.cms.productPerPageOptions,
      setSelectedURL: this.$serverURL + this.$api.cms.selectedProductPerPage,
      option: "",
      selectedProduct: null,
      singleContent: {},
      productButtonText: "Add",
      productButtonIconClass: "fa-solid fa-rotate",
      productButtonClass: "btn btn-primary mt-2",
      productButtonFlag: false,
      productUpdateButtonText: "Update",
      productUpdateButtonIconClass: "fa-solid fa-rotate",
      productUpdateButtonClass: "btn btn-primary mt-2",
      productUpdateButtonFlag: false,
    };
  },
  async created() {
    document.option = "JARS - Product Per Page";
    await this.getContentData();
    await this.getSelectedProductData();
    var scripts = [
      "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
      "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
      "/core/assets/js/table-datatable.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href =
      "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
    document.head.appendChild(style);
  },

  methods: {
    async getContentData() {
      const res = await this.getDataList(this.setURL);
      this.contentList = res;
    },

    async getSelectedProductData() {
      const res = await this.getDataList(this.setSelectedURL);
      this.selectedProduct = res[0]?.selectedProduct;
    },

    validateNumber(input) {
      const num = Number(input);
      return !isNaN(num) && isFinite(num);
    },

    validate() {
      if (!this.option || !this.validateNumber(this.option)) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter a valid numeric option!",
        });
        return false;
      }
      return true;
    },

    validateUpdate() {
      if (
        !this.singleContent.option ||
        !this.validateNumber(this.singleContent.option)
      ) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter a valid numeric option!",
        });
        return false;
      }
      return true;
    },

    async save() {
      if (this.validate()) {
        this.productButtonText = "Adding...";
        this.productButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.productButtonClass = "btn btn-secondary mt-2";
        this.productButtonFlag = true;
        let token = localStorage.getItem("token");
        const data = {
          option: this.option,
        };
        let config = {
          method: "POST",
          url: this.$serverURL + this.$api.cms.productPerPageOptions,
          data: data,
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        await this.$axios(config)
          .then(async (response) => {
            if (response.status == 201) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }
            this.option = "";
            await this.getContentData();
            document.getElementById("addCloseButton").click();
            let element = document.getElementsByClassName(
              "lv-colorpicker__colorblock"
            );
            Array.from(element).forEach((el) => {
              el.style.backgroundColor = "";
            });
            this.productButtonText = "Add";
            this.productButtonIconClass = "fa-solid fa-rotate";
            this.productButtonClass = "btn btn-primary mt-2";
            this.productButtonFlag = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });

            this.productButtonText = "Add";
            this.productButtonIconClass = "fa-solid fa-rotate";
            this.productButtonClass = "btn btn-primary mt-2";
            this.productButtonFlag = false;
          });
      }
    },

    async editContent(data) {
      this.singleContent = {};
      this.singleContent = await data;
    },

    async update() {
      if (this.validateUpdate()) {
        this.productUpdateButtonText = "Updating...";
        this.productUpdateButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.productUpdateButtonClass = "btn btn-secondary mt-2";
        this.productUpdateButtonFlag = true;
        let token = localStorage.getItem("token");
        const data = {
          option: this.singleContent.option,
        };
        let config = {
          method: "PATCH",
          url:
            this.$serverURL +
            this.$api.cms.productPerPageOptions +
            "/" +
            this.singleContent.id,
          data: data,
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        await this.$axios(config)
          .then(async (response) => {
            if (response.status == 200) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }
            this.singleContent = {};
            await this.getContentData();
            document.getElementById("editCloseButton").click();

            this.productUpdateButtonText = "Update";
            this.productUpdateButtonIconClass = "fa-solid fa-rotate";
            this.productUpdateButtonClass = "btn btn-primary mt-2";
            this.productUpdateButtonFlag = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });

            this.productUpdateButtonText = "Update";
            this.productUpdateButtonIconClass = "fa-solid fa-rotate";
            this.productUpdateButtonClass = "btn btn-primary mt-2";
            this.productUpdateButtonFlag = false;
          });
      }
    },

    async updateSelectedOption() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Do you want to update?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            let token = localStorage.getItem("token");
            const data = {
              selectedProduct: this.selectedProduct,
            };
            let config = {
              method: "POST",
              url: this.$serverURL + this.$api.cms.selectedProductPerPage,
              data: data,
              headers: {
                Authorization: "Bearer " + token,
              },
            };
            await this.$axios(config)
              .then(async (response) => {
                if (response.status == 200) {
                  this.$swal.fire({
                    toast: true,
                    position: "top-end",
                    text: response.data.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 3000,
                    animation: false,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    },
                  });
                }
                await this.getSelectedProductData();
              })
              .catch((error) => {
                this.isLoading = false;
                this.$swal.fire({
                  icon: "error",
                  text: error.response.data.message,
                });
              });
          } else {
            await this.getSelectedProductData();
          }
        });
    },

    addCss() {
      document.querySelector(".lv-overlaypanel").style.zIndex = 20000;
    },

    deleteContent(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              let config = {
                method: "DELETE",
                url: this.setURL + "/" + id,
                headers: authHeader(),
              };
              await this.deleteData(config);
              await this.getContentData();
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                text: error.response.data.message,
              });
            }
          }
        });
    },
  },
};
