import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.landing.landingThreeSectionOneUrl,
            cmsData: {
                title: "",
                description: "",
                buttonOneText: "",
                buttonOneLink: "",
                buttonTwoText: "",
                buttonTwoLink: "",
                imageOne: "",
                imageTwo: "",
                imageThree: "",
                imageFour: ""
            },
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                },
                mediaEmbed: {
                    previewsInData: true
                },
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    ],
                },
            },
            preview: {
                imagePreviewOne: "",
                imagePreviewTwo: "",
                imagePreviewThree: "",
                imagePreviewFour: "",
            }
        }
    },
    async created() {
        document.title = "Jars - Landing Three Section One Page CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        // handleAddress(locationData) {
        //     let location = locationData.formatted_address;
        //     let locationSplit = location.split(',');
        //     if (locationSplit[0].trim() != locationData.name) {
        //         location = locationData.name + ', ' + location;
        //     }
        //     this.cmsData.address = location;
        // },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {
                let fromData = new FormData();
                fromData.append('title', this.cmsData.title);
                fromData.append('description', this.cmsData.description);
                fromData.append('buttonOneText', this.cmsData.buttonOneText);
                fromData.append('buttonOneLink', this.cmsData.buttonOneLink);
                fromData.append('buttonTwoText', this.cmsData.buttonTwoText);
                fromData.append('buttonTwoLink', this.cmsData.buttonTwoLink);
                fromData.append('imageOne', this.cmsData.imageOne);
                fromData.append('imageTwo', this.cmsData.imageTwo);
                fromData.append('imageThree', this.cmsData.imageThree);
                fromData.append('imageFour', this.cmsData.imageFour);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, fromData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$refs.imagePreviewOne.value = null;
                    this.$refs.imagePreviewTwo.value = null;
                    this.$refs.imagePreviewThree.value = null;
                    this.$refs.imagePreviewFour.value = null;
                    this.preview = {
                        imagePreviewOne: "",
                        imagePreviewTwo: "",
                        imagePreviewThree: "",
                        imagePreviewFour: "",
                    }
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}