<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="bx bx-home-alt"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Change Password</li>
          </ol>
        </nav>
      </div>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <form>
              <div class="modal-body">
                <div class="row g-3">
                  <div class="col-12">
                    <label class="form-label">Old Password:</label>
                    <input
                      type="password"
                      v-model="oldPassword"
                      class="form-control"
                      placeholder="old password"
                      required
                    />
                  </div>
                  <div class="col-12">
                    <label class="form-label">New Password:</label>
                    <input
                      type="password"
                      v-model="newPassword"
                      class="form-control"
                      placeholder="new password"
                      required
                    />
                  </div>
                  <div class="col-12">
                    <label class="form-label">Retype New Password:</label>
                    <input
                      type="password"
                      v-model="confirmPassword"
                      class="form-control"
                      placeholder="retype password"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  @click="updatePassword()"
                  class="btn primary-button pull-left text-white"
                >
                  <i class="bx bx-save"></i> Save Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/change-password.js"></script>

<style>
.primary-button {
  background-color: #457bf0;
}
</style>