<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Shopping Cart Footer</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Shopping Cart Footer CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Shopping Cart Footer CMS</h5>
          <hr />
          <div class="card">
            <div class="card-body" style="display: flex; align-items: center;">
              <h5 style="margin: 0;">{{stateName}}</h5>
              <div
                style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
                @click="$router.push({ path: '/shopping-cart-footer-cms-state-list'})"
              >
                <span style="margin-right: 5px;">
                  <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
                </span>
                <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
              </div>
            </div>
          </div>
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <label class="form-label">Description:</label>
                    <textarea v-model="cmsData.description" class="form-control" cols="30" rows="5"></textarea>
                  </div>
                  <div class="d-grid">
                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/shopping-cart-footer-cms.js"></script>