
export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.pageMetaCMS,
            cmsData: {},
        }
    },
    async created() {
        document.title = "JARS - Site Page Meta CMS";
        this.getData();

    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async updateCms() {
            try {
                let data = {
                    'signinPageMetaKeyword': this.cmsData.signinPageMetaKeyword,
                    'signinPageMetaDescription': this.cmsData.signinPageMetaDescription,
                    'signupPageMetaKeyword': this.cmsData.signupPageMetaKeyword,
                    'signupPageMetaDescription': this.cmsData.signupPageMetaDescription,
                    'homePageMetaKeyword': this.cmsData.homePageMetaKeyword,
                    'homePageMetaDescription': this.cmsData.homePageMetaDescription,
                    'shopPageMetaKeyword': this.cmsData.shopPageMetaKeyword,
                    'shopPageMetaDescription': this.cmsData.shopPageMetaDescription,
                    'specialPageMetaKeyword': this.cmsData.specialPageMetaKeyword,
                    'specialPageMetaDescription': this.cmsData.specialPageMetaDescription,
                    'productDetailsPageMetaKeyword': this.cmsData.productDetailsPageMetaKeyword,
                    'productDetailsPageMetaDescription': this.cmsData.productDetailsPageMetaDescription,
                    'cartPageMetaKeyword': this.cmsData.cartPageMetaKeyword,
                    'cartPageMetaDescription': this.cmsData.cartPageMetaDescription,
                    'dailyDealsPageMetaKeyword': this.cmsData.dailyDealsPageMetaKeyword,
                    'dailyDealsPageMetaDescription': this.cmsData.dailyDealsPageMetaDescription,
                    'jarsPlusPageMetaKeyword': this.cmsData.jarsPlusPageMetaKeyword,
                    'jarsPlusPageMetaDescription': this.cmsData.jarsPlusPageMetaDescription,
                    'aboutUsPageMetaKeyword': this.cmsData.aboutUsPageMetaKeyword,
                    'aboutUsPageMetaDescription': this.cmsData.aboutUsPageMetaDescription,
                    'articlesPageMetaKeyword': this.cmsData.articlesPageMetaKeyword,
                    'articlesPageMetaDescription': this.cmsData.articlesPageMetaDescription,
                    'articleDetailsPageMetaKeyword': this.cmsData.articleDetailsPageMetaKeyword,
                    'articleDetailsPageMetaDescription': this.cmsData.articleDetailsPageMetaDescription,
                    'pressReleasePageMetaKeyword': this.cmsData.pressReleasePageMetaKeyword,
                    'pressReleasePageMetaDescription': this.cmsData.pressReleasePageMetaDescription,
                    'pressReleaseDetailsPageMetaKeyword': this.cmsData.pressReleaseDetailsPageMetaKeyword,
                    'pressReleaseDetailsPageMetaDescription': this.cmsData.pressReleaseDetailsPageMetaDescription,
                    'contactUsPageMetaKeyword': this.cmsData.contactUsPageMetaKeyword,
                    'contactUsPageMetaDescription': this.cmsData.contactUsPageMetaDescription,
                    'faqPageMetaKeyword': this.cmsData.faqPageMetaKeyword,
                    'faqPageMetaDescription': this.cmsData.faqPageMetaDescription,
                    'careerPageMetaKeyword': this.cmsData.careerPageMetaKeyword,
                    'careerPageMetaDescription': this.cmsData.careerPageMetaDescription,
                    'careerDepartmentPageMetaKeyword': this.cmsData.careerDepartmentPageMetaKeyword,
                    'careerDepartmentPageMetaDescription': this.cmsData.careerDepartmentPageMetaDescription,
                    'careerDetailsPageMetaKeyword': this.cmsData.careerDetailsPageMetaKeyword,
                    'careerDetailsPageMetaDescription': this.cmsData.careerDetailsPageMetaDescription,
                    'privacyPageMetaKeyword': this.cmsData.privacyPageMetaKeyword,
                    'privacyPageMetaDescription': this.cmsData.privacyPageMetaDescription,
                    'termsPageMetaKeyword': this.cmsData.termsPageMetaKeyword,
                    'termsPageMetaDescription': this.cmsData.termsPageMetaDescription,
                    'cookiesSettingsPageMetaKeyword': this.cmsData.cookiesSettingsPageMetaKeyword,
                    'cookiesSettingsPageMetaDescription': this.cmsData.cookiesSettingsPageMetaDescription,
                    'profilePageMetaKeyword': this.cmsData.profilePageMetaKeyword,
                    'profilePageMetaDescription': this.cmsData.profilePageMetaDescription,
                    'successPageMetaKeyword': this.cmsData.successPageMetaKeyword,
                    'successPageMetaDescription': this.cmsData.successPageMetaDescription,
                    'storeDetailsPageMetaKeyword': this.cmsData.storeDetailsPageMetaKeyword,
                    'storeDetailsPageMetaDescription': this.cmsData.storeDetailsPageMetaDescription,
                    'retailerPageMetaKeyword': this.cmsData.retailerPageMetaKeyword,
                    'retailerPageMetaDescription': this.cmsData.retailerPageMetaDescription,
                    'orderPageMetaKeyword': this.cmsData.orderPageMetaKeyword,
                    'orderPageMetaDescription': this.cmsData.orderPageMetaDescription,
                    'myItemsPageMetaKeyword': this.cmsData.myItemsPageMetaKeyword,
                    'myItemsPageMetaDescription': this.cmsData.myItemsPageMetaDescription,
                    'getDirectionPageMetaKeyword': this.cmsData.getDirectionPageMetaKeyword,
                    'getDirectionPageMetaDescription': this.cmsData.getDirectionPageMetaDescription,
                    'forgotPasswordPageMetaKeyword': this.cmsData.forgotPasswordPageMetaKeyword,
                    'forgotPasswordPageMetaDescription': this.cmsData.forgotPasswordPageMetaDescription,
                    'reviewPageMetaKeyword': this.cmsData.reviewPageMetaKeyword,
                    'reviewPageMetaDescription': this.cmsData.reviewPageMetaDescription,
                    'resetPasswordPageMetaKeyword': this.cmsData.resetPasswordPageMetaKeyword,
                    'resetPasswordPageMetaDescription': this.cmsData.resetPasswordPageMetaDescription,
                    'invoicePageMetaKeyword': this.cmsData.invoicePageMetaKeyword,
                    'invoicePageMetaDescription': this.cmsData.invoicePageMetaDescription,
                    'categoryPageMetaKeyword': this.cmsData.categoryPageMetaKeyword,
                    'categoryPageMetaDescription': this.cmsData.categoryPageMetaDescription,
                    'ageVerificationPageMetaKeyword': this.cmsData.ageVerificationPageMetaKeyword,
                    'ageVerificationPageMetaDescription': this.cmsData.ageVerificationPageMetaDescription,
                    'weeklyAdPageMetaKeyword': this.cmsData.weeklyAdPageMetaKeyword,
                    'weeklyAdPageMetaDescription': this.cmsData.weeklyAdPageMetaDescription,
                    'dealsJarsPageMetaKeyword': this.cmsData.dealsJarsPageMetaKeyword,
                    'dealsJarsPageMetaDescription': this.cmsData.dealsJarsPageMetaDescription,
                    'mapPageMetaKeyword': this.cmsData.mapPageMetaKeyword,
                    'mapPageMetaDescription': this.cmsData.mapPageMetaDescription,
                    'mapDetailsPageMetaKeyword': this.cmsData.mapDetailsPageMetaKeyword,
                    'mapDetailsPageMetaDescription': this.cmsData.mapDetailsPageMetaDescription,

                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {

                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}