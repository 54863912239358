<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Offer Badge Toggle</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Offer Badge Toggle CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Offer Badge Toggle CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-6">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <div class="form-check form-switch">
                      <label class="form-label">Discount Badge</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.discountBadge"
                      />
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="form-check form-switch">
                      <label class="form-label">All Discount Badge in special page</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.allDiscountBadge"
                      />
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="form-check form-switch">
                      <label class="form-label">Show Discount Badge in all shop page</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.discountBadgeShopPage"
                      />
                    </div>
                  </div>
                  <div class="d-grid">
                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/offer-page-badge-toggle-cms.js"></script>