<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">All Page Title CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">All Page Title CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Page Title CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="border border-3 p-4 rounded">
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Login Page Title:</label>
                                                <input type="text" v-model="cmsData.loginPageTitle" class="form-control" placeholder="Login page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Signup Page Title:</label>
                                                <input type="text" v-model="cmsData.signupPageTitle" class="form-control" placeholder="Signup page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Title:</label>
                                                <input type="text" v-model="cmsData.homePageTitle" class="form-control" placeholder="Home page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Page Title:</label>
                                                <input type="text" v-model="cmsData.shopPagetitle" class="form-control" placeholder="Shop page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Special Page Title:</label>
                                                <input type="text" v-model="cmsData.specialPagetitle" class="form-control" placeholder="Special page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Product Details Page Title:</label>
                                                <input type="text" v-model="cmsData.productDetailsPageTitle" class="form-control" placeholder="Product details page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page Title:</label>
                                                <input type="text" v-model="cmsData.cartPageTitle" class="form-control" placeholder="Cart page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Daily Deals Page Title:</label>
                                                <input type="text" v-model="cmsData.dailyDealsPageTitle" class="form-control" placeholder="Daily Deals page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">JARS+ Page Title:</label>
                                                <input type="text" v-model="cmsData.jarsPlusPageTitle" class="form-control" placeholder="JARS+ page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">About Us Page Title:</label>
                                                <input type="text" v-model="cmsData.aboutUsPageTitle" class="form-control" placeholder="About Us page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Articles Page Title:</label>
                                                <input type="text" v-model="cmsData.articlesPageTitle" class="form-control" placeholder="Articles page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Article Details Page Title:</label>
                                                <input type="text" v-model="cmsData.articleDetailsPageTitle" class="form-control" placeholder="Article Details page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Press Release Page Title:</label>
                                                <input type="text" v-model="cmsData.pressReleasePageTitle" class="form-control" placeholder="Press Release page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Press Release Details Page Title:</label>
                                                <input type="text" v-model="cmsData.pressReleaseDetailsPageTitle" class="form-control" placeholder="Press Release Details page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Us Page Title:</label>
                                                <input type="text" v-model="cmsData.contactUsPageTitle" class="form-control" placeholder="Contact Us page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Page Title:</label>
                                                <input type="text" v-model="cmsData.faqPageTitle" class="form-control" placeholder="FAQ page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Title:</label>
                                                <input type="text" v-model="cmsData.careerPageTitle" class="form-control" placeholder="Career page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Career Department Page Title:</label>
                                                <input type="text" v-model="cmsData.careerDepartmentPageTitle" class="form-control" placeholder="Career Department page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Career Details Page Title:</label>
                                                <input type="text" v-model="cmsData.careerDetailsPageTitle" class="form-control" placeholder="Career Details page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Title:</label>
                                                <input type="text" v-model="cmsData.privacyPageTitle" class="form-control" placeholder="Privacy page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Terms & Conditions Page Title:</label>
                                                <input type="text" v-model="cmsData.termsPageTitle" class="form-control" placeholder="Terms & Conditions page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Cookies Settings Page Title:</label>
                                                <input type="text" v-model="cmsData.cookiesSettingsPageTitle" class="form-control" placeholder="Cookies Settings page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Profile Page Title:</label>
                                                <input type="text" v-model="cmsData.profilePageTitle" class="form-control" placeholder="Profile page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Success Page Title:</label>
                                                <input type="text" v-model="cmsData.successPageTitle" class="form-control" placeholder="Success page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Store Details Page Title:</label>
                                                <input type="text" v-model="cmsData.storeDetailsPageTitle" class="form-control" placeholder="Store Details page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Retailer Page Title:</label>
                                                <input type="text" v-model="cmsData.retailerPageTitle" class="form-control" placeholder="Retailer page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Order Page Title:</label>
                                                <input type="text" v-model="cmsData.orderPageTitle" class="form-control" placeholder="Order page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">My Items Page Title:</label>
                                                <input type="text" v-model="cmsData.myItemsPageTitle" class="form-control" placeholder="My Items page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Get Direction Page Title:</label>
                                                <input type="text" v-model="cmsData.getDirectionPageTitle" class="form-control" placeholder="Get Direction page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Forgot Password Page Title:</label>
                                                <input type="text" v-model="cmsData.forgotPasswordPageTitle" class="form-control" placeholder="Forgot Password page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Review Page Title:</label>
                                                <input type="text" v-model="cmsData.reviewPageTitle" class="form-control" placeholder="Review page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Reset Password Page Title:</label>
                                                <input type="text" v-model="cmsData.resetPasswordPageTitle" class="form-control" placeholder="Reset Password page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Invoice Page Title:</label>
                                                <input type="text" v-model="cmsData.invoicePageTitle" class="form-control" placeholder="Invoice page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Category Page Title:</label>
                                                <input type="text" v-model="cmsData.categoryPageTitle" class="form-control" placeholder="Category page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Age Verification Page Title:</label>
                                                <input type="text" v-model="cmsData.ageVerificationPageTitle" class="form-control" placeholder="Age Verification page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Weekly Ad Page Title:</label>
                                                <input type="text" v-model="cmsData.weeklyAdPageTitle" class="form-control" placeholder="Weekly ad page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Deals Jars Page Title:</label>
                                                <input type="text" v-model="cmsData.dealsJarsPageTitle" class="form-control" placeholder="Deals jars page title">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Map Page Title:</label>
                                                <input type="text" v-model="cmsData.mapPageTitle" class="form-control" placeholder="Map page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Map Details Page Title:</label>
                                                <input type="text" v-model="cmsData.mapDetailsPageTitle" class="form-control" placeholder="Map Details page title">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="updateCms()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/site-page-title-cms.js"></script>