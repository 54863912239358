import { authHeader } from "../../../auth";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "EditPressRelease",
  data() {
    return {
      editor: ClassicEditor,
      setURL: this.$serverURL + this.$api.pressRelease.pressReleaseURL,
      editData: {},
      title: "",
      category: "",
      description: "",
      image: "",
      previewImage: "",
      imageFile: "",
      pressReleaseId: "",
      pressCategory: [],
      updateButtonText: "Update",
      updateButtonIconClass: "fa-solid fa-rotate",
      updateButtonClass: "btn btn-primary",
      updateButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Press Release";
    this.getPressCategory();
  },
  mounted() {
    if (this.$route.params.id === undefined) {
      return;
    }
    this.pressReleaseId = this.$route.params.id;
    this.getPressReleaseData(this.pressReleaseId);
  },
  methods: {
    getPressReleaseData: async function (id) {
      let config = {
        method: "GET",
        url: this.setURL + "/" + id,
        headers: authHeader(),
      };
      await this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.editData =
              response.data.data.length > 0 ? response.data.data[0] : {};
            this.title = this.editData?.title;
            this.category = this.editData?.category
              ? this.editData.category
              : "";
            this.description = this.editData?.description;
            this.image = this.editData?.image;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            text: error.response.data.message,
          });
        });
    },
    getPressCategory: async function () {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.pressRelease.pressReleaseCategory,
        headers: authHeader(),
      };
      await this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.pressCategory = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again!",
          });
        });
    },
    onImageChange(event) {
      this.imageFile = event.target.files[0];
      let input = this.$refs.imageFile;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    updatePressRelease: async function () {
      this.updateButtonText = "Updating...";
      this.updateButtonIconClass = "fa-solid fa-rotate fa-spin";
      this.updateButtonClass = "btn btn-secondary";
      this.updateButtonFlag = true;
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("category", this.category);
      formData.append("description", this.description);
      formData.append("image", this.imageFile ? this.imageFile : this.image);
      let config = {
        method: "PATCH",
        url: this.setURL + "/" + this.pressReleaseId,
        data: formData,
        headers: {
          ...authHeader(),
          "Content-Type": "multipart/form-data",
        },
      };
      await this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.$swal.fire({
              toast: true,
              position: "top-end",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              animation: false,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          }
          this.title = "";
          this.category = "";
          this.description = "";
          this.image = "";
          this.imageFile = "";
          this.$refs.imageFile.value = null;
          this.previewImage = "";
          this.$router.push("/press-release");
          this.updateButtonText = "Update";
          this.updateButtonIconClass = "fa-solid fa-rotate";
          this.updateButtonClass = "btn btn-primary";
          this.updateButtonFlag = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            text: error.response.data.message,
          });
          this.updateButtonText = "Update";
          this.updateButtonIconClass = "fa-solid fa-rotate";
          this.updateButtonClass = "btn btn-primary";
          this.updateButtonFlag = false;
        });
    },
  },
};
