import { authHeader } from "../../../../auth";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "FAQ",
  data() {
    return {
      singleFaq: {},
      question: "",
      answer: "",
      setURL: this.$serverURL + this.$api.cms.faqURL,
      dataList: [],
      editor: ClassicEditor,
      faqId: "",
      updateButtonText: "Update",
      updateButtonIconClass: "fa-solid fa-rotate",
      updateButtonClass: "btn btn-primary w-100",
      updateButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Edit FAQ";
    if (this.$route.params.id != undefined) {
      this.faqId = this.$route.params.id;
    }
    await this.editFaq(this.faqId);
  },
  methods: {
    editFaq: async function (id) {
      let config = {
        method: "GET",
        url: this.setURL + "/" + id,
        headers: authHeader(),
      };
      await this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.singleFaq = response.data.data[0];
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            text: error.response.data.message,
          });
        });
    },
    updateFaq: async function () {
      if (!this.singleFaq.question) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter question!",
        });
      } else if (!this.singleFaq.answer) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter answer!",
        });
      } else {
        this.updateButtonText = "Updating...";
        this.updateButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.updateButtonClass = "btn btn-secondary w-100";
        this.updateButtonFlag = true;
        let data = {
          question: this.singleFaq.question,
          answer: this.singleFaq.answer,
        };
        let config = {
          method: "PATCH",
          url: this.setURL + "/" + this.singleFaq.id,
          data: data,
          headers: authHeader(),
        };
        let response = await this.updateData(config);
        if (response.statusCode == 200) {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        }
        this.singleFaq = {};
        this.dataList = await this.getDataList(this.setURL);
        this.$router.push("/faq");
        this.updateButtonText = "Update";
        this.updateButtonIconClass = "fa-solid fa-rotate";
        this.updateButtonClass = "btn btn-primary w-100";
        this.updateButtonFlag = false;
      }
    },
  },
};
