import { authHeader } from "../../../auth";

export default {
  name: "AddArticleCategory",
  data() {
    return {
      navList: [],
      selectedImageOne: null,
      selectedImageTwo: null,
      ImageOne: "",
      ImageTwo: "",
      previewImageOne: "",
      previewImageTwo: "",
      imageFileOne: "",
      imageFileTwo: "",
      addButtonText: "Create",
      addButtonIconClass: "fa-solid fa-rotate",
      addButtonClass: "btn btn-primary",
      addButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Article Category";
    var scripts = [
      "/core/assets/js/jquery.min.js",
      "/core/assets/plugins/simplebar/js/simplebar.min.js",
      "/core/assets/plugins/metismenu/js/metisMenu.min.js",
      "./core/assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js",
      "/core/assets/plugins/smart-wizard/js/jquery.smartWizard.min.js",
      "/core/assets/js/form-wizard.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    await this.getNavList();
  },
  methods: {
    getNavList: async function () {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.blog.articleCategory,
        headers: authHeader(),
      };
      await this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.navList = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again!",
          });
        });
    },
    onOneImageChange(event) {
      this.imageOne = event.target.files[0];
      let input = this.$refs.imageFileOne;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImageOne = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    onTwoImageChange(event) {
      this.imageTwo = event.target.files[0];
      let input = this.$refs.imageFileTwo;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImageTwo = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    validate: function () {
      if (!this.title) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter title!",
        });
        return false;
      }
      if (!this.imageOne) {
        this.$swal.fire({
          icon: "error",
          text: "Please upload image one!",
        });
        return false;
      }
      if (!this.imageTwo) {
        this.$swal.fire({
          icon: "error",
          text: "Please upload image two!",
        });
        return false;
      }
      return true;
    },
    save: async function () {
      if (this.validate() == true) {
        this.addButtonText = "Creating...";
        this.addButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.addButtonClass = "btn btn-secondary";
        this.addButtonFlag = true;
        let token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("title", this.title);
        formData.append("isShowNav", this.isShowNav);
        formData.append("imageOne", this.imageOne);
        formData.append("imageTwo", this.imageTwo);
        let config = {
          method: "POST",
          url: this.$serverURL + this.$api.blog.articleCategory,
          data: formData,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        await this.$axios(config)
          .then((response) => {
            if (response.status == 201) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }
            this.title = "";
            this.imageOne = "";
            this.imageTwo = "";
            this.$router.push("/article-category");
            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary";
            this.addButtonFlag = false;
          })
          .catch((error) => {
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });
            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary";
            this.addButtonFlag = false;
          });
      }
    },
  },
};
