<template>
  <div class="page-wrapper">
      <div class="page-content">
          <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div class="breadcrumb-title pe-3">How To Enjoy</div>
              <div class="ps-3">
                  <nav aria-label="breadcrumb">
                      <ol class="breadcrumb mb-0 p-0">
                          <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                          <li class="breadcrumb-item active" aria-current="page">How To Enjoy CMS</li>
                      </ol>
                  </nav>
              </div>
          </div>
          <div class="card">
              <div class="card-body">
                  <h5 class="card-title">How To Enjoy CMS</h5>
                  <hr/>
                  <div class="form-body mt-4">
                      <div class="row">
                          <div class="col-lg-6">
                              <div class="border border-3 p-4 rounded h-100">
                                  <div class="mb-3">
                                      <label class="form-label">Title:</label>
                                      <input type="text" v-model="cmsData.title" class="form-control">
                                  </div>
                                  <div class="mb-3">
                                      <label class="form-label">Sub Title:</label>
                                      <input type="text" v-model="cmsData.subTitle" class="form-control">
                                  </div>
                                  <div class="d-grid">
                                      <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-6">
                              <div class="border border-3 p-4 rounded h-100">
                                  <div class="d-flex justify-content-center">
                                      <img style="width: 100%;" src="../../../../../public/core/assets/images/how-to-enjoy.png">
                                  </div>
                                  <h6 class="text-center pt-4"><b>Dutchie Custom Key: How To Enjoy</b></h6>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script src="../js/how-to-enjoy-cms.js"></script>