<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Site Map CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Site Map CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Select Process for Sitemap</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-10">
                <div class="form-body mt-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="border border-3 p-4 rounded">
                        <div class="mb-3">
                          <div class="form-check form-switch">
                            <label class="form-label">Manual Process</label>
                            <input
                              class="form-check-input"
                              style="cursor: pointer"
                              type="radio"
                              name="process"
                              value="manual"
                              v-model="processType"
                              @change="siteMapCheckerToggle"
                            />
                          </div>
                        </div>
                        <div class="mb-3">
                          <div class="form-check form-switch">
                            <label class="form-label">Automatic Process</label>
                            <input
                              class="form-check-input"
                              style="cursor: pointer"
                              type="radio"
                              name="process"
                              value="automatic"
                              v-model="processType"
                              @change="siteMapCheckerToggle"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" v-if="cmsData.manual">
        <div class="card-body">
          <h5 class="card-title">Site Map CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-10">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <label class="form-label">
                      <h5>Static Sitemap Data</h5>
                    </label>
                    <textarea
                      type="text"
                      class="form-control"
                      cols="30"
                      rows="15"
                      v-model="staticSitemap"
                      placeholder="Enter Static Sitemap Data"
                    ></textarea>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">
                      <h5>Dynamic Sitemap Data</h5>
                    </label>
                    <textarea
                      type="text"
                      class="form-control"
                      cols="30"
                      rows="15"
                      v-model="dynamicSitemap"
                      placeholder="Enter Static Sitemap Data"
                    ></textarea>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">
                      <h5>Dynamic Blog Sitemap Data</h5>
                    </label>
                    <textarea
                      type="text"
                      class="form-control"
                      cols="30"
                      rows="15"
                      v-model="dynamicBlogSitemap"
                      placeholder="Enter Static Sitemap Data"
                    ></textarea>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">
                      <h5>Dynamic Product Sitemap Data</h5>
                    </label>
                    <textarea
                      type="text"
                      class="form-control"
                      cols="30"
                      rows="15"
                      v-model="dynamicProductSitemap"
                      placeholder="Enter Static Sitemap Data"
                    ></textarea>
                  </div>

                  <div class="d-flex justify-content-end">
                    <button
                      @click="generateSitemap"
                      type="button"
                      class="btn btn-primary"
                    >Generate Sitemap</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" v-if="cmsData.automatic">
        <div class="card-body">
          <h5 class="card-title">Select Duration</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-10">
                <div class="border border-3 p-4 rounded">
                  <div class="d-flex align-items-center">
                    <div>
                      <label
                        v-for="option in options"
                        :key="option.value"
                        style="display: block; margin: 5px 0; cursor: pointer;"
                      >
                        <input
                          type="radio"
                          name="duration"
                          :value="option.value"
                          v-model="selectedOption"
                          @change="handleRadioChange(option)"
                        />
                        {{ option.label }}
                      </label>
                    </div>
                    <div v-if="selectedOption == 'instant'">
                      <button class="btn btn-primary ms-5" @click="handleInstantGenerate">Generate Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/site-map-cms.js"></script>