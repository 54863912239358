<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Weekly Ad</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i
                                        class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Weekly Ad</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body" style="display: flex; align-items: center;">
                    <h5 style="margin: 0;">{{ stateName }}</h5>
                    <div style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
                        @click="$router.push({ path: '/weekly-ad-state-list' })">
                        <span style="margin-right: 5px;">
                            <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
                        </span>
                        <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-between align-items-center">
                        <h5 class="card-title mb-0 pb-0">Weekly Ad</h5>
                        <div class="ms-auto">
                            <div class="btn-group">
                                <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                    data-bs-target="#addModal">
                                    <i class="bx bxs-plus-square"></i>Add New Data
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div class="ms-auto">
                            <!-- Add Modal -->
                            <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel"
                                aria-hidden="true">
                                <form @submit.prevent="addEntity" enctype="multipart/form-data">
                                    <div class="modal-dialog modal-md">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="addModalLabel">Add New Weekly Ad</h5>
                                                <button type="button" id="closeButton" class="btn-close"
                                                    data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <label class="form-label">Background Image: [Preferred Image Size:
                                                    340X283,
                                                    Max Upload Limit: 1MB]</label>
                                                <input class="form-control" @change="onImageChange" type="file"
                                                    ref="image">
                                            </div>
                                            <div class="modal-body">
                                                <div class="col-md-3">
                                                    <img v-if="previewImage" :src="previewImage" width="150" />
                                                    <img v-else src="../../../assets/images/default.png" width="150" />
                                                </div>
                                            </div>
                                            <div class="modal-body">
                                                <label class="form-label">Title:</label>
                                                <input class="form-control mb-1" type="text" v-model="title" />
                                            </div>
                                            <div class="modal-body">
                                                <label class="form-label">Button Text:</label>
                                                <input class="form-control mb-1" type="text" v-model="buttonText" />
                                            </div>
                                            <div class="modal-body">
                                                <label class="form-label">Button Link:</label>
                                                <input class="form-control mb-1" type="text" v-model="buttonLink" />
                                            </div>
                                            <div class="modal-body">
                                                <label class="form-label">Tag Names:</label>
                                                <Multiselect v-model="selectedWeeklyTags" openDirection="top"
                                                    :options="weeklyAdTags" mode="tags" :close-on-select="false"
                                                    :searchable="true" :create-option="true" />
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                                    Close
                                                </button>
                                                <button type="submit" :class="addButtonClass"
                                                    :disabled="addButtonFlag == true"><i
                                                        :class='addButtonIconClass'></i>{{ addButtonText }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- Add Modal -->
                            <!-- Edit Modal -->
                            <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel"
                                aria-hidden="true">
                                <form @submit.prevent="updateEntity">
                                    <div class="modal-dialog modal-md">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="addModalLabel">Update Weekly Ad</h5>
                                                <button type="button" id="buttonClose" class="btn-close"
                                                    data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <label class="form-label">Background Image: [Preferred Image Size:
                                                    340X283,
                                                    Max Upload
                                                    Limit: 1MB]</label>
                                                <input class="form-control" @change="onUpdateImageChange" type="file"
                                                    ref="updateImage">
                                            </div>
                                            <div class="modal-body">
                                                <div class="col-md-12">
                                                    <img v-if="previewUpdateImage" class="img-thumbnail"
                                                        :src="previewUpdateImage" width="150">
                                                    <img v-else-if="singleData.backgroundImage" class="img-thumbnail"
                                                        :src="singleData.backgroundImage" width="150">
                                                    <img v-else class="img-thumbnail"
                                                        src="../../../assets/images/default.png" width="150">
                                                </div>
                                            </div>
                                            <div class="modal-body">
                                                <label class="form-label">Title:</label>
                                                <input class="form-control mb-1" type="text"
                                                    v-model="singleData.title" />
                                            </div>
                                            <div class="modal-body">
                                                <label class="form-label">Button Text:</label>
                                                <input class="form-control mb-1" type="text"
                                                    v-model="singleData.buttonText" />
                                            </div>
                                            <div class="modal-body">
                                                <label class="form-label">Button Link:</label>
                                                <input class="form-control mb-1" type="text"
                                                    v-model="singleData.buttonLink" />
                                            </div>
                                            <div class="modal-body">
                                                <label class="form-label">Tag Names:</label>
                                                <Multiselect v-model="selectedWeeklyTags" openDirection="top"
                                                    :options="weeklyAdTags" mode="tags" :close-on-select="false"
                                                    :searchable="true" :create-option="true" />
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                                    Close
                                                </button>
                                                <button type="submit" :class="updateButtonClass"
                                                    :disabled="updateButtonFlag == true"><i
                                                        :class='updateButtonIconClass'></i>{{ updateButtonText
                                                    }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- Edit Modal -->
                        </div>
                    </div>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="table-responsive">
                                <table id="example" class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sl</th>
                                            <th>Background Image</th>
                                            <th>Title</th>
                                            <th>Button Text</th>
                                            <th>Button Link</th>
                                            <th>Tag Names</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(data, index) in dataList" :key="index">
                                            <td>{{ index + 1 }}</td>
                                            <td><img :src="data.backgroundImage" height="50"></td>
                                            <td>{{ data.title }}</td>
                                            <td>{{ data.buttonText }}</td>
                                            <td>{{ data.buttonLink }}</td>
                                            <td>
                                                <span v-for="(tag, i) in data.tags" :key="i">{{ tag.tagName }}<span
                                                        v-if="i + 1 < data.tags.length">, </span>
                                                </span>
                                            </td>
                                            <td>
                                                <div class="d-flex order-actions">
                                                    <a href="javascript:void(0);" v-on:click="editEntity(data)"
                                                        data-bs-toggle="modal" data-bs-target="#editModal"
                                                        class="edit-button">
                                                        <i class="bx bxs-edit text-white"></i>
                                                    </a>
                                                    <a href="javascript:void(0);" v-on:click="deleteEntity(data.id)"
                                                        class="ms-3 delete-button"><i
                                                            class="bx bxs-trash text-white"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Sl</th>
                                            <th>Background Image</th>
                                            <th>Title</th>
                                            <th>Button Text</th>
                                            <th>Button Link</th>
                                            <th>Tag Names</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/weekly-ad.js"></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
</style>