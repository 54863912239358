export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.landing.landingThreeSectionSixCmsUrl,
            cmsData: {},
        }
    },
    async created() {
        document.title = "Jars - Landing Three Section Six CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        handleUploadImage(event) {
            this.cmsData.backgroundImage = event.target.files[0];
            let input = this.$refs.backgroundImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {
                let data = {
                    title: this.cmsData.title,
                    description: this.cmsData.description,
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}