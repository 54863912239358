<template>
	<div class="page-wrapper">
		<loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Benefits Modal</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								Benefits Modal
							</li>
						</ol>
					</nav>
				</div>
				<div class="ms-auto">
					<div class="btn-group">
						<button type="button" class="btn btn-primary" @click="$router.push('/blog-post')">
							<i class="bx bx-coin-stack"></i> Benefits Modal List
						</button>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-xl-12 mx-auto">
							<h6 class="mt-2 mb-2 text-uppercase">Edit Benefits Modal</h6>
							<hr>
							<div class="form-body mt-4">
								<div class="row mb-3">
									<div class="col-lg-8">
										<div class="border border-3 p-1 rounded">
											<div class="card-body p-5">
												<form class="row g-3">
													<div class="col-md-12">
														<label class="form-label">Upload Image: [Image Size: 1920X1280, Max Limit: 1MB]</label>
														<div class="col-12">
															<img
																style="position: relative; left: 11px"
																v-if="previewImage"
																:src="previewImage"
																height="70"
															/>
															<img
																style="position: relative; left: 11px"
																v-else-if="singleContent.image"
																:src="singleContent.image"
																height="70"
															/>
														</div>
														<input class="form-control" type="file" ref="imageFile" @change="onImageChange"/>
													</div>
													<div class="col-md-12">
														<label for="postTitle" class="form-label">Title:</label>
														<input type="text" v-model="singleContent.title" class="form-control">
													</div>
													<div class="col-md-12">
														<label for="postTitle" class="form-label">Sub Title:</label>
														<input type="text" v-model="singleContent.subTitle" class="form-control" />
													</div>
													<div class="col-md-12">
														<label for="postTitle" class="form-label">Terms And Condition Title:</label>
														<input type="text" v-model="singleContent.termsAndConditionTitle" class="form-control" />
													</div>
													<div class="d-grid mt-5">
														<button v-on:click="updateBenefits" type="button" :class="updateButtonClass" :disabled="updateButtonFlag == true"><i
															:class='updateButtonIconClass'></i>{{ updateButtonText }}</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="../js/edit-benefits-modal.js"></script>

<style scoped>
	@import '../../../../public/core/assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css';
	@import '../../../../public/core/assets/plugins/metismenu/css/metisMenu.min.css';
	@import '../../../../public/core/assets/plugins/smart-wizard/css/smart_wizard_all.min.css';
	.margin-auto {	
        margin-left: 480px;
	}
</style>