<template>
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
      <div>
        <img
          src="../../../../public/core/assets/images/logo-icon.png"
          class="logo-icon"
          alt="logo icon"
        />
      </div>
      <div>
        <h4 class="logo-text">JARS</h4>
      </div>
      <div class="toggle-icon ms-auto">
        <i class="bx bx-arrow-to-left"></i>
      </div>
    </div>
    <ul class="metismenu" id="menu">
      <li>
        <a href="javascript:void(0)" @click="$router.push('/home')">
          <div class="parent-icon">
            <i class="bx bxs-dashboard"></i>
          </div>
          <div class="menu-title">Dashboard</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/locations')">
          <div class="parent-icon">
            <i class="bx bxs-store"></i>
          </div>
          <div class="menu-title">Locations</div>
        </a>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-up-arrow"></i>
          </div>
          <div class="menu-title">Data</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/retailer-aiq')">
              <i class="bx bx-right-arrow-alt"></i>Retailer AIQ Service
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/feedback')">
              <i class="bx bx-right-arrow-alt"></i>Feedback
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-review')">
              <i class="bx bx-right-arrow-alt"></i>Product Review
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/contact')">
              <i class="bx bx-right-arrow-alt"></i>Contact Request
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/weekly-ad-signups')">
              <i class="bx bx-right-arrow-alt"></i>Weekly Ad Signups
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/draw-giveaway')">
              <i class="bx bx-right-arrow-alt"></i>Draw & Giveaway Request
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bx-cog"></i>
          </div>
          <div class="menu-title">System Settings</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/state-wise-contact-reason')">
              <i class="bx bx-right-arrow-alt"></i>Contact Reason
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/synchronize')">
              <i class="bx bx-right-arrow-alt"></i>Synchronize
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/shop-page')">
              <i class="bx bx-right-arrow-alt"></i>Shop Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/shop-page-filter-toggle-cms')">
              <i class="bx bx-right-arrow-alt"></i>Shop Filter Toggle
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/cart-counter-cms')">
              <i class="bx bx-right-arrow-alt"></i>Cart Counter
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/offer-page-badge-toggle-cms')">
              <i class="bx bx-right-arrow-alt"></i>Offer Badge Toggle Cms
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fadeIn animated bx bxs-user-rectangle"></i>
          </div>
          <div class="menu-title">User</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/admins')">
              <i class="bx bx-right-arrow-alt"></i>Admins
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/customers')">
              <i class="bx bx-right-arrow-alt"></i>Customers
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fa-solid fa-list-check"></i>
          </div>
          <div class="menu-title">SEO</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-page-title-cms')">
              <i class="bx bx-right-arrow-alt"></i>Site Page Title CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-page-meta-cms')">
              <i class="bx bx-right-arrow-alt"></i>Site Page Meta CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-map')">
              <i class="bx bx-right-arrow-alt"></i>Site Map
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-meta-mapping')">
              <i class="bx bx-right-arrow-alt"></i>Product Details Page Meta
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/robot-txt-cms')">
              <i class="bx bx-right-arrow-alt"></i>Robot Text
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/page-schema')">
              <i class="bx bx-right-arrow-alt"></i>Page Schema
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/delivery-pages')">
              <i class="bx bx-right-arrow-alt"></i>Delivery Pages
              <!-- <div class="parent-icon">
                <i class="bx bxs-cart-alt"></i>
              </div>-->
              <!-- <div class="menu-title">Delivery Pages</div> -->
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-cog"></i>
          </div>
          <div class="menu-title">CMS Setting</div>
        </a>
        <ul>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-home-alt"></i>
              </div>
              <div class="menu-title">Home</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-slider-states')">
                  <!-- <a href="javascript:void(0)" @click="$router.push('/state-list')"> -->
                  <i class="bx bx-right-arrow-alt"></i>Home Slider
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-sub-nav')">
                  <i class="bx bx-right-arrow-alt"></i>Home Page Sub Nav
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/home-page-mobile-category-cms')"
                >
                  <!-- <a href="javascript:void(0)" @click="$router.push('/state-list')"> -->
                  <i class="bx bx-right-arrow-alt"></i>Home Page Mobile Category
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/home-page-cms')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Home Page CMS
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/upto-section')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Upto Banner
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-two-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Two
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-three-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Three
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-four-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Four
                </a>
              </li>
              <!-- <li>
                    <a href="javascript:void(0)" @click="$router.push('/section-four-slider-states')">
                    <i class="bx bx-right-arrow-alt"></i>Section Four Slider
                    </a>
              </li>-->
              <li>
                <a href="javascript:void(0)" @click="$router.push('/common-banner-states')">
                  <i class="bx bx-right-arrow-alt"></i>Common Banner
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/new-drop-banner-states')">
                  <i class="bx bx-right-arrow-alt"></i>New Drop Banner
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-five-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Five
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-seven-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Seven
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-eight-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Eight
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-nine-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Nine
                </a>
              </li>
              <!-- <li>
                <a href="javascript:void(0)" @click="$router.push('/instagram-section-states')">
                  <i class="bx bx-right-arrow-alt"></i>Instagram CMS
                </a>
              </li>-->
            </ul>
          </li>

          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="fa-solid fa-list-check"></i>
              </div>
              <div class="menu-title">Events</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/event-days')">
                  <i class="bx bx-right-arrow-alt"></i>Event Days
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/events')">
                  <i class="bx bx-right-arrow-alt"></i>Event List
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-home-alt"></i>
              </div>
              <div class="menu-title">Landings</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/landing-url-settings')">
                  <i class="bx bx-right-arrow-alt"></i>Url Settings
                </a>
              </li>
              <li>
                <a href="#" class="has-arrow">
                  <div class="parent-icon">
                    <i class="bx bxs-up-arrow"></i>
                  </div>
                  <div class="menu-title">Landing One</div>
                </a>
                <ul>
                  <li>
                    <a href="javascript:void(0)" @click="$router.push('/landing-one-settings')">
                      <i class="bx bx-right-arrow-alt"></i>Settings
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="$router.push('/landing-one-slider')">
                      <i class="bx bx-right-arrow-alt"></i>Slider
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="$router.push('/landing-one-section-two')">
                      <i class="bx bx-right-arrow-alt"></i>Section Two CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-three')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Three CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-four-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Four CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-four-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Four Content
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-five-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Five CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-six-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Six CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-six-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Six Content
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-seven-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Seven CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-eignt-cms-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Eight CMS & Content
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-nine-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Nine CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-ten-cms-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Ten CMS & Content
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#" class="has-arrow">
                  <div class="parent-icon">
                    <i class="bx bxs-up-arrow"></i>
                  </div>
                  <div class="menu-title">Landing Two</div>
                </a>
                <ul>
                  <li>
                    <a href="javascript:void(0)" @click="$router.push('/landing-two-settings')">
                      <i class="bx bx-right-arrow-alt"></i>Settings
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="$router.push('/landing-two-slider')">
                      <i class="bx bx-right-arrow-alt"></i>Slider
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-two-section-two-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Two CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-two-section-three-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Three CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-two-section-four-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Four CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-two-section-four-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Four Content
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-two-section-five-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Five CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-two-section-six-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Six CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-two-section-seven-cms-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Seven CMS & Content
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-two-section-eight-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Eight CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-two-section-eight-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Eight Content
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-two-section-nine-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Nine CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-two-section-ten-cms-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Ten CMS & Content
                    </a>
                  </li>
                  <!-- <li>
                    <a href="javascript:void(0)" @click="$router.push('/landing-one-section-two')">
                      <i class="bx bx-right-arrow-alt"></i>Section Two CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-three')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Three CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-three-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Three Content
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-four-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Four CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-four-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Four Content
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-five-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Five CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-six-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Six CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-six-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Six Content
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-seven-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Seven CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-eignt-cms-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Eight CMS & Content
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-nine-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Nine CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-one-section-ten-cms-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Ten CMS & Content
                    </a>
                  </li>-->
                </ul>
              </li>
              <li>
                <a href="#" class="has-arrow">
                  <div class="parent-icon">
                    <i class="bx bxs-up-arrow"></i>
                  </div>
                  <div class="menu-title">Landing Three</div>
                </a>
                <ul>
                  <li>
                    <a href="javascript:void(0)" @click="$router.push('/landing-three-settings')">
                      <i class="bx bx-right-arrow-alt"></i>Settings
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-three-section-one')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section One
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-three-section-two-cms-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Two CMS & Content
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-three-section-three')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Three
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-three-section-four')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Four
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-three-section-five-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Five CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-three-section-six-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Six CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-three-section-six-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Six Content
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-three-section-seven-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Seven CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-three-section-eight-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Eight CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-three-section-nine-cms')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Nine CMS
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/landing-three-section-ten-cms-content')"
                    >
                      <i class="bx bx-right-arrow-alt"></i>Section Ten CMS & Content
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bxs-pen"></i>
              </div>
              <div class="menu-title">Article</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/blog-post')">
                  <i class="bx bx-right-arrow-alt"></i>Article Post
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/article-category')">
                  <i class="bx bx-right-arrow-alt"></i>Article Category
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/article-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Article Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/article-details-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Article Details CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bxs-news"></i>
              </div>
              <div class="menu-title">Press Release</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/press-release')">
                  <i class="bx bx-right-arrow-alt"></i>Press Release
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/press-release-category')">
                  <i class="bx bx-right-arrow-alt"></i>Press Release Category
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/press-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Press Release CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-copy-alt"></i>
              </div>
              <div class="menu-title">Header Footer</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/header-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Header CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/footer-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Footer CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/header-banner-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Header Banner CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/privacy-policy-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Privacy Policy
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/terms-of-service-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Terms of Service
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/cookies-settings-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Cookies Settings
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-info-circle"></i>
              </div>
              <div class="menu-title">About</div>
            </a>
            <ul>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/about-page-header-section-cms')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Header Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-page-section-one-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section One CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-page-section-two-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Two CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-page-section-three-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Three CMS
                </a>
              </li>
              <!-- <li>
                                <a href="javascript:void(0)" @click="$router.push('/about-page-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Page CMS
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/about-section')">
                                    <i class="bx bx-right-arrow-alt"></i>Page Section Two
                                </a>
              </li>-->
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-briefcase-alt-2"></i>
              </div>
              <div class="menu-title">Career</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-select-multiple"></i>
              </div>
              <div class="menu-title">Shop</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/shop-page-banner-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Shop Banner Cms
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/shop-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Shop Page Cms
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/shopping-cart-footer-cms-state-list')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Shopping Cart Footer Cms
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/product-per-page-options')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Product Per Page Options Cms
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-select-multiple"></i>
              </div>
              <div class="menu-title">Deals</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/deals-dropdown-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Deals Dropdown CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/deals-page-state-list')">
                  <i class="bx bx-right-arrow-alt"></i>Deals Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-select-multiple"></i>
              </div>
              <div class="menu-title">Quiz</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/quiz')">
                  <i class="bx bx-right-arrow-alt"></i>Quiz List
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/quiz-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-cart-alt"></i>
              </div>
              <div class="menu-title">Cart</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/high-deals-cms')">
                  <i class="bx bx-right-arrow-alt"></i>High Deals
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/cart-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-user-circle"></i>
              </div>
              <div class="menu-title">Auth</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/login-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Login Page
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/register-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Register Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-spreadsheet"></i>
              </div>
              <div class="menu-title">Product Details</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/how-to-enjoy-cms')">
                  <i class="bx bx-right-arrow-alt"></i>How to Enjoy CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/related-products-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Related Products CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/discover-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Discover CMS
                </a>
              </li>
              <!-- <li>
                                <a href="javascript:void(0)" @click="$router.push('/reserve-time-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Reserve Time CMS
                                </a>
              </li>-->
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="fadeIn animated bx bxs-purchase-tag"></i>
              </div>
              <div class="menu-title">Weekly Ad</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/weekly-ad-tags')">
                  <i class="bx bx-right-arrow-alt"></i>Tags
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/weekly-ad-state-list')">
                  <i class="bx bx-right-arrow-alt"></i>Weekly Ads List
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/weekly-ad-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Weekly Ads CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bxs-zoom-out"></i>
              </div>
              <div class="menu-title">Popup Modules</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/newsletter-pop-up-state-list')">
                  <i class="bx bx-right-arrow-alt"></i>Newsletter Popup Module
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/offer-pop-up-state-list')">
                  <i class="bx bx-right-arrow-alt"></i>Offer Popup Module
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bxs-category-alt"></i>
              </div>
              <div class="menu-title">Category Icons</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/category')">
                  <i class="bx bx-right-arrow-alt"></i>All
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/Category-states')">
                  <i class="bx bx-right-arrow-alt"></i>State Wise
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-question-mark"></i>
              </div>
              <div class="menu-title">FAQ</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/faq')">
                  <i class="bx bx-right-arrow-alt"></i>FAQ
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/faq-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-shopping-bag"></i>
              </div>
              <div class="menu-title">JARS+</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-one-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section One CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/steps-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Steps Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/benefits-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Benefits Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/benefits-modal')">
                  <i class="bx bx-right-arrow-alt"></i>Benefits Modal
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a href="javascript:void(0)" @click="$router.push('/locations-cms')">
              <i class="bx bx-right-arrow-alt"></i>Locations CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/featured-brand')">
              <i class="bx bx-right-arrow-alt"></i>Featured Brands
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/user-page-banner-cms')">
              <i class="bx bx-right-arrow-alt"></i>User Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/service-type-cms')">
              <i class="bx bx-right-arrow-alt"></i>Service Type CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/age-verification-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Age Verification CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/landing-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Landing Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/pickup-delivery-state-list')">
              <i class="bx bx-right-arrow-alt"></i>Pickup Delivery CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/contact-page-store-list')">
              <i class="bx bx-right-arrow-alt"></i>Contact Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/social-link')">
              <i class="bx bx-right-arrow-alt"></i>Social Links
            </a>
          </li>
          <!-- <li>
            <a href="javascript:void(0)" @click="$router.push('/deals-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Deals Page CMS
            </a>
          </li>-->
        </ul>
      </li>

      <!-- <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fadeIn animated bx bxs-briefcase-alt-2"></i>
          </div>
          <div class="menu-title">Career</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career')">
              <i class="bx bx-right-arrow-alt"></i>Career List
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career-applicant')">
              <i class="bx bx-right-arrow-alt"></i>Career Applicant
            </a>
          </li>
        </ul>
      </li>-->

      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-component"></i>
          </div>
          <div class="menu-title">Component UI</div>
        </a>
        <ul>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-book-content"></i>
              </div>
              <div class="menu-title">Article</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/article-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Article Page
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/article-details-page-component-ui')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Article Details Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-user-circle"></i>
              </div>
              <div class="menu-title">Auth</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/login-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Login Page
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/register-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Register Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/about-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>About Page
            </a>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-briefcase-alt-2"></i>
              </div>
              <div class="menu-title">Career</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Career Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              @click="$router.push('/cookies-settings-page-component-ui')"
            >
              <i class="bx bx-right-arrow-alt"></i>Cookies Settings Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/contact-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Contact Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/deals-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Deals Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/faq-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Faq Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/home-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Home Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/jarsplus-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Jars Plus Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/landing-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Landing Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/newsletter-popup-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Newsletter Popup
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/offer-popup-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Offer Popup
            </a>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-news"></i>
              </div>
              <div class="menu-title">Press</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/press-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Press Page
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/press-details-page-component-ui')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Press Details Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-news"></i>
              </div>
              <div class="menu-title">Product Details</div>
            </a>
            <ul>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/discover-highlights-component-ui')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Discover Highlights
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/privacy-policy-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Privacy Policy Page
            </a>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-briefcase-alt-2"></i>
              </div>
              <div class="menu-title">Shop</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/shop-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Shop Banner
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              @click="$router.push('/terms-condition-page-component-ui')"
            >
              <i class="bx bx-right-arrow-alt"></i>Terms Condition Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/top-ticker-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Top Ticker
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/user-page-banner-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>User Page Banner
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/weekly-ad-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Weekly Ad Page
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>