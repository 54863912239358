import { authHeader } from "../../../../../auth";

export default {
  data() {
    return {
      stateCode: this.$route.query.stateCode,
      stateName: this.$route.query.stateName,
      url: this.$serverURL + this.$api.cms.home.sectionTwoCmsURL,
      setURL: this.$serverURL + this.$api.cms.home.sectionTwoURL,
      cmsData: {},
      title: "",
      buttonText: "",
      buttonLink: "",
      previewImage: "",
      imageFile: "",
      dataList: [],
      singleData: {},
      previewUpdateImage: "",
      updateImageFile: "",
      addButtonText: "Create",
      addButtonIconClass: "fa-solid fa-rotate",
      addButtonClass: "btn btn-primary",
      addButtonFlag: false,
      updateButtonText: "Update",
      updateButtonIconClass: "fa-solid fa-rotate",
      updateButtonClass: "btn btn-primary",
      updateButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Section Two CMS";
    await this.getData();
    this.dataList = await this.getDataList(
      this.setURL + "?stateCode=" + this.stateCode
    );
    var scripts = [
      "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
      "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
      "/core/assets/js/table-datatable.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href =
      "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
    document.head.appendChild(style);
  },
  methods: {
    onImageChange: function (event) {
      this.imageFile = event.target.files[0];
      let input = this.$refs.imageFile;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    onUpdateImageChange: function (event) {
      this.updateImageFile = event.target.files[0];
      let input = this.$refs.updateImageFile;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewUpdateImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    async getData() {
      let response = await this.getCmsData(
        this.url + "?stateCode=" + this.stateCode
      );
      if (response.statusCode == 200) {
        this.cmsData = response.data.length > 0 ? response.data[0] : {};
      } else {
        this.cmsData = {};
      }
    },
    async update() {
      try {
        let data = {
          stateCode: this.stateCode,
          stateName: this.stateName,
          title: this.cmsData.title,
          subTitle: this.cmsData.subTitle,
          buttonText: this.cmsData.buttonText,
          buttonLink: this.cmsData.buttonLink,
          status: this.cmsData.status,
          isDefault: this.cmsData.isDefault,
        };
        this.$swal.showLoading();
        let response = await this.createUpdateCMS(this.url, data);
        if (response.statusCode == 200 || response.statusCode == 201) {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        }
        await this.getData();
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          text: "Something went wrong. Please try again!",
        });
      } finally {
        this.$swal.hideLoading();
      }
    },
    validate: function () {
      if (!this.title) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter title!",
        });
        return false;
      }
      if (!this.buttonText) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter button text!",
        });
        return false;
      }
      if (!this.buttonLink) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter button link!",
        });
        return false;
      }
      if (!this.description) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter description!",
        });
        return false;
      }
      if (!this.imageFile) {
        this.$swal.fire({
          icon: "error",
          text: "Please upload image!",
        });
        return false;
      }
      return true;
    },
    addEntity: async function () {
      if (this.validate() == true) {
        this.addButtonText = "Creating...";
        this.addButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.addButtonClass = "btn btn-secondary";
        this.addButtonFlag = true;
        let token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("stateCode", this.stateCode);
        formData.append("stateName", this.stateName);
        formData.append("title", this.title);
        formData.append("description", this.description);
        formData.append("buttonText", this.buttonText);
        formData.append("buttonLink", this.buttonLink);
        formData.append("image", this.imageFile);
        let config = {
          method: "POST",
          url: this.setURL,
          data: formData,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        await this.$axios(config)
          .then(async (response) => {
            if (response.status == 201) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }
            this.title = "";
            this.description = "";
            this.buttonText = "";
            this.buttonLink = "";
            this.imageFile = "";
            this.previewImage = "";
            this.$refs.imageFile.value = null;
            this.dataList = await this.getDataList(
              this.setURL + "?stateCode=" + this.stateCode
            );
            document.getElementById("closeButton").click();
            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary";
            this.addButtonFlag = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });
            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary";
            this.addButtonFlag = false;
          });
      }
    },
    deleteEntity: function (id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              let config = {
                method: "DELETE",
                url: this.setURL + "/" + id,
                headers: authHeader(),
              };
              console.log("Config: ", config);
              await this.deleteData(config);
              this.dataList = await this.getDataList(
                this.setURL + "?stateCode=" + this.stateCode
              );
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                text: error.response.data.message,
              });
            }
          }
        });
    },
    editEntity: async function (data) {
      this.singleData = data;
    },
    updateValidation: function () {
      if (!this.singleData.title) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter title!",
        });
        return false;
      }
      if (!this.singleData.buttonText) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter button text!",
        });
        return false;
      }
      if (!this.singleData.buttonLink) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter button link!",
        });
        return false;
      }
      if (!this.singleData.description) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter description!",
        });
        return false;
      }
      return true;
    },
    updateEntity: async function () {
      if (this.updateValidation() == true) {
        this.updateButtonText = "Updating...";
        this.updateButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.updateButtonClass = "btn btn-secondary";
        this.updateButtonFlag = true;
        let token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("stateCode", this.stateCode);
        formData.append("stateName", this.stateName);
        formData.append("title", this.singleData.title);
        formData.append("description", this.singleData.description);
        formData.append("buttonText", this.singleData.buttonText);
        formData.append("buttonLink", this.singleData.buttonLink);
        formData.append(
          "image",
          this.updateImageFile ? this.updateImageFile : this.singleData.image
        );
        let config = {
          method: "PATCH",
          url: this.setURL + "/" + this.singleData.id,
          data: formData,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        await this.$axios(config)
          .then(async (response) => {
            if (response.status == 200) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }
            this.singleData = {};
            this.updateImageFile = "";
            this.previewUpdateImage = "";
            this.$refs.updateImageFile.value = null;
            this.dataList = await this.getDataList(
              this.setURL + "?stateCode=" + this.stateCode
            );
            document.getElementById("buttonClose").click();
            this.updateButtonText = "Update";
            this.updateButtonIconClass = "fa-solid fa-rotate";
            this.updateButtonClass = "btn btn-primary";
            this.updateButtonFlag = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });
            this.updateButtonText = "Update";
            this.updateButtonIconClass = "fa-solid fa-rotate";
            this.updateButtonClass = "btn btn-primary";
            this.updateButtonFlag = false;
          });
      }
    },
    updateStatus: async function () {
      try {
        let data = {
          stateCode: this.stateCode,
          stateName: this.stateName,
          title: this.cmsData.title,
          subTitle: this.cmsData.subTitle,
          buttonText: this.cmsData.buttonText,
          buttonLink: this.cmsData.buttonLink,
          status: this.cmsData.status,
          isDefault: this.cmsData.isDefault,
        };
        let response = await this.createUpdateCMS(this.url, data);
        if (response.statusCode == 200 || response.statusCode == 201) {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        }
        await this.getData();
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          text: "Something went wrong. Please try again!",
        });
      }
    },
  },
};
