<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Landing One</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Landing One Settings</li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="update()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Landing One Settings</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-6 mb-3">
                  <div class="border border-3 p-4 rounded h-100">
                    <div class="form-check form-switch mb-3">
                      <label class="form-label">[To show this section on website enable the toogle.]</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.sectionOnestatus"
                        id="flexSwitchCheckDefault"
                      />
                      <img style="width: 100%;" src="/core/assets/images/landing-one/slider.png" />
                      <h6 class="text-center pt-4">
                        <b>Slider Section</b>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <div class="border border-3 p-4 rounded h-100">
                    <div class="form-check form-switch mb-3">
                      <label class="form-label">[To show this section on website enable the toogle.]</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.sectionTwostatus"
                        id="flexSwitchCheckDefault"
                      />
                      <img
                        style="width: 100%;"
                        src="/core/assets/images/landing-one/section-two-lt.png"
                      />
                      <h6 class="text-center pt-4">
                        <b>Section Two</b>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <div class="border border-3 p-4 rounded h-100">
                    <div class="form-check form-switch mb-3">
                      <label class="form-label">[To show this section on website enable the toogle.]</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.sectionThreestatus"
                        id="flexSwitchCheckDefault"
                      />
                      <img
                        style="width: 100%;"
                        src="/core/assets/images/landing-one/section-three.png"
                      />
                      <h6 class="text-center pt-4">
                        <b>Section Three</b>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <div class="border border-3 p-4 rounded h-100">
                    <div class="form-check form-switch mb-3">
                      <label class="form-label">[To show this section on website enable the toogle.]</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.sectionFourstatus"
                        id="flexSwitchCheckDefault"
                      />
                      <img
                        style="width: 100%;"
                        src="/core/assets/images/landing-one/section-four.png"
                      />
                      <h6 class="text-center pt-4">
                        <b>Section Four</b>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <div class="border border-3 p-4 rounded h-100">
                    <div class="form-check form-switch mb-3">
                      <label class="form-label">[To show this section on website enable the toogle.]</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.sectionFivestatus"
                        id="flexSwitchCheckDefault"
                      />
                      <img
                        style="width: 100%;"
                        src="/core/assets/images/landing-one/section-five.png"
                      />
                      <h6 class="text-center pt-4">
                        <b>Section Five</b>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <div class="border border-3 p-4 rounded h-100">
                    <div class="form-check form-switch mb-3">
                      <label class="form-label">[To show this section on website enable the toogle.]</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.sectionSixstatus"
                        id="flexSwitchCheckDefault"
                      />
                      <img
                        style="width: 100%;"
                        src="/core/assets/images/landing-one/section-six.png"
                      />
                      <h6 class="text-center pt-4">
                        <b>Section Six</b>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <div class="border border-3 p-4 rounded h-100">
                    <div class="form-check form-switch mb-3">
                      <label class="form-label">[To show this section on website enable the toogle.]</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.sectionSevenstatus"
                        id="flexSwitchCheckDefault"
                      />
                      <img
                        style="width: 100%;"
                        src="/core/assets/images/landing-one/section-seven.png"
                      />
                      <h6 class="text-center pt-4">
                        <b>Section Seven</b>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <div class="border border-3 p-4 rounded h-100">
                    <div class="form-check form-switch mb-3">
                      <label class="form-label">[To show this section on website enable the toogle.]</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.sectionEightstatus"
                        id="flexSwitchCheckDefault"
                      />
                      <img
                        style="width: 100%;"
                        src="/core/assets/images/landing-one/section-eight.png"
                      />
                      <h6 class="text-center pt-4">
                        <b>Section Eight</b>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <div class="border border-3 p-4 rounded h-100">
                    <div class="form-check form-switch mb-3">
                      <label class="form-label">[To show this section on website enable the toogle.]</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.sectionNinestatus"
                        id="flexSwitchCheckDefault"
                      />
                      <img
                        style="width: 100%;"
                        src="/core/assets/images/landing-one/section-nine.png"
                      />
                      <h6 class="text-center pt-4">
                        <b>Section Nine</b>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <div class="border border-3 p-4 rounded h-100">
                    <div class="form-check form-switch mb-3">
                      <label class="form-label">[To show this section on website enable the toogle.]</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.sectionTenstatus"
                        id="flexSwitchCheckDefault"
                      />
                      <img
                        style="width: 100%;"
                        src="/core/assets/images/landing-one/section-ten.png"
                      />
                      <h6 class="text-center pt-4">
                        <b>Section Ten</b>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-grid mt-5">
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/settings.js"></script>


<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>