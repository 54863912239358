<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">All Page Meta CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">All Page Meta CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Page Meta CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="border border-3 p-4 rounded">
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Signin Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.signinPageMetaKeyword" class="form-control" placeholder="Signin page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Signin Page Meta Description:</label>
                                                <textarea v-model="cmsData.signinPageMetaDescription" class="form-control" placeholder="Signin page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Signup Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.signupPageMetaKeyword" class="form-control" placeholder="Signup page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Signup Page Meta Description:</label>
                                                <textarea v-model="cmsData.signupPageMetaDescription" class="form-control" placeholder="Signup page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.homePageMetaKeyword" class="form-control" placeholder="Home page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Description:</label>
                                                <textarea v-model="cmsData.homePageMetaDescription" class="form-control" placeholder="Home page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.shopPageMetaKeyword" class="form-control" placeholder="Shop page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Page Meta Description:</label>
                                                <textarea v-model="cmsData.shopPageMetaDescription" class="form-control" placeholder="Shop page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Special Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.specialPageMetaKeyword" class="form-control" placeholder="Special page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Special Page Meta Description:</label>
                                                <textarea v-model="cmsData.specialPageMetaDescription" class="form-control" placeholder="Special page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Product Details Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.productDetailsPageMetaKeyword" class="form-control" placeholder="Product details page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Product Details Page Meta Description:</label>
                                                <textarea v-model="cmsData.productDetailsPageMetaDescription" class="form-control" placeholder="Product details page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.cartPageMetaKeyword" class="form-control" placeholder="Cart page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page Meta Description:</label>
                                                <textarea v-model="cmsData.cartPageMetaDescription" class="form-control" placeholder="Cart page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Daily Deals Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.dailyDealsPageMetaKeyword" class="form-control" placeholder="Daily Deals page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Daily Deals Page Meta Description:</label>
                                                <textarea v-model="cmsData.dailyDealsPageMetaDescription" class="form-control" placeholder="Daily  Deals page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">JARS+ Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.jarsPlusPageMetaKeyword" class="form-control" placeholder="JARS+ page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">JARS+ Page Meta Description:</label>
                                                <textarea v-model="cmsData.jarsPlusPageMetaDescription" class="form-control" placeholder="JARS+ page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Us Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.aboutUsPageMetaKeyword" class="form-control" placeholder="About Us page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Us Page Meta Description:</label>
                                                <textarea v-model="cmsData.aboutUsPageMetaDescription" class="form-control" placeholder="About Us page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Articles Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.articlesPageMetaKeyword" class="form-control" placeholder="Articles page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Articles Page Meta Description:</label>
                                                <textarea v-model="cmsData.articlesPageMetaDescription" class="form-control" placeholder="Article page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Article Details Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.articleDetailsPageMetaKeyword" class="form-control" placeholder="Article Details page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Article Details Page Meta Description:</label>
                                                <textarea v-model="cmsData.articleDetailsPageMetaDescription" class="form-control" placeholder="Article Details page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Press Release Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.pressReleasePageMetaKeyword" class="form-control" placeholder="Press Release page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Press Release Page Meta Description:</label>
                                                <textarea v-model="cmsData.pressReleasePageMetaDescription" class="form-control" placeholder="Press Release page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Press Release Details Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.pressReleaseDetailsPageMetaKeyword" class="form-control" placeholder="Press Release Details page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Press Release Details Page Meta Description:</label>
                                                <textarea v-model="cmsData.pressReleaseDetailsPageMetaDescription" class="form-control" placeholder="Press Release Details page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Us Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.contactUsPageMetaKeyword" class="form-control" placeholder="Contact Us page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Us Page Meta Description:</label>
                                                <textarea v-model="cmsData.contactUsPageMetaDescription" class="form-control" placeholder="Contact Us page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.faqPageMetaKeyword" class="form-control" placeholder="FAQ page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Page Meta Description:</label>
                                                <textarea v-model="cmsData.faqPageMetaDescription" class="form-control" placeholder="FAQ page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.careerPageMetaKeyword" class="form-control" placeholder="Career page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Meta Description:</label>
                                                <textarea v-model="cmsData.careerPageMetaDescription" class="form-control" placeholder="Career page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Department Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.careerDepartmentPageMetaKeyword" class="form-control" placeholder="Career Department page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Department Page Meta Description:</label>
                                                <textarea v-model="cmsData.careerDepartmentPageMetaDescription" class="form-control" placeholder="Career Department page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Details Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.careerDetailsPageMetaKeyword" class="form-control" placeholder="Career Details page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Details Page Meta Description:</label>
                                                <textarea v-model="cmsData.careerDetailsPageMetaDescription" class="form-control" placeholder="Career Details page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.privacyPageMetaKeyword" class="form-control" placeholder="Privacy page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Page Meta Description:</label>
                                                <textarea v-model="cmsData.privacyPageMetaDescription" class="form-control" placeholder="Privacy page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms & Conditions Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.termsPageMetaKeyword" class="form-control" placeholder="Terms & Conditions page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms & Conditions Page Meta Description:</label>
                                                <textarea v-model="cmsData.termsPageMetaDescription" class="form-control" placeholder="Terms & Conditions page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Cookies Settings Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.cookiesSettingsPageMetaKeyword" class="form-control" placeholder="Cookies Settings page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Cookies Settings Page Meta Description:</label>
                                                <textarea v-model="cmsData.cookiesSettingsPageMetaDescription" class="form-control" placeholder="Cookies Settings page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Profile Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.profilePageMetaKeyword" class="form-control" placeholder="Profile page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Profile Page Meta Description:</label>
                                                <textarea v-model="cmsData.profilePageMetaDescription" class="form-control" placeholder="Profile page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Success Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.successPageMetaKeyword" class="form-control" placeholder="Success page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Success Page Meta Description:</label>
                                                <textarea v-model="cmsData.successPageMetaDescription" class="form-control" placeholder="Success page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Store Details Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.storeDetailsPageMetaKeyword" class="form-control" placeholder="Store Details page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Store Details Page Meta Description:</label>
                                                <textarea v-model="cmsData.storeDetailsPageMetaDescription" class="form-control" placeholder="Store Details page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Retailer Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.retailerPageMetaKeyword" class="form-control" placeholder="Retailer page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Retailer Page Meta Description:</label>
                                                <textarea v-model="cmsData.retailerPageMetaDescription" class="form-control" placeholder="Retailer page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Order Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.orderPageMetaKeyword" class="form-control" placeholder="Order page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Order Page Meta Description:</label>
                                                <textarea v-model="cmsData.orderPageMetaDescription" class="form-control" placeholder="Order page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">My Items Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.myItemsPageMetaKeyword" class="form-control" placeholder="My Items page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">My Items Page Meta Description:</label>
                                                <textarea v-model="cmsData.myItemsPageMetaDescription" class="form-control" placeholder="My Items page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Get Direction Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.getDirectionPageMetaKeyword" class="form-control" placeholder="Get Direction page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Get Direction Page Meta Description:</label>
                                                <textarea v-model="cmsData.getDirectionPageMetaDescription" class="form-control" placeholder="Get Direction page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Forgot Password Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.forgotPasswordPageMetaKeyword" class="form-control" placeholder="Forgot Password page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Forgot Password Page Meta Description:</label>
                                                <textarea v-model="cmsData.forgotPasswordPageMetaDescription" class="form-control" placeholder="Forgot Password page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Review Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.reviewPageMetaKeyword" class="form-control" placeholder="Review page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Review Page Meta Description:</label>
                                                <textarea v-model="cmsData.reviewPageMetaDescription" class="form-control" placeholder="Review page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Reset Password Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.resetPasswordPageMetaKeyword" class="form-control" placeholder="Reset Password page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Reset Password Page Meta Description:</label>
                                                <textarea v-model="cmsData.resetPasswordPageMetaDescription" class="form-control" placeholder="Reset Password page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Invoice Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.invoicePageMetaKeyword" class="form-control" placeholder="Invoice page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Invoice Page Meta Description:</label>
                                                <textarea v-model="cmsData.invoicePageMetaDescription" class="form-control" placeholder="Invoice page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Category Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.categoryPageMetaKeyword" class="form-control" placeholder="Category page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Category Page Meta Description:</label>
                                                <textarea v-model="cmsData.categoryPageMetaDescription" class="form-control" placeholder="Category page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Age Verification Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.ageVerificationPageMetaKeyword" class="form-control" placeholder="Age Verification page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Age Verification Page Meta Description:</label>
                                                <textarea v-model="cmsData.ageVerificationPageMetaDescription" class="form-control" placeholder="Age Verification page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Weekly Ad Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.weeklyAdPageMetaKeyword" class="form-control" placeholder="Weekly ad page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Weekly Ad Page Meta Description:</label>
                                                <textarea v-model="cmsData.weeklyAdPageMetaDescription" class="form-control" placeholder="Weekly ad page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Deals Jars Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.dealsJarsPageMetaKeyword" class="form-control" placeholder="Deals jars page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Deals Jars Page Meta Description:</label>
                                                <textarea v-model="cmsData.dealsJarsPageMetaDescription" class="form-control" placeholder="Deals jars page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Map Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.mapPageMetaKeyword" class="form-control" placeholder="Map page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Map Page Meta Description:</label>
                                                <textarea v-model="cmsData.mapPageMetaDescription" class="form-control" placeholder="Map page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Map Details Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.mapDetailsPageMetaKeyword" class="form-control" placeholder="Map Details page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Map Details Page Meta Description:</label>
                                                <textarea v-model="cmsData.mapDetailsPageMetaDescription" class="form-control" placeholder="Map Details page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="updateCms()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/site-page-meta-cms.js"></script>