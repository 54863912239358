<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Landing Three Section Four Page CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
              >Landing Three Section Four Page CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Landing Three Section Four Page CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label class="form-label">Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.title"
                          class="form-control"
                          placeholder="Enter title"
                        />
                      </div>

                      <!-- <div class="mb-3">
                        <label class="form-label">Address:</label>
                        <GMapAutocomplete
                          @place_changed="handleAddress"
                          :value="cmsData.address"
                          :class="'form-control'"
                          v-on:input="cmsData.address = $event.target.value"
                        ></GMapAutocomplete>
                      </div>-->

                      <div class="col-6 mb-3">
                        <label class="form-label">List One Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.listOneTitle"
                          class="form-control"
                          placeholder="List One Title"
                        />
                      </div>

                      <div class="col-12 mb-3">
                        <label class="form-label">List One Description:</label>
                        <ckeditor :editor="editor" v-model="cmsData.listOneDescription" :config="editorConfig"></ckeditor>
                      </div>

                      <div class="col-6 mb-3">
                        <label class="form-label">List Two Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.listTwoTitle"
                          class="form-control"
                          placeholder="List Two Title"
                        />
                      </div>

                      <div class="col-12 mb-3">
                        <label class="form-label">List Two Description:</label>
                        <ckeditor :editor="editor" v-model="cmsData.listTwoDescription" :config="editorConfig"></ckeditor>
                      </div>

                      <div class="col-6 mb-3">
                        <label class="form-label">List Three Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.listThreeTitle"
                          class="form-control"
                          placeholder="List Three Title"
                        />
                      </div>

                      <div class="col-12 mb-3">
                        <label class="form-label">List Three Description:</label>
                        <ckeditor :editor="editor" v-model="cmsData.listThreeDescription" :config="editorConfig"></ckeditor>
                      </div>

                      <div class="col-6 mb-3">
                        <label for="inputProductTitle" class="form-label">
                          Image:
                          <strong>
                            [Preferred Image Size: 334X428, Max Upload Limit:
                            1MB]
                          </strong>
                        </label>
                        <img
                          style="position: relative; left: 11px"
                          v-if="preview.imageOne"
                          :src="preview.imageOne"
                          height="70"
                          width="100"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-else-if="cmsData.image"
                          :src="cmsData.image"
                          height="70"
                          width="100"
                        />
                        <input
                          class="form-control mt-3"
                          type="file"
                          ref="imageOne"
                          accept="image/*"
                          @change="handleUploadImage('image','imageOne','imageOne', $event)"
                        />
                      </div>

                      
                    </div>
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/landing-three-section-four.js"></script>