<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Home</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Offer Pop Up CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body" style="display: flex; align-items: center;">
          <h5 style="margin: 0;">{{stateName}}</h5>
          <div
            style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
            @click="$router.push({ path: '/offer-pop-up-state-list'})"
          >
            <span style="margin-right: 5px;">
              <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
            </span>
            <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
          </div>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Offer Pop Up CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label class="form-label">Title:</label>
                        <input type="text" v-model="cmsData.title" class="form-control" id="inputPageHeaderTitle"
                          placeholder="Enter title" />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">SubTitle:</label>
                        <input type="text" v-model="cmsData.subTitle" class="form-control" id="inputPageHeaderTitle"
                          placeholder="Enter subTitle" />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Offer Button Text:</label>
                        <input type="text" v-model="cmsData.offerButtonText" class="form-control" id="inputPageHeaderTitle"
                          placeholder="Enter offer button text" />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Offer Button Link:</label>
                        <input type="text" v-model="cmsData.offerButtonLink" class="form-control" id="inputPageHeaderTitle"
                          placeholder="Enter offer button link" />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Bottom Button Text:</label>
                        <input type="text" v-model="cmsData.bottomButtonText" class="form-control"
                          id="inputPageHeaderTitle" placeholder="Enter bottom button text" />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Appear After Time (in seconds):</label>
                        <input type="number" v-model="cmsData.time" class="form-control"
                          id="inputPageHeaderTitle" placeholder="Enter appear after time (in seconds)" />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Expired Time (in seconds):</label>
                        <input type="number" v-model="cmsData.expireTime" class="form-control"
                          id="inputPageHeaderTitle" placeholder="Enter expired time (in seconds)" />
                      </div>
                      <div class="col-12 mb-3">
														<label class="form-label">Pages:</label>
														<Multiselect v-model="selectedPages" :options="pages" mode="tags" :close-on-select="false" :searchable="true" :create-option="true"/>
													</div>
                      <div class="col-12 mb-3">
                        <div class="row">
                          <div class="col-12">
                            <label for="inputProductTitle" class="form-label">
                              Image:
                              <strong>[Preferred Image Size: 706X706, Max Upload Limit: 1MB]</strong>
                            </label>
                          </div>
                          <div class="col-12">
                            <input class="form-control mt-3" type="file" ref="image" accept="image/*"
                              @change="handleUploadImage($event)" />
                          </div>
                        </div>
                        <div class="mt-3">
                          <img style="position: relative; left: 11px" v-if="previewImage" :src="previewImage"
                            height="100" />
                          <img style="position: relative; left: 11px" v-else-if="cmsData.image" :src="cmsData.image"
                            height="100" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/offer-pop-up-cms.js"></script>