<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Locations CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Locations CMS</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group"></div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Locations CMS List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Contact No</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ row.retailerName }}</td>
                  <td>
                    <img :src="row.featuredImage" height="80" />
                  </td>
                  <td>{{ (row.retailerMobileNumber && checkNullString(row.retailerMobileNumber)) ?
                    row.retailerMobileNumber : "N/A" }}</td>
                  <td>{{ $filters.formatDate(row.createdAt) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        v-on:click="editData(row)" class="edit-button">
                        <i class="bx bxs-edit"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Contact No</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit Retailer</h5>
            <button type="button" class="btn-close" id="close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Retailer Name:</label>
                  <input type="text" disabled v-model="singleData.retailerName" class="form-control" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Page Title:</label>
                  <input type="text" v-model="singleData.pageTitle" class="form-control" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Today's Special Title:</label>
                  <input type="text" v-model="singleData.todaysSpecial" class="form-control" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Cannabis Product Title:</label>
                  <input type="text" v-model="singleData.cannabisProductTitile" class="form-control" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Store Feature Title:</label>
                  <input type="text" v-model="singleData.storeFeatureTitle" class="form-control" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Faq Title:</label>
                  <input type="text" v-model="singleData.faqTitle" class="form-control" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Contact Number:</label>
                  <input type="text" v-model="singleData.retailerMobileNumber" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Email:</label>
                  <input type="text" v-model="singleData.email" class="form-control" />
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">About:</label>
                  <!-- <textarea type="text" v-model="singleData.about" class="form-control" /> -->
                  <ckeditor :editor="editor" v-model="singleData.about" :config="editorConfig"></ckeditor>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Cannabis Product Description:</label>
                  <textarea type="text" v-model="singleData.cannabisProductDescription" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Schema:</label>
                  <textarea type="text" v-model="singleData.schema" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Canonical:</label>
                  <textarea type="text" v-model="singleData.canonical" class="form-control" />
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Jars Rewards:</label>
                  <ckeditor :editor="editor" v-model="singleData.jarsRewards" :config="editorConfig"></ckeditor>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Refer A Friend:</label>
                  <ckeditor :editor="editor" v-model="singleData.referAFriend" :config="editorConfig"></ckeditor>
                </div>
              </div>
            </div>
            <hr />

            <div class="mb-3">
              <label class="form-label">Image: [Preferred Image Size: 510X500, Max Upload Limit: 1MB]</label>
              <input class="form-control" @change="onImageChange" type="file" ref="image" />
            </div>
            <div class="mb-3">
              <div class="col-md-12">
                <img v-if="previewImage" class="img-thumbnail" :src="previewImage" style="height: 150px;" />
                <img v-else-if="singleData.featuredImage" class="img-thumbnail" :src="singleData.featuredImage"
                  style="height: 150px;" />
              </div>
            </div>
            <!-- galley image -->
            <div class="border border-3 p-4 rounded mb-4">
              <label for="exampleInputEmail1" class="mb-3">
                Store Images (Max Limit 4):
                <strong>
                  [Preferred Image Size: 900X550, Max Upload Limit:
                  1MB]
                </strong>
              </label>
              <input type="file" style="display: none" @change="uploadStoreImages" ref="storeImages" />

              <div class="card text-center">
                <div @click="$refs.storeImages.click()" class="card-body btn btn-outline-primary"
                  :style="[disableButton == true || previewStoreImages.length > 3 ? { 'pointer-events': 'none' } : '',]">
                  <h5>Select Image</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3 mb-2" v-for="(data, index) in previewStoreImages" :key="index">
                  <i class="fa-solid fa-circle-xmark close-button" @click="removeUploadedStoreImage(index)"></i>
                  <img :src="data" height="150" style="width: 80%;" />
                </div>
              </div>
            </div>
            <!-- store feature -->
            <div class="border border-3 p-4 rounded mb-4">
              <label for="exampleInputEmail1" class="mb-3">
                <strong>Store Features</strong>
              </label>
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Content One Title:</label>
                            <input type="text" v-model="singleData.storeFeatureContentOneTitle" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Content One Sub Title:</label>
                            <input type="text" v-model="singleData.storeFeatureContentOneSubTitle"
                              class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="form-label">Icon Image: [Preferred Image Size: 45X45, Max Upload Limit:
                              1MB]</label>
                            <input class="form-control" @change="storeFeatureContentFeaturedImageOneOnChange"
                              type="file" ref="storeFeatureContentFeaturedImageOne" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <img v-if="storeFeatureContentPreviewImageOne" class="img-thumbnail"
                            :src="storeFeatureContentPreviewImageOne" style="height: 100px;" />
                          <img v-else-if="singleData.storeFeatureContentFeaturedImageOne" class="img-thumbnail"
                            :src="singleData.storeFeatureContentFeaturedImageOne" style="height: 100px;" />
                          <img v-else class="img-thumbnail" src="/core/assets/images/retailer_image.png"
                            style="height: 100px;" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Content Two Title:</label>
                            <input type="text" v-model="singleData.storeFeatureContentTwoTitle" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Content Two Sub Title:</label>
                            <input type="text" v-model="singleData.storeFeatureContentTwoSubTitle"
                              class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="form-label">Content Two Icon Image: [Preferred Image Size: 45X45, Max Upload
                              Limit: 1MB]</label>
                            <input class="form-control" @change="storeFeatureContentFeaturedImageTwoOnChange"
                              type="file" ref="storeFeatureContentFeaturedImageTwo" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <img v-if="storeFeatureContentPreviewImageTwo" class="img-thumbnail"
                            :src="storeFeatureContentPreviewImageTwo" style="height: 100px;" />
                          <img v-else-if="singleData.storeFeatureContentFeaturedImageTwo" class="img-thumbnail"
                            :src="singleData.storeFeatureContentFeaturedImageTwo" style="height: 100px;" />
                          <img v-else class="img-thumbnail" src="/core/assets/images/retailer_image.png"
                            style="height: 100px;" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Content Three Title:</label>
                            <input type="text" v-model="singleData.storeFeatureContentThreeTitle"
                              class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Content Three Sub Title:</label>
                            <input type="text" v-model="singleData.storeFeatureContentThreeSubTitle"
                              class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="form-label">Content Three Icon Image: [Preferred Image Size: 45X45, Max Upload
                              Limit: 1MB]</label>
                            <input class="form-control" @change="storeFeatureContentFeaturedImageThreeOnChange"
                              type="file" ref="storeFeatureContentFeaturedImageThree" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <img v-if="storeFeatureContentPreviewImageThree" class="img-thumbnail"
                            :src="storeFeatureContentPreviewImageThree" style="height: 100px;" />
                          <img v-else-if="singleData.storeFeatureContentFeaturedImageThree" class="img-thumbnail"
                            :src="singleData.storeFeatureContentFeaturedImageThree" style="height: 100px;" />
                          <img v-else class="img-thumbnail" src="/core/assets/images/retailer_image.png"
                            style="height: 100px;" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="border border-3 p-4 rounded mt-3">
              <div class="mb-3">
                <div class="text-start gap-2">
                  <strong>City Page Section</strong>
                </div>
                <div class="mt-3">
                  <div class="mb-3">
                    <label for="inputHeaderText" class="form-label">Header Text:</label>
                    <input type="text" v-model="singleData.headerText" class="form-control"
                      placeholder="Enter Header Text" />
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-12">
                      <label class="form-label" for="description">Paragraph Text:</label>
                      <ckeditor :editor="editor" v-model="singleData.paragraphText" :config="editorConfig"></ckeditor>
                    </div>
                  </div>
                </div>
                <div class="text-start gap-2">
                  <strong>Anchor Tag</strong>
                </div>
                <div class="border border-3 p-4 rounded mt-3">
                  <div v-for="(section, index) in singleData.anchorTag" :key="index" class="mt-3 row d-flex align-items-center">
                    <div class="mb-3 col-md-5">
                      <label for="inputAnchorText" class="form-label">Anchor Text:</label>
                      <input type="text" v-model="section.anchorText" class="form-control"
                        placeholder="Enter AnchorText" />
                    </div>
                    <div class="mb-3 col-md-5">
                      <label for="inputAnchorLink" class="form-label">Anchor Link:</label>
                      <input type="text" v-model="section.anchorLink" class="form-control"
                        placeholder="Enter Anchor Link" />
                    </div>
                    <div class="mb-3 col-md-2 mt-2">
                      <br>
                      <button type="button" @click="removeCitySection(index)" class="btn btn-danger">
                        Remove
                      </button>
                    </div>
                  </div>
                  <button v-if="!counterTwo" type="button" @click="addCitySection" class="btn btn-primary mt-3">
                    Add Tag
                  </button>
                </div>
              </div>
            </div>
            <br>
            <div class="d-flex flex-row-reverse">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary me-3" @click="update">Update</button>
            </div>
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="update">Update</button>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>

<script src="../js/retailer.js"></script>