import { authHeader } from "../../../auth";

export default {
  name: "PressReleaseCategory",
  data() {
    return {
      navList: [],
      navId: null,
      singleContent: [],
      selectedImageOne: null,
      selectedImageTwo: null,
      imageOne: "",
      imageTwo: "",
      previewImageOne: "",
      previewImageTwo: "",
      imageFileOne: "",
      imageFileTwo: "",
      updateButtonText: "Update",
      updateButtonIconClass: "fa-solid fa-rotate",
      updateButtonClass: "btn btn-primary",
      updateButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Edit Press Release Category";
    this.navId = this.$route.params.id;
    await this.getNavData();
    await this.getNavList();
    var scripts = [
      "/core/assets/js/jquery.min.js",
      "/core/assets/plugins/simplebar/js/simplebar.min.js",
      "/core/assets/plugins/metismenu/js/metisMenu.min.js",
      "./core/assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js",
      "/core/assets/plugins/smart-wizard/js/jquery.smartWizard.min.js",
      "/core/assets/js/form-wizard.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  methods: {
    onOneImageChange(event) {
      this.imageOne = event.target.files[0];
      let input = this.$refs.imageFileOne;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImageOne = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    onTwoImageChange(event) {
      this.imageTwo = event.target.files[0];
      let input = this.$refs.imageFileTwo;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImageTwo = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    getNavList: function () {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.pressRelease.pressReleaseCategory,
        headers: authHeader(),
      };
      this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.navList = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again!",
          });
        });
    },
    getNavData: async function () {
      let config = {
        method: "GET",
        url:
          this.$serverURL +
          this.$api.pressRelease.pressReleaseCategory +
          "/" +
          this.navId,
        headers: authHeader(),
      };
      await this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.singleContent = response.data.data;
            console.log("this.singleContent", response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again!",
          });
        });
    },
    validate: function () {
      if (!this.singleContent.title) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter title!",
        });
        return false;
      }
      return true;
    },
    updateNav: async function () {
      if (this.validate() == true) {
        this.updateButtonText = "Updating...";
        this.updateButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.updateButtonClass = "btn btn-secondary";
        this.updateButtonFlag = true;
        let token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("title", this.singleContent.title);
        formData.append("isShowNav", this.singleContent.isShowNav);
        formData.append(
          "imageOne",
          this.imageOne ? this.imageOne : this.singleContent.imageOne
        );
        formData.append(
          "imageTwo",
          this.imageTwo ? this.imageTwo : this.singleContent.imageTwo
        );

        let config = {
          method: "PATCH",
          url:
            this.$serverURL +
            this.$api.pressRelease.pressReleaseCategory +
            "/" +
            this.navId,
          data: formData,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        await this.$axios(config)
          .then((response) => {
            if (response.status == 200) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
              this.imageOne = "";
              this.imageTwo = "";
              this.imageFileOne = "";
              this.imageFileTwo = "";
              this.$refs.imageFileOne.value = null;
              this.$refs.imageFileTwo.value = null;
              this.previewImageOne = "";
              this.previewImageTwo = "";
              this.updateButtonText = "Update";
              this.updateButtonIconClass = "fa-solid fa-rotate";
              this.updateButtonClass = "btn btn-primary";
              this.updateButtonFlag = false;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong. Please try again!",
            });
            this.updateButtonText = "Update";
            this.updateButtonIconClass = "fa-solid fa-rotate";
            this.updateButtonClass = "btn btn-primary";
            this.updateButtonFlag = false;
          });
        this.$router.push("/press-release-category");
      }
    },
  },
};
