<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Pickup Delivery CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Pickup Delivery CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body" style="display: flex; align-items: center;">
          <h5 style="margin: 0;">{{stateName}}</h5>
          <div
            style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
            @click="$router.push({ path: '/pickup-delivery-state-list'})"
          >
            <span style="margin-right: 5px;">
              <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
            </span>
            <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
          </div>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Pickup Delivery CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="row">
                      <div class="col-6 mb-3">
                        <label class="form-label">Pickup Search Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.pickupSearchTitle"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter pickup search title"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Pickup Input Placeholder:</label>
                        <input
                          type="text"
                          v-model="cmsData.pickupInputPlaceholder"
                          class="form-control"
                          id="inputPageHeaderPageName"
                          placeholder="Enter pickup input placeholder"
                        />
                      </div>

                      <div class="col-12 mb-3">
                        <label class="form-label">Delivery Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.deliveryTitle"
                          class="form-control"
                          id="inputPageHeaderPageName"
                          placeholder="Enter Delivery Title"
                        />
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Delivery Description:</label>
                        <!-- <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          v-model="cmsData.deliveryDescription"
                        ></textarea> -->
                        <ckeditor :editor="editor" v-model="cmsData.deliveryDescription" :config="editorConfig"></ckeditor>
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Delivery Search Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.deliverySearchTitle"
                          class="form-control"
                          id="inputPageHeaderPageName"
                          placeholder="Enter delivery search title"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Delivery Input Placeholder:</label>
                        <input
                          type="text"
                          v-model="cmsData.deliveryInputPlaceholder"
                          class="form-control"
                          id="inputPageHeaderPageName"
                          placeholder="Enter pickup page title"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/pickup-delivery.js"></script>