<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Landing Url Slug Settings</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Landing Url Slug Settings</li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Landing Url Slug Settings</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <div class="mb-3">
                          <div class="input-group">
                            <label class="form-label col-md-12">Landing One Slug:</label>
                            <div class="input-group-append">
                              <span class="input-group-text">/landing/</span>
                            </div>
                            <input type="text" v-model="cmsData.urlSlugOne" class="form-control" />
                            <div class="input-group-append">
                              <span
                                class="input-group-text"
                                style="cursor: pointer;"
                                @click="copyLink(cmsData.urlSlugOne)"
                              >Copy Link</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <div class="mb-3">
                          <div class="input-group">
                            <label class="form-label col-md-12">Landing Two Slug:</label>
                            <div class="input-group-append">
                              <span class="input-group-text">/landing/</span>
                            </div>
                            <input type="text" v-model="cmsData.urlSlugTwo" class="form-control" />
                            <div class="input-group-append">
                              <span
                                class="input-group-text"
                                style="cursor: pointer;"
                                @click="copyLink(cmsData.urlSlugTwo)"
                              >Copy Link</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <div class="mb-3">
                          <div class="input-group">
                            <label class="form-label col-md-12">Landing Three Slug:</label>
                            <div class="input-group-append">
                              <span class="input-group-text">/landing/</span>
                            </div>
                            <input type="text" v-model="cmsData.urlSlugThree" class="form-control" />
                            <div class="input-group-append">
                              <span
                                class="input-group-text"
                                style="cursor: pointer;"
                                @click="copyLink(cmsData.urlSlugThree)"
                              >Copy Link</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/custom-url-slug.js"></script>