<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Landing One Section Four CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Landing One Section Four CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Landing One Section Four CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-6 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label class="form-label">Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.title"
                          class="form-control"
                          placeholder="Enter title"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Description:</label>
                        <textarea
                          class="form-control"
                          rows="3"
                          v-model="cmsData.description"
                          placeholder="Enter description"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-12 mb-3">
                      <label for="inputProductTitle" class="form-label">
                        Background Image:
                        <strong>
                          [Preferred Image Size: 3840X1476, Max Upload Limit:
                          1MB]
                        </strong>
                      </label>
                      <img
                        style="position: relative; left: 11px"
                        v-if="previewImage"
                        :src="previewImage"
                        width="200"
                      />
                      <img
                        style="position: relative; left: 11px"
                        v-else-if="cmsData.backgroundImage"
                        :src="cmsData.backgroundImage"
                        width="200"
                      />
                      <input
                        class="form-control mt-3"
                        type="file"
                        ref="backgroundImage"
                        accept="image/*"
                        @change="handleUploadImage($event)"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="d-grid">
                      <button type="submit" class="btn btn-primary">Save</button>
                    </div>`
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/landing-one-section-four.js"></script>