import { authHeader } from '../../../../auth';

export default {
    name: "EditHomePageUptoBanner",
    data() {
        return {
            editData: [],
            title: '',
            description: '',
            buttonText: '',
            buttonLink: '',
            image: '',
            previewImage: '',
            imageFile: '',
            sectionId: ''
        }
    },
    async created() {
        document.title = "JARS - Home Page Upto Banner";
    },
    mounted() {
        if(this.$route.params.id != undefined) {
            this.sectionId = this.$route.params.id;
        }
        this.getSectionData(this.sectionId);
    },
    methods: {
        getSectionData: async function(id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cms.homePageUptoBannerURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.editData = response.data.data[0];
                    this.title = this.editData.title;
                    this.description = this.editData.description;
                    this.buttonText = this.editData.buttonText;
                    this.buttonLink = this.editData.buttonLink;
                    this.image = this.editData.image;
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onImageChange(event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        update: async function() {
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("title", this.title);
            formData.append("description", this.description);
            formData.append("buttonText", this.buttonText);
            formData.append("buttonLink", this.buttonLink);
            formData.append("image", this.imageFile ? this.imageFile : this.image);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.cms.homePageUptoBannerURL + '/' + this.sectionId,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.title = '';
                this.description = '';
                this.buttonText = '';
                this.buttonLink = '';
                this.imageFile = '';
                this.$refs.imageFile.value = null;
                this.$router.push("/upto-section");
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}