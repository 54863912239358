<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">FAQ</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">FAQ</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button type="button" class="btn btn-primary" @click="$router.push('/faq')">
              <i class="bx bx-coin-stack"></i>FAQ List
            </button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Update FAQ</h5>
          <hr />
          <form @submit.prevent="updateFaq">
            <div class="border border-3 p-2">
              <div class="row">
                <div class="col-12 pt-2">
                  <label class="form-label">Question:</label>
                  <textarea v-model="singleFaq.question" class="form-control" cols="30" rows="4"></textarea>
                </div>
                <div class="col-12 pt-2">
                  <label class="form-label">Answer:</label>
                  <ckeditor :editor="editor" v-model="singleFaq.answer" :config="editorConfig"></ckeditor>
                </div>
                <div class="col-12 pt-2">
                  <button type="submit" :class="updateButtonClass" :disabled="updateButtonFlag == true"><i
                      :class='updateButtonIconClass'></i>{{ updateButtonText }}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/edit-faq.js"></script>