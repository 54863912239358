export default {
    data() {
        return {
            stateCode: this.$route.query.stateCode,
            stateName: this.$route.query.stateName,
            url: this.$serverURL + this.$api.cms.dealsPageCmsURLNew,
            cmsData: {
                stateCode: "",
                stateName: "",
                shopDealPageTitle: "",
                shopDealPageSubTitle: "",
                shopDealCategoryTitle: "",
                shopDealCategoryButtonOneTitle: "",
                shopDealCategoryButtonOneLink: "",
                shopDealCategoryButtonTwoTitle: "",
                shopDealCategoryButtonTwoLink: "",
                shopDealSectionOneBackgroundImage: "",
                shopDealSpecialCategoryTitle: "",
                shopDealBannerOneImage: "",
                shopDealBannerOneLink: "",
                shopDealBannerTwoDesktopImage: "",
                shopDealBannerTwoMobileImage: "",
                // shopDealBannerTwoButtonText: "",
                shopDealBannerTwoLink: "",
                shopDealBannerThreeImage: "",
                // shopDealBannerThreeButtonText: "",
                shopDealBannerThreeLink: "",
                shopDealFirstPurchaseBackgoundImage: "",
                shopDealFirstPurchaseTitle: "",
                shopDealFirstPurchaseSubTitle: "",
                shopDealFirstPurchaseButtonText: "",
                shopDealFirstPurchaseButtonLink: "",
            },
            preview: {
                shopDealSectionOneBackgroundImage: "",
                shopDealBannerOneImage: "",
                shopDealBannerTwoDesktopImage: "",
                shopDealBannerTwoMobileImage: "",
                shopDealBannerThreeImage: "",
                shopDealFirstPurchaseBackgoundImage: "",
            }
        }
    },
    async created() {
        document.title = "Jars - Deals Page CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url + '?stateCode=' + this.stateCode);
            if (response.statusCode == 200) {
                let tempCmsData = this.cmsData
                this.cmsData = (response.data.length > 0) ? response.data[0] : tempCmsData;
            }
            else {
                this.cmsData = {
                    stateCode: "",
                    stateName: "",
                    shopDealPageTitle: "",
                    shopDealPageSubTitle: "",
                    shopDealCategoryTitle: "",
                    shopDealCategoryButtonOneTitle: "",
                    shopDealCategoryButtonOneLink: "",
                    shopDealCategoryButtonTwoTitle: "",
                    shopDealCategoryButtonTwoLink: "",
                    shopDealSectionOneBackgroundImage: "",
                    shopDealSpecialCategoryTitle: "",
                    shopDealBannerOneImage: "",
                    shopDealBannerOneLink: "",
                    shopDealBannerTwoDesktopImage: "",
                    shopDealBannerTwoMobileImage: "",
                    // shopDealBannerTwoButtonText: "",
                    shopDealBannerTwoLink: "",
                    shopDealBannerThreeImage: "",
                    // shopDealBannerThreeButtonText: "",
                    shopDealBannerThreeLink: "",
                    shopDealFirstPurchaseBackgoundImage: "",
                    shopDealFirstPurchaseTitle: "",
                    shopDealFirstPurchaseSubTitle: "",
                    shopDealFirstPurchaseButtonText: "",
                    shopDealFirstPurchaseButtonLink: "",
                };
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {
                let fromData = new FormData();
                fromData.append("stateCode", this.stateCode);
                fromData.append("stateName", this.stateName);
                fromData.append('shopDealPageTitle', this.cmsData.shopDealPageTitle);
                fromData.append('shopDealPageSubTitle', this.cmsData.shopDealPageSubTitle);
                fromData.append('shopDealCategoryTitle', this.cmsData.shopDealCategoryTitle);
                fromData.append('shopDealCategoryButtonOneTitle', this.cmsData.shopDealCategoryButtonOneTitle);
                fromData.append('shopDealCategoryButtonOneLink', this.cmsData.shopDealCategoryButtonOneLink);
                fromData.append('shopDealCategoryButtonTwoTitle', this.cmsData.shopDealCategoryButtonTwoTitle);
                fromData.append('shopDealCategoryButtonTwoLink', this.cmsData.shopDealCategoryButtonTwoLink);
                fromData.append('shopDealSectionOneBackgroundImage', this.cmsData.shopDealSectionOneBackgroundImage);
                fromData.append('shopDealSpecialCategoryTitle', this.cmsData.shopDealSpecialCategoryTitle);
                fromData.append('shopDealBannerOneImage', this.cmsData.shopDealBannerOneImage);
                fromData.append('shopDealBannerOneLink', this.cmsData.shopDealBannerOneLink);
                fromData.append('shopDealBannerTwoDesktopImage', this.cmsData.shopDealBannerTwoDesktopImage);
                fromData.append('shopDealBannerTwoMobileImage', this.cmsData.shopDealBannerTwoMobileImage);
                // fromData.append('shopDealBannerTwoButtonText', this.cmsData.shopDealBannerTwoButtonText);
                fromData.append('shopDealBannerTwoLink', this.cmsData.shopDealBannerTwoLink);
                fromData.append('shopDealBannerThreeImage', this.cmsData.shopDealBannerThreeImage);
                // fromData.append('shopDealBannerThreeButtonText', this.cmsData.shopDealBannerThreeButtonText);
                fromData.append('shopDealBannerThreeLink', this.cmsData.shopDealBannerThreeLink);
                fromData.append('shopDealFirstPurchaseBackgoundImage', this.cmsData.shopDealFirstPurchaseBackgoundImage);
                fromData.append('shopDealFirstPurchaseTitle', this.cmsData.shopDealFirstPurchaseTitle);
                fromData.append('shopDealFirstPurchaseSubTitle', this.cmsData.shopDealFirstPurchaseSubTitle);
                fromData.append('shopDealFirstPurchaseButtonText', this.cmsData.shopDealFirstPurchaseButtonText);
                fromData.append('shopDealFirstPurchaseButtonLink', this.cmsData.shopDealFirstPurchaseButtonLink);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, fromData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$refs.shopDealSectionOneBackgroundImage.value = null;
                    this.$refs.shopDealBannerOneImage.value = null;
                    this.$refs.shopDealBannerTwoDesktopImage.value = null;
                    this.$refs.shopDealBannerTwoMobileImage.value = null;
                    this.$refs.shopDealBannerThreeImage.value = null;
                    this.$refs.shopDealFirstPurchaseBackgoundImage.value = null;
                    this.preview = {
                        shopDealSectionOneBackgroundImage: "",
                        shopDealBannerOneImage: "",
                        shopDealBannerTwoDesktopImage: "",
                        shopDealBannerTwoMobileImage: "",
                        shopDealBannerThreeImage: "",
                        shopDealFirstPurchaseBackgoundImage: "",
                    }
                    await this.getData();
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }

            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}