import { authHeader } from "../../../auth";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "AddPressRelease",
  data() {
    return {
      editor: ClassicEditor,
      setURL: this.$serverURL + this.$api.pressRelease.pressReleaseURL,
      title: "",
      description: "",
      imageFile: "",
      previewImage: null,
      pressCategory: [],
      selectedCategory: "",
      addButtonText: "Create",
      addButtonIconClass: "fa-solid fa-rotate",
      addButtonClass: "btn btn-primary",
      addButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Press Release";
    this.getPressCategory();
  },
  methods: {
    onImageChange: function (event) {
      this.imageFile = event.target.files[0];
      let input = this.$refs.imageFile;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    getPressCategory: async function () {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.pressRelease.pressReleaseCategory,
        headers: authHeader(),
      };
      await this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.pressCategory = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again!",
          });
        });
    },
    validate: function () {
      if (!this.title) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter title!",
        });
        return false;
      } else if (!this.selectedCategory) {
        this.$swal.fire({
          icon: "error",
          text: "Please select category!",
        });
        return false;
      } else if (!this.description) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter description!",
        });
        return false;
      } else if (!this.imageFile) {
        this.$swal.fire({
          icon: "error",
          text: "Please upload image!",
        });
        return false;
      }
      return true;
    },
    savePressRelease: async function () {
      if (this.validate() == true) {
        this.addButtonText = "Creating...";
        this.addButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.addButtonClass = "btn btn-secondary";
        this.addButtonFlag = true;
        const formData = new FormData();
        formData.append("title", this.title);
        formData.append("category", this.selectedCategory);
        formData.append("description", this.description);
        formData.append("image", this.imageFile);
        const config = {
          method: "POST",
          url: this.setURL,
          data: formData,
          headers: {
            ...authHeader(),
            "Content-Type": "multipart/form-data",
          },
        };
        await this.$axios(config)
          .then((response) => {
            if (response.status == 201) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }
            this.title = "";
            this.selectedCategory = "";
            this.description = "";
            this.imageFile = "";
            this.$refs.imageFile.value = null;
            this.$router.push("/press-release");
            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary";
            this.addButtonFlag = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });
            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary";
            this.addButtonFlag = false;
          });
      }
    },
  },
};
