export default {
  name: "Add Event",

  data() {
    return {
      eventDaysUrl: this.$serverURL + this.$api.cms.event.days,
      eventDaysList: [],
      selectedEventDays: [],
      title: "",
      description: "",
      buttonText: "",
      buttonLink: "",
      eventDate: "",
      address: "",
      latitude: "",
      longitude: "",
      addressButtonText: "",
      previewImage: "",
      imageFile: "",
      addButtonText: "Create",
      addButtonIconClass: "fa-solid fa-rotate",
      addButtonClass: "btn btn-primary",
      addButtonFlag: false,
    };
  },
  async created() {
    await this.getEventDays();
    document.title = "JARS - Add Event";
    var scripts = [
      "/core/assets/js/jquery.min.js",
      "/core/assets/plugins/simplebar/js/simplebar.min.js",
      "/core/assets/plugins/metismenu/js/metisMenu.min.js",
      "./core/assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js",
      "/core/assets/plugins/smart-wizard/js/jquery.smartWizard.min.js",
      "/core/assets/js/form-wizard.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  methods: {
    async getEventDays() {
      const res = await this.getDataList(this.eventDaysUrl);
      this.eventDaysList = res.map((el) => {
        return { value: el.id, label: el.title };
      });
    },
    onImageChange(event) {
      this.imageFile = event.target.files[0];
      let input = this.$refs.imageFile;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    validate: function () {
      if (!this.title) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter title!",
        });
        return false;
      }
      if (!this.imageFile) {
        this.$swal.fire({
          icon: "error",
          text: "Please upload image!",
        });
        return false;
      }
      return true;
    },
    handleAddress(locationData) {
      let location = locationData.formatted_address;
      let locationSplit = location.split(",");
      if (locationSplit[0].trim() != locationData.name) {
        location = locationData.name + ", " + location;
      }
      this.latitude = locationData.geometry.location.lat();
      this.longitude = locationData.geometry.location.lng();
      this.address = location;
    },
    save: async function () {
      if (this.validate() == true) {
        this.addButtonText = "Creating...";
        this.addButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.addButtonClass = "btn btn-secondary";
        this.addButtonFlag = true;
        let token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("title", this.title);
        formData.append("description", this.description);
        formData.append("buttonText", this.buttonText);
        formData.append("buttonLink", this.buttonLink);
        formData.append("eventDate", this.eventDate);
        formData.append("image", this.imageFile);
        formData.append("address", this.address);
        formData.append("latitude", this.latitude);
        formData.append("longitude", this.longitude);
        formData.append("addressButtonText", this.addressButtonText);
        formData.append("days", JSON.stringify(this.selectedEventDays));
        let config = {
          method: "POST",
          url: this.$serverURL + this.$api.cms.event.eventUrl,
          data: formData,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        await this.$axios(config)
          .then((response) => {
            if (response.status == 201) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }
            this.$router.push("/events");
            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary";
            this.addButtonFlag = false;
          })
          .catch((error) => {
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });
            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary";
            this.addButtonFlag = false;
          });
      }
    },
  },
};
