<template>
	<div class="page-wrapper">
		<loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Home Page Sub Nav</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								Home Page Sub Nav
							</li>
						</ol>
					</nav>
				</div>
				<div class="ms-auto">
					<div class="btn-group">
						<button type="button" class="btn btn-primary" @click="$router.push('/home-page-sub-nav')">
							<i class="bx bx-coin-stack"></i> Home Page Sub Nav
						</button>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-xl-12 mx-auto">
							<h6 class="mt-2 mb-2 text-uppercase">Edit Home Page Sub Nav</h6>
							<hr>
							<div class="form-body mt-4">
								<div class="row mb-3">
									<div class="col-lg-8">
										<div class="border border-3 p-1 rounded">
											<div class="card-body p-5">
												<div class="card-title d-flex align-items-center">
													<div><i class="bx bxs-user me-1 font-22 text-primary"></i></div>
													<h5 class="mb-0 text-primary">Edit Home Page Sub Nav Information
													</h5>
												</div>
												<hr>
												<form class="row g-3">
													<div class="col-md-12">
														<label for="postTitle" class="form-label">Title:</label>
														<input type="text" v-model="singleContent.title"
															class="form-control">
													</div>
													<div class="col-12">
														<label for="shortDetails" class="form-label">Section ID /
															Link:</label>
														<textarea class="form-control"
															v-model="singleContent.link"></textarea>
													</div>
													<div class="col-md-12">
														<label class="form-label">Upload Image One: [Image Size: 30X30,
															Max Limit: 1MB]</label>
														<input class="form-control" type="file" ref="imageFileOne"
															@change="onOneImageChange" />
													</div>
													<div class="mb-3">
														<div class="col-md-12">
															<img v-if="previewImageOne" class="img-thumbnail"
																:src="previewImageOne" width="30">
															<img v-else-if="singleContent.imageOne"
																class="img-thumbnail" :src="singleContent.imageOne"
																width="30">
															<img v-else class="img-thumbnail"
																src="../../../assets/images/default.png" height="30"
																width="30">
														</div>
													</div>
													<div class="col-md-12">
														<label class="form-label">Upload Image Two: [Image Size: 30X30,
															Max Limit: 1MB]</label>
														<input class="form-control" type="file" ref="imageFileTwo"
															@change="onTwoImageChange" />
													</div>
													<div class="mb-3">
														<div class="col-md-12">
															<img v-if="previewImageTwo" class="img-thumbnail"
																:src="previewImageTwo" width="30">
															<img v-else-if="singleContent.imageTwo"
																class="img-thumbnail" :src="singleContent.imageTwo"
																width="30">
															<img v-else class="img-thumbnail"
																src="../../../assets/images/default.png" height="30"
																width="30">
														</div>
													</div>
													<div class="d-grid mt-5">
														<button v-on:click="updateNav" type="button"
															:class="updateButtonClass"
															:disabled="updateButtonFlag == true"><i
																:class='updateButtonIconClass'></i>{{ updateButtonText
															}}</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="../js/edit-home-page-sub-nav.js"></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.margin-auto {
	margin-left: 480px;
}
</style>