import { authHeader } from '../../../auth';
import draggable from 'vuedraggable';

export default {
    name: "Category",
    components: {
        draggable
    },
    data() {
        return {
            stateCode: this.$route.query.stateCode,
            stateName: this.$route.query.stateName,
            dataList: [],
            setURL: this.$serverURL + this.$api.category.requestURL,
            singleData: {},
            previewIcon: "",
            updateIcon: "",
            previewImage: "",
            updateImage: "",
            dragging: false,
            tempDataList: [],
            searchKey: "",
            categoryList: [],
        }
    },
    async created() {
        document.title = "JARS - State Wise Category";
        await this.getData();
        await this.createCategory();
        await this.getStateWiseData();
        await this.updatePosition();
        this.loadCss();
    },
    computed: {
        isDefault() {
            let newArr = this.categoryList.filter((el) => (el.isDefault == true));
            return (newArr.length == this.categoryList.length) ? true : false;
        },
    },
    methods: {
        loadCss() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
        async getData() {
            const response = await this.getDataList(this.setURL);
            this.dataList = response;
            // this.tempDataList = response.filter((d) => d.categoryName != 'CBD' && d.categoryName != 'SEEDS');
            this.tempDataList = response.filter((d) => d.categoryName != 'SEEDS');
        },
        async getStateWiseData() {
            const response = await this.getDataList(this.$serverURL + this.$api.category.stateWiseCategoryUrl + '?stateCode=' + this.stateCode);
            // this.categoryList = response.filter((d) => d.categoryName != 'CBD' && d.categoryName != 'SEEDS');
            this.categoryList = response.filter((d) => d.categoryName != 'SEEDS');
        },
        async handleSetDefault() {
            let token = localStorage.getItem('token');
            let data = {
                stateCode: this.stateCode
            }
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.category.setDefaultUrl,
                data: data,
                headers: {
                    "Authorization": "Bearer " + token,
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getStateWiseData();
            }).catch(error => {
                console.log(error);
            });
        },
        editImage(data) {
            this.singleData = { ...data };
        },
        createCategory: async function () {
            let arrList = [];
            this.tempDataList.map(el => {
                arrList.push({
                    stateCode: this.stateCode,
                    stateName: this.stateName,
                    categoryId: el.id,
                    categoryName: el.categoryName
                })
            })
            let token = localStorage.getItem('token');
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.category.stateWiseCategoryUrl,
                data: {
                    categories: arrList
                },
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 201) {
                    return;
                }
            }).catch(error => {
                console.log(error);
                return;
            });
        },
        searchCategory() {
            // const newData = this.dataList.filter((d) => d.categoryName != 'CBD' && d.categoryName != 'SEEDS');
            const newData = this.dataList.filter((d) => d.categoryName != 'SEEDS');
            let newArr = [...newData];
            let searchVal = this.searchKey.toUpperCase();
            let filteredArr = [];
            newArr.forEach((el) => {
                let categoryName = el?.categoryName?.toUpperCase();
                if (categoryName.includes(searchVal)) {
                    filteredArr.push(el);
                }
            });
            this.tempDataList = filteredArr;
        },
        async updatePosition() {
            try {
                const newOrderList = this.categoryList.map((el, index) => {
                    return { id: el.id, position: index + 1 }
                })
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.category.stateWiseCategoryPositionUrl,
                    headers: authHeader(),
                    data: {
                        newOrderList
                    },
                }
                await this.postData(config).then(async (response) => {
                    if (response.statusCode == 200) {
                        await this.getStateWiseData();
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        async onDragUpdate(event) {
            event
            await this.updatePosition();
        },
        async handleUpdateHideShow(data) {
            let token = localStorage.getItem('token');
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.category.stateWiseCategoryUrl + '/' + data.id,
                data: {
                    isShown: data.isShown,
                },
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    await this.getStateWiseData();
                }
            }).catch(error => {
                console.log(error);
                return;
            });
        },
        onImageChange(event) {
            this.updateImage = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        update: async function () {
            const formData = new FormData();
            const token = localStorage.getItem('token');
            formData.append("featuredImage", this.updateImage ? this.updateImage : this.singleData.featuredImage);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.category.stateWiseCategoryImageUrl + '/' + this.singleData.id,
                data: formData,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                }
            };
            this.$swal.showLoading();
            await this.$axios(config).then(async (response) => {
                this.$swal.close();
                if (response.data.statusCode == 200) {
                    this.singleData = {};
                    this.$refs.image.value = "";
                    this.updateImage = "";
                    this.previewImage = "";
                    await this.getStateWiseData();
                    document.getElementById("close-btn").click();
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    },
    watch: {
        searchKey: function () {
            this.searchCategory();
        }
    }
}