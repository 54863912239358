import { authHeader } from "../../../auth";

export default {
    name: "ChangePassword",
    data() {
        return {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            isEdit: false,
        };
    },
    created(){
        document.title = "JARS - Change Password";
    },
    methods: {
        updatePassword: async function () {
            if (this.newPassword == "") {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your password!"
                });
            } 
            else if (this.confirmPassword == "") {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your confirm password!"
                });
            } 
            else if (this.newPassword != this.confirmPassword) {
                this.$swal.fire({
                    icon: "error",
                    text: "New password & confirm password does not match!"
                });
            } 
            else {
                if (this.newPassword.length < 6) {
                    this.$swal.fire({
                        icon: "error",
                        text: "You have to enter at least 6 digit!"
                    });
                } else {
                    let data = {
                        currentPassword: this.oldPassword,
                        newPassword: this.newPassword
                    };
                    let config = {
                        method: "POST",
                        url: this.$serverURL + this.$api.auth.changePasswordURL,
                        data: data,
                        headers: authHeader()
                    };
                    await this.$axios(config).then((response) => {
                        if (response.status == 200) {
                            this.$swal.fire({
                                icon: "success",
                                text: "Successfully changed your password!"
                            });
                            this.$router.push("/home");
                        }
                    }).catch((error) => {
                        let message = 'Failed to update!';
                        if(error.response.data.statusCode == 404) {
                            message = 'Old password does not match';
                        }
                        this.$swal.fire({
                            icon: "error",
                            text: message,
                        });
                    });
                    this.isEdit = false;
                }
            }
        }
    }
};