export default {
    data() {
        return {
            cmsData: {
                enableCartCounter: undefined,
                minute: "",
                second: "",
            },
            url: this.$serverURL + this.$api.cms.cartCounter,
        }
    },
    async created() {
        document.title = "JARS - Cart Counter Cms";
        await this.getData();
        await this.loadJS();
    },
    methods: {
        async loadJS() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js",
                "/core/assets/plugins/metismenu/js/metisMenu.min.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },

        async handleUpdate() {
            try {
                const data = {
                    enableCartCounter: this.cmsData.enableCartCounter,
                    minute: this.cmsData.minute,
                    second: this.cmsData.second,
                    counterTimeInSeconds: ((parseInt(this.cmsData.minute) * 60) + parseInt(this.cmsData.second)),
                }
                const token = localStorage.getItem('token');
                let config = {
                    method: "POST",
                    url: this.url,
                    data: data,
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    }
                };
                this.$swal.showLoading();
                await this.$axios(config).then(async (response) => {
                    this.$swal.close();
                    if (response.data.statusCode == 200) {
                        await this.getData();
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                console.log(error)
            }
        }
    }
}