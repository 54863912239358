<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Section Four</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Section Four CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body" style="display: flex; align-items: center;">
          <h5 style="margin: 0;">{{ stateName }}</h5>
          <div style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
            @click="$router.push({ path: '/section-four-states' })">
            <span style="margin-right: 5px;">
              <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
            </span>
            <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Section Four CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-6">
                <div class="border border-3 p-4 rounded h-100">
                  <div class="mb-3">
                    <label class="form-label">Title:</label>
                    <input type="text" v-model="cmsData.title" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Sub Title:</label>
                    <input type="text" v-model="cmsData.subTitle" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Slider Button Text:</label>
                    <input type="text" v-model="cmsData.sliderButtonText" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Button Text:</label>
                    <input type="text" v-model="cmsData.buttonText" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Button Link:</label>
                    <input type="text" v-model="cmsData.buttonLink" class="form-control" />
                  </div>
                  <div class="form-check form-switch mb-3">
                    <label class="form-label">[If there is no Cms for the state, then this deta will show as default for
                      the state.]</label>
                    <input class="form-check-input" type="checkbox" v-model="cmsData.isDefault"
                      id="flexSwitchCheckDefault" />
                  </div>
                  <div class="d-grid">
                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="border border-3 p-4 rounded h-100">
                  <div class="d-flex justify-content-center">
                    <img style="width: 100%;" src="../../../../../../public/core/assets/images/section-four.png" />
                  </div>
                  <h6 class="text-center pt-4">
                    <b>Section Four</b>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-between align-items-center">
            <h5 class="card-title mb-0 pb-0">Section Four Slider</h5>
            <div class="ms-auto">
              <div class="btn-group">
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                  <i class="bx bxs-plus-square"></i>Add New Data
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div class="ms-auto">
              <!-- Add Modal -->
              <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                <form @submit.prevent="addSlider">
                  <div class="modal-dialog modal-md">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="addModalLabel">Add Slider</h5>
                        <button type="button" id="closeButton" class="btn-close" data-bs-dismiss="modal"
                          aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Title:</label>
                        <input type="text" v-model="title" class="form-control" />
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Type:</label>
                        <select v-model="type" class="form-select">
                          <option value>Select an option</option>
                          <option value="Price">Price</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Button Text:</label>
                        <input type="text" v-model="buttonText" class="form-control" />
                      </div>
                      <div class="modal-body">
                        <label class="form-label">
                          Button Link:
                          <strong>[If you choose the type "Price" and leave the button link blank, it will work
                            automatically]</strong>
                        </label>
                        <input type="text" v-model="buttonLink" class="form-control" />
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Button Two Text:</label>
                        <input type="text" v-model="buttonTwoText" class="form-control" />
                      </div>
                      <div class="modal-body">
                        <label class="form-label">
                          Button Two Link:
                          <strong>[If you choose the type "Price" and leave the button link blank, it will work
                            automatically]</strong>
                        </label>
                        <input type="text" v-model="buttonTwoLink" class="form-control" />
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" :class="addButtonClass" :disabled="addButtonFlag == true"><i
                            :class='addButtonIconClass'></i>{{ addButtonText }}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!-- Add Modal -->
              <!-- Edit Modal -->
              <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                <form @submit.prevent="updateSlider">
                  <div class="modal-dialog modal-md">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="addModalLabel">Update Slider</h5>
                        <button type="button" id="buttonClose" class="btn-close" data-bs-dismiss="modal"
                          aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Title:</label>
                        <input type="text" v-model="singleSlider.title" class="form-control" />
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Type:</label>
                        <select v-model="singleSlider.type" class="form-select">
                          <option value>Select an option</option>
                          <option value="Price">Price</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Button Text:</label>
                        <input type="text" v-model="singleSlider.buttonText" class="form-control" />
                      </div>
                      <div class="modal-body">
                        <label class="form-label">
                          Button Link:
                          <strong>[If you choose the type "Price" and leave the button link blank, it will work
                            automatically]</strong>
                        </label>
                        <input type="text" v-model="singleSlider.buttonLink" class="form-control" />
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Button Two Text:</label>
                        <input type="text" v-model="singleSlider.buttonTwoText" class="form-control" />
                      </div>
                      <div class="modal-body">
                        <label class="form-label">
                          Button Two Link:
                          <strong>[If you choose the type "Price" and leave the button link blank, it will work
                            automatically]</strong>
                        </label>
                        <input type="text" v-model="singleSlider.buttonTwoLink" class="form-control" />
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" :class="updateButtonClass" :disabled="updateButtonFlag == true"><i
                            :class='updateButtonIconClass'></i>{{ updateButtonText }}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!-- Edit Modal -->
            </div>
          </div>
          <div class="form-body mt-4">
            <div class="row">
              <div class="table-responsive">
                <table id="example" class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Button Text</th>
                      <th>Button Link</th>
                      <th>Button Two Text</th>
                      <th>Button Two Link</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in dataList" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ data.title }}</td>
                      <td>{{ data.type }}</td>
                      <td>{{ data.buttonText }}</td>
                      <td>{{ data.buttonLink }}</td>
                      <td>{{ data.buttonTwoText }}</td>
                      <td>{{ data.buttonTwoLink }}</td>
                      <td>
                        <div class="d-flex order-actions">
                          <a href="javascript:void(0);" v-on:click="editSlider(data)" data-bs-toggle="modal"
                            data-bs-target="#editModal" class="edit-button">
                            <i class="bx bxs-edit text-white"></i>
                          </a>
                          <a href="javascript:void(0);" v-on:click="deleteSlider(data.id)" class="ms-3 delete-button">
                            <i class="bx bxs-trash text-white"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>SL</th>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Button Text</th>
                      <th>Button Link</th>
                      <th>Button Two Text</th>
                      <th>Button Two Link</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/section-four-cms.js"></script>


<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>