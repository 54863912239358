import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.landing.landingTwoSectionSixCmsUrl,
            cmsData: {
                title: "",
                description: "",
            },
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                },
                mediaEmbed: {
                    previewsInData: true
                },
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    ],
                },
            },
            preview: {
                imageOne: "",
            }
        }
    },
    async created() {
        document.title = "Jars - Landing Two Section Six Page CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async updateCms() {
            try {
                let data = {
                    'title': this.cmsData.title,
                    'description': this.cmsData.description,
                };

                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}