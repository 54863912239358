// import { authHeader } from '../../../../../../auth';
export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.landing.landingTwoSettingsUrl,
            cmsData: {
                sectionOnestatus: true,
                sectionTwostatus: true,
                sectionThreestatus: true,
                sectionFourstatus: true,
                sectionFivestatus: true,
                sectionSixstatus: true,
                sectionSevenstatus: true,
                sectionEightstatus: true,
                sectionNinestatus: true,
                sectionTenstatus: true,
            },
        }
    },
    async created() {
        document.title = "JARS - Landing Two Settings";
        await this.getData();
        await this.loadCss();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                const data = (response.data.length > 0) ? response.data[0] : {}
                this.cmsData = Object.keys(data).length > 0 ? {
                    sectionOnestatus: data?.sectionOnestatus,
                    sectionTwostatus: data?.sectionTwostatus,
                    sectionThreestatus: data?.sectionThreestatus,
                    sectionFourstatus: data?.sectionFourstatus,
                    sectionFivestatus: data?.sectionFivestatus,
                    sectionSixstatus: data?.sectionSixstatus,
                    sectionSevenstatus: data?.sectionSevenstatus,
                    sectionEightstatus: data?.sectionEightstatus,
                    sectionNinestatus: data?.sectionNinestatus,
                    sectionTenstatus: data?.sectionTenstatus,
                } : {
                    sectionOnestatus: false,
                    sectionTwostatus: false,
                    sectionThreestatus: false,
                    sectionFourstatus: false,
                    sectionFivestatus: false,
                    sectionSixstatus: false,
                    sectionSevenstatus: false,
                    sectionEightstatus: false,
                    sectionNinestatus: false,
                    sectionTenstatus: false,
                };
            }
            else {
                this.cmsData = {
                    sectionOnestatus: false,
                    sectionTwostatus: false,
                    sectionThreestatus: false,
                    sectionFourstatus: false,
                    sectionFivestatus: false,
                    sectionSixstatus: false,
                    sectionSevenstatus: false,
                    sectionEightstatus: false,
                    sectionNinestatus: false,
                    sectionTenstatus: false,
                };
            }
        },
        async update() {
            try {
                let data = {
                    ...this.cmsData,
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        loadCss() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
    }
}