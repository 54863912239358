import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { authHeader } from "../../../auth";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "Retailer",
  components: {
    Editor,
  },
  data() {
    return {
      dataList: [],
      setURL: this.$serverURL + this.$api.retailer.requestURL,
      singleData: {
        id: "",
        retailerName: "",
        retailerMobileNumber: "",
        email: "",
        about: "",
        todaysSpecial: "",
        jarsRewards: "",
        referAFriend: "",
        pageTitle: "",
        featuredImage: "",
        retailerImage: [],
        canonical: "",
        schema: "",

        cannabisProductTitile: "",
        storeFeatureTitle: "",
        faqTitle: "",
        cannabisProductDescription: "",

        storeFeatureContentOneTitle: "",
        storeFeatureContentOneSubTitle: "",
        storeFeatureContentTwoTitle: "",
        storeFeatureContentTwoSubTitle: "",
        storeFeatureContentThreeTitle: "",
        storeFeatureContentThreeSubTitle: "",

        storeFeatureContentFeaturedImageOne: "",
        storeFeatureContentFeaturedImageTwo: "",
        storeFeatureContentFeaturedImageThree: "",
        headerText: "",
        paragraphText: "",
        anchorTag: [{ anchorText: "", anchorLink: "" }],
      },
      previewImage: "",
      storeFeatureContentPreviewImageOne: "",
      storeFeatureContentPreviewImageTwo: "",
      storeFeatureContentPreviewImageThree: "",
      featuredImage: null,
      updateImage: "",
      storeImageList: [],
      deletedImage: [],
      previewStoreImages: [],
      disableButton: false,
      editor: ClassicEditor,
      editorConfig: {
        ckfinder: {
          uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
        },
      },
      tempSingleData: {},
    };
  },
  async created() {
    document.title = "Jars - Retailer";
    this.dataList = await this.getDataList(this.setURL);
    var scripts = [
      "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
      "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
      "/core/assets/js/table-datatable.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href =
      "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
    document.head.appendChild(style);
  },
  methods: {
    addCitySection() {
      // Check if anchorTag is defined and is an array, if not initialize it
      if (!Array.isArray(this.singleData.anchorTag)) {
        this.singleData.anchorTag = [];
      }
      // Push a new empty object to the array
      this.singleData.anchorTag.push({ anchorText: "", anchorLink: "" });
    },
    removeCitySection(index) {
      // Remove the section at the specified index
      this.singleData.anchorTag.splice(index, 1);
    },
    checkNullString(data) {
      return data != "null" ? true : false;
    },
    async updateDataToSingleData() {
      this.previewImage = "";
      this.storeFeatureContentPreviewImageOne = "";
      this.storeFeatureContentPreviewImageTwo = "";
      this.storeFeatureContentPreviewImageThree = "";
      this.updateImage = "";
      this.$refs.image.value = "";
      this.$refs.storeFeatureContentFeaturedImageOne.value = "";
      this.$refs.storeFeatureContentFeaturedImageTwo.value = "";
      this.$refs.storeFeatureContentFeaturedImageThree.value = "";
      this.$refs.storeImages.value = null;
      this.previewStoreImages = [];
      this.storeImageList = [];
      this.deletedImage = [];
      this.singleData = {
        id: this.tempSingleData.id,
        retailerName:
          this.tempSingleData.retailerName &&
          this.checkNullString(this.tempSingleData.retailerName)
            ? this.tempSingleData.retailerName
            : "",
        retailerMobileNumber:
          this.tempSingleData.retailerMobileNumber &&
          this.checkNullString(this.tempSingleData.retailerMobileNumber)
            ? this.tempSingleData.retailerMobileNumber
            : "",
        email:
          this.tempSingleData.email &&
          this.checkNullString(this.tempSingleData.email)
            ? this.tempSingleData.email
            : "",
        about:
          this.tempSingleData.about &&
          this.checkNullString(this.tempSingleData.about)
            ? this.tempSingleData.about
            : "",
        todaysSpecial:
          this.tempSingleData.todaysSpecial &&
          this.checkNullString(this.tempSingleData.todaysSpecial)
            ? this.tempSingleData.todaysSpecial
            : "",
        jarsRewards:
          this.tempSingleData.jarsRewards &&
          this.checkNullString(this.tempSingleData.jarsRewards)
            ? this.tempSingleData.jarsRewards
            : "",
        referAFriend:
          this.tempSingleData.referAFriend &&
          this.checkNullString(this.tempSingleData.referAFriend)
            ? this.tempSingleData.referAFriend
            : "",
        pageTitle:
          this.tempSingleData.pageTitle &&
          this.checkNullString(this.tempSingleData.pageTitle)
            ? this.tempSingleData.pageTitle
            : "",
        schema:
          this.tempSingleData.schema &&
          this.checkNullString(this.tempSingleData.schema)
            ? this.tempSingleData.schema
            : "",
        canonical:
          this.tempSingleData.canonical &&
          this.checkNullString(this.tempSingleData.canonical)
            ? this.tempSingleData.canonical
            : "",
        featuredImage: this.tempSingleData.featuredImage
          ? this.tempSingleData.featuredImage
          : "",
        retailerImage:
          this.tempSingleData.retailerImage.length > 0
            ? this.tempSingleData.retailerImage
            : [],

        cannabisProductTitile:
          this.tempSingleData.cannabisProductTitile &&
          this.checkNullString(this.tempSingleData.cannabisProductTitile)
            ? this.tempSingleData.cannabisProductTitile
            : "",
        storeFeatureTitle:
          this.tempSingleData.storeFeatureTitle &&
          this.checkNullString(this.tempSingleData.storeFeatureTitle)
            ? this.tempSingleData.storeFeatureTitle
            : "",
        faqTitle:
          this.tempSingleData.faqTitle &&
          this.checkNullString(this.tempSingleData.faqTitle)
            ? this.tempSingleData.faqTitle
            : "",
        cannabisProductDescription:
          this.tempSingleData.cannabisProductDescription &&
          this.checkNullString(this.tempSingleData.cannabisProductDescription)
            ? this.tempSingleData.cannabisProductDescription
            : "",

        storeFeatureContentOneTitle:
          this.tempSingleData.storeFeatureContentOneTitle &&
          this.checkNullString(this.tempSingleData.storeFeatureContentOneTitle)
            ? this.tempSingleData.storeFeatureContentOneTitle
            : "",
        storeFeatureContentOneSubTitle:
          this.tempSingleData.storeFeatureContentOneSubTitle &&
          this.checkNullString(
            this.tempSingleData.storeFeatureContentOneSubTitle
          )
            ? this.tempSingleData.storeFeatureContentOneSubTitle
            : "",
        storeFeatureContentTwoTitle:
          this.tempSingleData.storeFeatureContentTwoTitle &&
          this.checkNullString(this.tempSingleData.storeFeatureContentTwoTitle)
            ? this.tempSingleData.storeFeatureContentTwoTitle
            : "",
        storeFeatureContentTwoSubTitle:
          this.tempSingleData.storeFeatureContentTwoSubTitle &&
          this.checkNullString(
            this.tempSingleData.storeFeatureContentTwoSubTitle
          )
            ? this.tempSingleData.storeFeatureContentTwoSubTitle
            : "",
        storeFeatureContentThreeTitle:
          this.tempSingleData.storeFeatureContentThreeTitle &&
          this.checkNullString(
            this.tempSingleData.storeFeatureContentThreeTitle
          )
            ? this.tempSingleData.storeFeatureContentThreeTitle
            : "",
        storeFeatureContentThreeSubTitle:
          this.tempSingleData.storeFeatureContentThreeSubTitle &&
          this.checkNullString(
            this.tempSingleData.storeFeatureContentThreeSubTitle
          )
            ? this.tempSingleData.storeFeatureContentThreeSubTitle
            : "",

        storeFeatureContentFeaturedImageOne: this.tempSingleData
          .storeFeatureContentFeaturedImageOne
          ? this.tempSingleData.storeFeatureContentFeaturedImageOne
          : "",
        storeFeatureContentFeaturedImageTwo: this.tempSingleData
          .storeFeatureContentFeaturedImageTwo
          ? this.tempSingleData.storeFeatureContentFeaturedImageTwo
          : "",
        storeFeatureContentFeaturedImageThree: this.tempSingleData
          .storeFeatureContentFeaturedImageThree
          ? this.tempSingleData.storeFeatureContentFeaturedImageThree
          : "",
        headerText: this.tempSingleData.headerText
          ? this.tempSingleData.headerText
          : "",
        paragraphText: this.tempSingleData.paragraphText
          ? this.tempSingleData.paragraphText
          : "",
        anchorTag:
          JSON.parse(this?.tempSingleData?.anchorTag)?.length > 0
            ? JSON.parse(this?.tempSingleData?.anchorTag)
            : [{ anchorText: "", anchorLink: "" }],
      };

      if (this.singleData.retailerImage.length > 0) {
        this.storeImageList = this.singleData.retailerImage.map((el) => {
          return el.image;
        });
        this.previewStoreImages = this.singleData.retailerImage.map((el) => {
          return el.image;
        });
      }
      // this.previewStoreImages = this.singleData.retailerImage.length > 0 ? this.singleData.retailerImage : [];
      // this.storeImageList = this.singleData.retailerImage.length > 0 ? this.singleData.retailerImage : [];
      if (this.previewStoreImages.length > 3) {
        this.disableButton = true;
      } else {
        this.disableButton = false;
      }
    },
    editData(data) {
      this.tempSingleData = data;
    },
    onImageChange(event) {
      this.updateImage = event.target.files[0];
      let input = this.$refs.image;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    storeFeatureContentFeaturedImageOneOnChange(event) {
      this.singleData.storeFeatureContentFeaturedImageOne =
        event.target.files[0];
      let input = this.$refs.storeFeatureContentFeaturedImageOne;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.storeFeatureContentPreviewImageOne = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    storeFeatureContentFeaturedImageTwoOnChange(event) {
      this.singleData.storeFeatureContentFeaturedImageTwo =
        event.target.files[0];
      let input = this.$refs.storeFeatureContentFeaturedImageTwo;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.storeFeatureContentPreviewImageTwo = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    storeFeatureContentFeaturedImageThreeOnChange(event) {
      this.singleData.storeFeatureContentFeaturedImageThree =
        event.target.files[0];
      let input = this.$refs.storeFeatureContentFeaturedImageThree;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.storeFeatureContentPreviewImageThree = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    update: async function () {
      const formData = new FormData();
      formData.append(
        "featuredImage",
        this.updateImage && this.updateImage != null
          ? this.updateImage
          : this.singleData.featuredImage
      );
      formData.append(
        "retailerMobileNumber",
        this.singleData.retailerMobileNumber
      );
      formData.append("email", this.singleData.email);
      formData.append("about", this.singleData.about);
      formData.append("jarsRewards", this.singleData.jarsRewards);
      formData.append("referAFriend", this.singleData.referAFriend);
      formData.append("pageTitle", this.singleData.pageTitle);
      formData.append("schema", this.singleData.schema);
      formData.append("canonical", this.singleData.canonical);
      formData.append(
        "todaysSpecial",
        this.singleData.todaysSpecial
          ? this.singleData.todaysSpecial
          : "TODAY’S SPECIALS"
      );

      formData.append(
        "cannabisProductTitile",
        this.singleData.cannabisProductTitile
      );
      formData.append("storeFeatureTitle", this.singleData.storeFeatureTitle);
      formData.append("faqTitle", this.singleData.faqTitle);
      formData.append(
        "cannabisProductDescription",
        this.singleData.cannabisProductDescription
      );
      formData.append(
        "storeFeatureContentOneTitle",
        this.singleData.storeFeatureContentOneTitle
      );
      formData.append(
        "storeFeatureContentOneSubTitle",
        this.singleData.storeFeatureContentOneSubTitle
      );
      formData.append(
        "storeFeatureContentTwoTitle",
        this.singleData.storeFeatureContentTwoTitle
      );
      formData.append(
        "storeFeatureContentTwoSubTitle",
        this.singleData.storeFeatureContentTwoSubTitle
      );
      formData.append(
        "storeFeatureContentThreeTitle",
        this.singleData.storeFeatureContentThreeTitle
      );
      formData.append(
        "storeFeatureContentThreeSubTitle",
        this.singleData.storeFeatureContentThreeSubTitle
      );
      formData.append(
        "storeFeatureContentFeaturedImageOne",
        this.singleData.storeFeatureContentFeaturedImageOne
      );
      formData.append(
        "storeFeatureContentFeaturedImageTwo",
        this.singleData.storeFeatureContentFeaturedImageTwo
      );
      formData.append(
        "storeFeatureContentFeaturedImageThree",
        this.singleData.storeFeatureContentFeaturedImageThree
      );
      formData.append("headerText", this.singleData.headerText);
      formData.append("paragraphText", this.singleData.paragraphText);
      formData.append("anchorTag", JSON.stringify(this.singleData.anchorTag));

      this.storeImageList.map((el) => {
        if (typeof el !== "string") {
          formData.append("retailerImage", el);
        }
      });

      this.deletedImage.map((el) => {
        formData.append("deletedImage", el);
      });
      let config = {
        method: "PATCH",
        url:
          this.$serverURL +
          this.$api.retailer.requestURL +
          "/" +
          this.singleData.id,
        data: formData,
        headers: authHeader(),
      };
      this.$swal.showLoading();
      await this.$axios(config)
        .then(async (response) => {
          this.$swal.close();
          if (response.data.statusCode == 200) {
            setTimeout(async () => {
              this.dataList = await this.getDataList(this.setURL);
            }, 1500);
            document.getElementById("close-btn").click();
            this.$swal.fire({
              toast: true,
              position: "top-end",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              animation: false,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            text: error.response.data.message,
          });
        });
    },
    uploadStoreImages() {
      this.storeImageList.push(this.$refs.storeImages.files[0]);
      if (this.storeImageList.length > 3) {
        this.disableButton = true;
      }
      let input = this.$refs.storeImages;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewStoreImages.push(e.target.result);
        };
        reader.readAsDataURL(file[0]);
      }
      this.$refs.storeImages.value = null;
    },
    removeUploadedStoreImage(index) {
      this.previewStoreImages.splice(index, 1);
      if (typeof this.storeImageList[index] === "string") {
        if (this.storeImageList[index].startsWith("https")) {
          this.deletedImage.push(this.storeImageList[index]);
        }
      }
      this.storeImageList.splice(index, 1);
      if (this.storeImageList.length > 3) {
        this.disableButton = true;
      } else {
        this.disableButton = false;
      }
    },
  },
  watch: {
    tempSingleData: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal?.retailerId != oldVal?.retailerId) {
          this.updateDataToSingleData();
        }
      },
    },
  },
};
