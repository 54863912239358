<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Events</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Events</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              @click="$router.push('/events')"
            >
              <i class="bx bx-coin-stack"></i>Events
            </button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-12 mx-auto">
              <h6 class="mt-2 mb-2 text-uppercase">Create New Event</h6>
              <hr />
              <div class="form-body mt-4">
                <div class="row mb-3">
                  <div class="col-lg-8">
                    <div class="border border-3 p-1 rounded">
                      <div class="card-body p-5">
                        <div class="card-title d-flex align-items-center">
                          <div>
                            <i class="bx bxs-user me-1 font-22 text-primary"></i>
                          </div>
                          <h5 class="mb-0 text-primary">Enter Event Information</h5>
                        </div>
                        <hr />
                        <form class="row g-3">
                          <div class="col-md-12">
                            <label for="postTitle" class="form-label">Title:</label>
                            <input type="text" v-model="title" class="form-control" />
                          </div>
                          <div class="col-12">
                            <label class="form-label">Description:</label>
                            <textarea class="form-control" v-model="description"></textarea>
                          </div>
                          <div class="col-12">
                            <label class="form-label">Event Date:</label>
                            <input v-model="eventDate" type="date" class="form-control" />
                          </div>
                          <div class="col-6">
                            <label class="form-label">Address:</label>
                            <GMapAutocomplete
                              @place_changed="handleAddress"
                              :value="address"
                              :class="'form-control'"
                              v-on:input="address = $event.target.value"
                            ></GMapAutocomplete>
                          </div>
                          <div class="col-md-6">
                            <label class="form-label">Address Button Text:</label>
                            <input type="text" v-model="addressButtonText" class="form-control" />
                          </div>
                          <div class="col-md-6">
                            <label class="form-label">Button Text:</label>
                            <input type="text" v-model="buttonText" class="form-control" />
                          </div>
                          <div class="col-md-6">
                            <label class="form-label">Button Link:</label>
                            <input type="text" v-model="buttonLink" class="form-control" />
                          </div>
                          <div class="col-md-12">
                            <label class="form-label">Event Days:</label>
                            <Multiselect
                              v-model="selectedEventDays"
                              openDirection="top"
                              :options="eventDaysList"
                              mode="tags"
                              :close-on-select="false"
                              :searchable="true"
                              :create-option="true"
                            />
                          </div>
                          <div class="col-md-12">
                            <label
                              class="form-label"
                            >Upload Image: [Image Size: 387X388, Max Limit: 1MB]</label>
                            <input
                              class="form-control"
                              type="file"
                              ref="imageFile"
                              @change="onImageChange"
                            />
                          </div>
                          <div class="mb-3">
                            <div class="col-md-12">
                              <img
                                v-if="previewImage"
                                class="img-thumbnail"
                                :src="previewImage"
                                width="400"
                              />
                              <img
                                v-else
                                class="img-thumbnail"
                                src="../../../../assets/images/default.png"
                                height="100"
                                width="150"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-8">
                    <div class="d-grid mt-5">
                      <button v-on:click="save" type="button" :class="addButtonClass" :disabled="addButtonFlag == true"><i
                        :class='addButtonIconClass'></i>{{ addButtonText }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "/public/core/assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css";
@import "/public/core/assets/plugins/metismenu/css/metisMenu.min.css";
@import "/public/core/assets/plugins/smart-wizard/css/smart_wizard_all.min.css";
.custom-margin {
  margin-top: 40px;
}
.custom-margin-label {
  margin-top: 36px;
}
</style>

<script src="../js/add-event.js"></script>
