import { createApp } from "vue";
import App from "./App.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./routes/routes";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import mixins from "./mixin";
import VueGoogleMaps from "@fawmi/vue-google-maps";

const app = createApp(App);

app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.component("Multiselect", Multiselect);
app.component("v-select", vSelect);
app.use(CKEditor);
app.use(VueGoogleMaps, {
  load: {
    //key: 'AIzaSyCzeiU5pW1Ja8-pJiSz8b-XTx3MBWNCThA',
    key: "AIzaSyAXaert7SvJN9R-bl5zD5WS3UI5OF75I-A",
    libraries: "places",
  },
});

const server = require("../config/server.json");
const credentials = require("../config/credential.json");
app.config.globalProperties.$api = require("../config/api.json");
app.config.globalProperties.$dutchieApi = require("../config/dutchie-credential.json");
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$credentials = credentials;

app.config.globalProperties.$filters = {
  formatDateTime(date) {
    return moment(String(date)).format("DD/MM/YYYY hh:mm:ss A");
  },
  formatDate(date) {
    return moment(String(date)).format("MM/DD/YYYY");
  },
};

if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
  app.config.globalProperties.$serverURL = server.STAGING_HOST;
  app.config.globalProperties.$hostURL = server.STAGING_HOST;
  app.config.globalProperties.$imageURL = server.STAGING_HOST;
} else {
  app.config.globalProperties.$hostURL = server.HOST;
  app.config.globalProperties.$serverURL = server.SERVER_HOST;
  app.config.globalProperties.$imageURL = server.IMAGE_URL;
}

app.mixin(mixins);
app.use(router).mount("#app");
