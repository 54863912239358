<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Contact</li>
                    </ol>
                </nav>
                <!-- <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-success m-1" data-bs-toggle="modal" data-bs-target="#filterModal" id="filterTable">
                            <i class="bx bx-filter text-white float-left"></i>Filter
                        </button>
                    </div>
                </div> -->
            </div>
            <h6 class="mb-0 text-uppercase">Contact List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Contact Reason</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Message</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.contactReasonName }}</td>
                                    <td>{{ data.name }}</td>
                                    <td>{{ data.email }}</td>
                                    <td>
                                        <span class="metaModal" data-bs-toggle="modal" data-bs-target="#showModal"
                                            @click="updateModalValue(data.message)">
                                            {{ data.message ? data.message.length > 25 ?
                                                data.message.slice(0, 25) + '...' :
                                                data.message
                                                : 'No message found.'
                                            }}
                                        </span>
                                    </td>
                                    <td> {{ this.$filters.formatDate(data.createdAt) }} </td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0)" @click="deleteContent(data.id)"
                                                class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Contact Reason</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Message</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="showModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="showModalLabel">Message</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p class="form-control mb-1" type="text" v-html="message"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="filterModalLabel">Filtering</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form class="filter_form">
                            <div class="row mb-3">
                                <div class="col-12 col-sm-6">
                                    <label class="form-label">Start Date:</label>
                                    <input v-model="filter.startDate" type="date" class="form-control" />
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label class="form-label">End Date</label>
                                    <input v-model="filter.endDate" type="date" class="form-control" />
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="text-center">
                                    <button type="submit" @click="clearFilter()" class="btn btn-sm btn-warning rounded-pill ps-3 pe-3 m-1" data-bs-dismiss="modal">
                                        Clear
                                    </button>
                                    <button type="button" class="btn btn-sm btn-success rounded-pill ps-3 pe-3 m-1" data-bs-dismiss="modal" @click="validateFilter">
                                        Search
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script src='../js/contact.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}

.metaModal {
    color: black;
    cursor: pointer;
    text-decoration: none;
    display: block;
}

.metaModal:hover {
    cursor: pointer;
    color: #09f;
}</style>
