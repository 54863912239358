import { authHeader } from "../../../auth";

export default {
  data() {
    return {
      setURL: this.$serverURL + this.$api.featuredBrand.requestURL,
      link: "",
      previewImage: "",
      image: "",
      dataList: [],
      singleData: {},
      previewUpdateImage: "",
      updateImage: "",
      addButtonText: "Create",
      addButtonIconClass: "fa-solid fa-rotate",
      addButtonClass: "btn btn-primary",
      addButtonFlag: false,
      updateButtonText: "Update",
      updateButtonIconClass: "fa-solid fa-rotate",
      updateButtonClass: "btn btn-primary",
      updateButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Section Five CMS";
    // await this.getData();
    this.dataList = await this.getDataList(this.setURL);
    var scripts = [
      "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
      "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
      "/core/assets/js/table-datatable.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href =
      "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
    document.head.appendChild(style);
  },
  methods: {
    onImageChange: function (event) {
      this.image = event.target.files[0];
      let input = this.$refs.image;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    onUpdateImageChange: function (event) {
      this.updateImage = event.target.files[0];
      let input = this.$refs.updateImage;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewUpdateImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    validate: function () {
      if (!this.image) {
        this.$swal.fire({
          icon: "error",
          text: "Please upload image!",
        });
        return false;
      }
      if (!this.link) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter link!",
        });
        return false;
      }
      return true;
    },
    addEntity: async function () {
      if (this.validate() == true) {
        this.addButtonText = "Creating...";
        this.addButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.addButtonClass = "btn btn-secondary";
        this.addButtonFlag = true;
        let token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("link", this.link);
        formData.append("image", this.image);
        let config = {
          method: "POST",
          url: this.setURL,
          data: formData,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        await this.$axios(config)
          .then(async (response) => {
            if (response.status == 201) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }
            this.link = "";
            this.image = "";
            this.previewImage = "";
            this.$refs.image.value = null;
            this.dataList = await this.getDataList(this.setURL);
            document.getElementById("closeButton").click();
            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary";
            this.addButtonFlag = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });
            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary";
            this.addButtonFlag = false;
          });
      }
    },
    deleteEntity: function (id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              let config = {
                method: "DELETE",
                url: this.setURL + "/" + id,
                headers: authHeader(),
              };
              console.log("Config: ", config);
              await this.deleteData(config);
              this.dataList = await this.getDataList(this.setURL);
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                text: error.response.data.message,
              });
            }
          }
        });
    },
    editEntity: async function (data) {
      this.singleData = data;
    },
    updateValidation: function () {
      if (!this.singleData.link) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter link!",
        });
        return false;
      }
      return true;
    },
    updateEntity: async function () {
      if (this.updateValidation() == true) {
        this.updateButtonText = "Updating...";
        this.updateButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.updateButtonClass = "btn btn-secondary";
        this.updateButtonFlag = true;
        let token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("link", this.singleData.link);
        formData.append(
          "image",
          this.updateImage ? this.updateImage : this.singleData.image
        );
        let config = {
          method: "PATCH",
          url: this.setURL + "/" + this.singleData.id,
          data: formData,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        await this.$axios(config)
          .then(async (response) => {
            if (response.status == 200) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }
            this.singleData = {};
            this.updateImage = "";
            this.previewUpdateImage = "";
            this.$refs.updateImage.value = null;
            this.dataList = await this.getDataList(this.setURL);
            document.getElementById("buttonClose").click();
            this.updateButtonText = "Update";
            this.updateButtonIconClass = "fa-solid fa-rotate";
            this.updateButtonClass = "btn btn-primary";
            this.updateButtonFlag = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });
            this.updateButtonText = "Update";
            this.updateButtonIconClass = "fa-solid fa-rotate";
            this.updateButtonClass = "btn btn-primary";
            this.updateButtonFlag = false;
          });
      }
    },
  },
};
