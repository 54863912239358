<template>
    <div class="page-wrapper">
      <div class="page-content">
        <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div class="breadcrumb-title pe-3">Deals Dropdown CMS</div>
          <div class="ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item">
                  <router-link to="/home">
                    <i class="bx bx-home-alt"></i>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Deals Dropdown CMS</li>
              </ol>
            </nav>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Deals Dropdown CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12">
                  <div class="border border-3 p-4 rounded">
                    <div class="row mb-3">
                      <div class="col-sm-6">
                        <label class="form-label">Deals category Title:</label>
                        <input type="text" v-model="cmsData.dealsCategoryTitle" class="form-control" />
                      </div>
                      <div class="col-sm-6">
                        <label class="form-label">Deal Category Button Text:</label>
                        <input type="text" v-model="cmsData.dealsCategoryButtonText" class="form-control" />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-12">
                        <label class="form-label">Banner One Image:</label>
                        <div class="row">
                          <div class="col-sm-6">
                            <!-- <label
                              class="form-label"
                            >Image: [Preferred Iocn Size: 1485X700, Max Upload Limit: 1MB]</label> -->
                            <input
                              class="form-control"
                              @change="onImageChange('bannerOneImage')"
                              type="file"
                              ref="bannerOneImage"
                            />
                          </div>
                          <div class="col-sm-6">
                            <div class="col-md-3">
                              <img v-if="previewImage" :src="previewImage" style="height:100px;" />
                              <img v-else-if="cmsData.bannerOneImage" :src="cmsData.bannerOneImage" style="height:100px;" />
                              <img v-else src="../../../../assets/images/default.png" height="100" />
                            </div>
                          </div>
                          <div class="col-sm-6 mt-3">
                            <label class="form-label">Banner One Button Text</label>
                            <input type="text" v-model="cmsData.bannerOneButtonText" class="form-control" />
                          </div>
                          <div class="col-sm-6 mt-3">
                            <label class="form-label">Banner One Button Link</label>
                            <input type="text" v-model="cmsData.bannerOneButtonLink" class="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <hr />
  
                    <div class="row mt-3 mb-3">
                      <div class="col-sm-6">
                        <label class="form-label">Banner Two Title:</label>
                        <input type="text" v-model="cmsData.bannerTwoTitle" class="form-control" />
                      </div>
                    </div>
                    <div class="row">
                          <div class="col-sm-6">
                            <label class="form-label">Banner Two Image:</label>
                            <input
                              class="form-control"
                              @change="onImageChange('bannerTwoImage')"
                              type="file"
                              ref="bannerTwoImage"
                            />
                          </div>
                          <div class="col-sm-6">
                            <div class="col-md-3">
                              <img v-if="previewImage2" :src="previewImage2" style="height:100px;" />
                              <img v-else-if="cmsData.bannerTwoImage" :src="cmsData.bannerTwoImage" style="height:100px;" />
                              <img v-else src="../../../../assets/images/default.png" height="100" />
                            </div>
                          </div>
                          <div class="col-sm-6 mt-3">
                            <label class="form-label">Banner Two Button Text</label>
                            <input type="text" v-model="cmsData.bannerTwoButtonText" class="form-control" />
                          </div>
                          <div class="col-sm-6 mt-3">
                            <label class="form-label">Banner Two Button Link</label>
                            <input type="text" v-model="cmsData.bannerTwoButtonLink" class="form-control" />
                          </div>
                          <div class="col-sm-6 mb-2">
                            <label class="form-label">Deals Title</label>
                            <input type="text" v-model="cmsData.dealsTitle" class="form-control" />
                          </div>
                        </div>
                    <div class="d-grid">
                      <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script src="../js/dealsDropdown-cms.js"></script>