import { authHeader } from "../../../auth";
export default {
  name: "ContactReason",
  data() {
    return {
      setURL: this.$serverURL + this.$api.contact.contactReasonURL,
      stateCode: this.$route.query.stateCode,
      stateName: this.$route.query.stateName,
      dataList: [],
      singleData: {},
      contactReasonName: "",
      contactEmail: "",
      isShow: true,
      reasons: [
        { value: "Marketing", label: "Marketing" },
        { value: "Purchasing", label: "Purchasing" },
        { value: "Customer Service", label: "Customer Service" },
        { value: "PR & Communications", label: "PR & Communications" },
        { value: "Community Service", label: "Community Service" },
      ],
      addButtonText: "Create",
      addButtonIconClass: "fa-solid fa-rotate",
      addButtonClass: "btn btn-primary",
      addButtonFlag: false,
      updateButtonText: "Update",
      updateButtonIconClass: "fa-solid fa-rotate",
      updateButtonClass: "btn btn-primary",
      updateButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Contact Reason";
    this.dataList = await this.getDataList(
      this.setURL + "?stateCode=" + this.stateCode
    );
    var scripts = [
      "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
      "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
      "/core/assets/js/table-datatable.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href =
      "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
    document.head.appendChild(style);
  },
  computed: {
    isDefault() {
      let newArr = this.dataList.filter((el) => el.isDefault == true);
      return newArr.length == this.dataList.length ? true : false;
    },
    disabledReasons() {
      return this.dataList; // Array of values to disable
    },
  },
  methods: {
    addContactReason: async function () {
      if (!this.contactReasonName) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter contact reason!",
        });
      } else {
        this.addButtonText = "Creating...";
        this.addButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.addButtonClass = "btn btn-secondary";
        this.addButtonFlag = true;
        let data = {
          contactReasonName: this.contactReasonName,
          contactEmail: this.contactEmail,
          stateCode: this.stateCode,
          stateName: this.stateName,
        };
        let config = {
          method: "POST",
          url: this.setURL,
          data: data,
          headers: authHeader(),
        };
        await this.postData(config);
        this.contactReasonName = "";
        this.contactEmail = "";
        this.dataList = await this.getDataList(
          this.setURL + "?stateCode=" + this.stateCode
        );
        window.$("#addModal").modal("hide");
        this.addButtonText = "Create";
        this.addButtonIconClass = "fa-solid fa-rotate";
        this.addButtonClass = "btn btn-primary";
        this.addButtonFlag = false;
      }
    },
    editContactReason: async function (data) {
      this.singleData = data;
    },
    updateContactReason: async function () {
      if (!this.singleData.contactReasonName) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter contact reason!",
        });
      } else {
        this.updateButtonText = "Updating...";
        this.updateButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.updateButtonClass = "btn btn-secondary";
        this.updateButtonFlag = true;
        let data = {
          contactReasonName: this.singleData.contactReasonName,
          contactEmail: this.singleData.contactEmail,
          stateCode: this.singleData.stateCode,
          stateName: this.singleData.stateName,
          isShow: this.singleData.isShow,
        };
        let config = {
          method: "PATCH",
          url: `${this.setURL}/${this.singleData.id}`,
          data: data,
          headers: authHeader(),
        };
        await this.updateData(config);
        this.contactReasonName = "";
        this.contactEmail = "";
        this.dataList = await this.getDataList(
          this.setURL + "?stateCode=" + this.stateCode
        );
        window.$("#editModal").modal("hide");
        this.updateButtonText = "Update";
        this.updateButtonIconClass = "fa-solid fa-rotate";
        this.updateButtonClass = "btn btn-primary";
        this.updateButtonFlag = false;
      }
    },
    async handleSetDefault() {
      let token = localStorage.getItem("token");
      let data = {
        stateCode: this.stateCode,
      };
      let config = {
        method: "POST",
        url: this.$serverURL + this.$api.contact.setDefault,
        data: data,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      await this.$axios(config)
        .then(async (response) => {
          if (response.status == 200) {
            console.log("check");
            this.$swal.fire({
              toast: true,
              position: "top-end",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              animation: false,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          }
          this.dataList = await this.getDataList(
            this.setURL + "?stateCode=" + this.stateCode
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async handleUpdateHideShow(data) {
      let token = localStorage.getItem("token");
      let config = {
        method: "PATCH",
        url:
          this.$serverURL +
          this.$api.contact.contactReasonIsShowURL +
          "/" +
          data.id,
        data: {
          isShow: data.isShow,
        },
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      await this.$axios(config)
        .then(async (response) => {
          if (response.data.statusCode == 200) {
            this.dataList = await this.getDataList(
              this.setURL + "?stateCode=" + this.stateCode
            );
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    },
    deleteContactReason: function (id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (result.isConfirmed) {
              try {
                let config = {
                  method: "DELETE",
                  url: `${this.setURL}/${id}`,
                  headers: authHeader(),
                };
                await this.deleteData(config);
                this.dataList = await this.getDataList(
                  this.setURL + "?stateCode=" + this.stateCode
                );
              } catch (error) {
                this.$swal.fire({
                  icon: "error",
                  text: "Something went wrong, please try agian later." + error,
                });
              }
            }
          }
        });
    },
    isDisabled(value) {
      return this.disabledReasons.some(
        (data) => data.contactReasonName === value
      );
    },
  },
};
