import { authHeader } from "../../../../../../auth";
export default {
  name: "LandingOneSectionEightCmsContent",
  data() {
    return {
      contentList: [],
      setCmsURL:
        this.$serverURL + this.$api.cms.landing.landingOneSectionEightCmsUrl,
      setURL: this.$serverURL + this.$api.cms.landing.landingOneSectionEightUrl,
      name: "",
      logo: "",
      singleContent: {},
      previewImage: null,
      previewUpdateImage: null,
      cmsData: {},
      addButtonText: "Create",
      addButtonIconClass: "fa-solid fa-rotate",
      addButtonClass: "btn btn-primary",
      addButtonFlag: false,
      updateButtonText: "Update",
      updateButtonIconClass: "fa-solid fa-rotate",
      updateButtonClass: "btn btn-primary",
      updateButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Landing One Section Eight CMS & Content";
    await this.getSectionCmsData();
    await this.getContentData();
    var scripts = [
      "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
      "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
      "/core/assets/js/table-datatable.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href =
      "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
    document.head.appendChild(style);
  },

  methods: {
    async getContentData() {
      const res = await this.getDataList(this.setURL);
      this.contentList = res;
    },
    onImageChange: function (event) {
      this.logo = event.target.files[0];
      let input = this.$refs.imageFile;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    validate: function () {
      if (!this.name) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter title!",
        });
        return false;
      } else if (!this.logo) {
        this.$swal.fire({
          icon: "error",
          text: "Please upload image!",
        });
        return false;
      }
      return true;
    },
    save: async function () {
      if (this.validate() == true) {
        this.addButtonText = "Creating...";
        this.addButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.addButtonClass = "btn btn-secondary";
        this.addButtonFlag = true;
        let token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("logo", this.logo);
        formData.append("name", this.name);
        let config = {
          method: "POST",
          url:
            this.$serverURL + this.$api.cms.landing.landingOneSectionEightUrl,
          data: formData,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        await this.$axios(config)
          .then(async (response) => {
            if (response.status == 201) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }

            this.name = "";
            this.logo = "";
            this.previewImage = "";
            this.$refs.imageFile.value = null;
            await this.getContentData();
            document.getElementById("addCloseButton").click();
            let element = document.getElementsByClassName(
              "lv-colorpicker__colorblock"
            );
            element.forEach((el) => {
              el.style.backgroundColor = "";
            });
            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary";
            this.addButtonFlag = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });
            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary";
            this.addButtonFlag = false;
          });
      }
    },

    async editContent(data) {
      this.singleContent = {};
      this.singleContent = await data;
    },

    onImageUpdateChange(event) {
      this.singleContent.logo = event.target.files[0];
      let input = this.$refs.imageUpdateFile;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewUpdateImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },

    update: async function () {
      this.updateButtonText = "Updating...";
      this.updateButtonIconClass = "fa-solid fa-rotate fa-spin";
      this.updateButtonClass = "btn btn-secondary";
      this.updateButtonFlag = true;
      let token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("logo", this.singleContent.logo);
      formData.append("name", this.singleContent.name);
      let config = {
        method: "PATCH",
        url:
          this.$serverURL +
          this.$api.cms.landing.landingOneSectionEightUrl +
          "/" +
          this.singleContent.id,
        data: formData,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      };
      await this.$axios(config)
        .then(async (response) => {
          if (response.status == 200) {
            this.$swal.fire({
              toast: true,
              position: "top-end",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              animation: false,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          }
          this.singleContent = {};
          this.previewUpdateImage = "";
          this.$refs.imageUpdateFile.value = null;
          await this.getContentData();
          document.getElementById("editCloseButton").click();
          this.updateButtonText = "Update";
          this.updateButtonIconClass = "fa-solid fa-rotate";
          this.updateButtonClass = "btn btn-primary";
          this.updateButtonFlag = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            text: error.response.data.message,
          });
          this.updateButtonText = "Update";
          this.updateButtonIconClass = "fa-solid fa-rotate";
          this.updateButtonClass = "btn btn-primary";
          this.updateButtonFlag = false;
        });
    },
    addCss() {
      document.querySelector(".lv-overlaypanel").style.zIndex = 20000;
    },
    deleteContent: function (id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              let config = {
                method: "DELETE",
                url: this.setURL + "/" + id,
                headers: authHeader(),
              };
              await this.deleteData(config);
              // this.contentList = await this.getDataList(this.setURL + '?stateCode=' + this.stateCode);
              await this.getContentData();
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                text: error.response.data.message,
              });
            }
          }
        });
    },

    async getSectionCmsData() {
      let response = await this.getCmsData(this.setCmsURL);
      if (response.statusCode == 200) {
        this.cmsData = response.data.length > 0 ? response.data[0] : {};
      } else {
        this.cmsData = {};
      }
    },

    async updateCms() {
      try {
        let data = {
          title: this.cmsData.title,
        };
        this.$swal.showLoading();
        let response = await this.createUpdateCMS(this.setCmsURL, data);
        if (response.statusCode == 200 || response.statusCode == 201) {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        }
        await this.getSectionCmsData();
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          text: "Something went wrong. Please try again!",
        });
      } finally {
        this.$swal.hideLoading();
      }
    },
  },
};
