<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="bx bx-home-alt"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Cart Counter</li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <div class="row">
        <div class="col-6">
          <h6 class="mb-0">Enable/Disable Cart Counter</h6>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card">
            <div class="card-body">
              <div class="text-center">
                <div class="border border-3 p-5 rounded">
                  <h5 class="text-center pt-4">
                    <b>Cart Counter is {{cmsData.enableCartCounter ? 'Enabled' : 'Disabled'}}</b>
                  </h5>
                  <div class="d-flex justify-content-center form-check form-switch">
                    <label class="form-label"></label>
                    <input
                      @change="handleUpdate()"
                      class="form-check-input"
                      type="checkbox"
                      v-model="cmsData.enableCartCounter"
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mx-auto">
          <div class="card">
            <div class="card-body">
              <div class="">
                <div class="border border-3 p-4 rounded d-flex justify-content-center flex-column align-items-center">
                  <h5 class=" pt-4">
                    <b>Set Time</b>
                  </h5>
                  <div class="d-flex">
                    <div class="row">
                      <div class="col-5">
                        <label for="minutes">Minutes:</label>
                        <select v-model="cmsData.minute" class="form-control" id="minutes">
                          <option style="display:none" value selected>Select Minutes</option>
                          <option value="0">00</option>
                          <option value="1">01</option>
                          <option value="2">02</option>
                          <option value="3">03</option>
                          <option value="4">04</option>
                          <option value="5">05</option>
                          <option value="6">06</option>
                          <option value="7">07</option>
                          <option value="8">08</option>
                          <option value="9">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                          <option value="32">32</option>
                          <option value="33">33</option>
                          <option value="34">34</option>
                          <option value="35">35</option>
                          <option value="36">36</option>
                          <option value="37">37</option>
                          <option value="38">38</option>
                          <option value="39">39</option>
                          <option value="40">40</option>
                          <option value="41">41</option>
                          <option value="42">42</option>
                          <option value="43">43</option>
                          <option value="44">44</option>
                          <option value="45">45</option>
                          <option value="46">46</option>
                          <option value="47">47</option>
                          <option value="48">48</option>
                          <option value="49">49</option>
                          <option value="50">50</option>
                          <option value="51">51</option>
                          <option value="52">52</option>
                          <option value="53">53</option>
                          <option value="54">54</option>
                          <option value="55">55</option>
                          <option value="56">56</option>
                          <option value="57">57</option>
                          <option value="58">58</option>
                          <option value="59">59</option>
                        </select>
                      </div>
                      <div class="col-5">
                        <label for="seconds">Seconds:</label>
                        <select v-model="cmsData.second" class="form-control" id="seconds">
                          <option style="display:none" value="" selected>Select Seconds</option>
                          <option value="0">00</option>
                          <option value="1">01</option>
                          <option value="2">02</option>
                          <option value="3">03</option>
                          <option value="4">04</option>
                          <option value="5">05</option>
                          <option value="6">06</option>
                          <option value="7">07</option>
                          <option value="8">08</option>
                          <option value="9">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                          <option value="32">32</option>
                          <option value="33">33</option>
                          <option value="34">34</option>
                          <option value="35">35</option>
                          <option value="36">36</option>
                          <option value="37">37</option>
                          <option value="38">38</option>
                          <option value="39">39</option>
                          <option value="40">40</option>
                          <option value="41">41</option>
                          <option value="42">42</option>
                          <option value="43">43</option>
                          <option value="44">44</option>
                          <option value="45">45</option>
                          <option value="46">46</option>
                          <option value="47">47</option>
                          <option value="48">48</option>
                          <option value="49">49</option>
                          <option value="50">50</option>
                          <option value="51">51</option>
                          <option value="52">52</option>
                          <option value="53">53</option>
                          <option value="54">54</option>
                          <option value="55">55</option>
                          <option value="56">56</option>
                          <option value="57">57</option>
                          <option value="58">58</option>
                          <option value="59">59</option>
                        </select>
                      </div>
                      <div class="col-2" style="margin-top: 20px;">
                        <button type="submit" class="btn btn-primary mb-3" @click="handleUpdate()">Save</button>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card">
            <div class="card-body">
              <div class="text-center">
                <div class="border border-3 p-4 rounded">
                  <h5 class="text-center pt-4">
                    <b>Elastic Product Sync API</b>
                  </h5>
                  <button
                    @click="getElasticSearchData()"
                    :class="elasticButtonClass"
                    :disabled="elasticButtonFlag == true"
                  >
                    <i :class="elasticButtonIconClass"></i>
                    {{ elasticButtonText }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mx-auto">
          <div class="card">
            <div class="card-body">
              <div class="text-center">
                <div class="border border-3 p-4 rounded">
                  <h5 class="text-center pt-4">
                    <b>Elastic Retailer Sync API</b>
                  </h5>
                  <button
                    @click="getRetailerElasticSearchData()"
                    :class="elasticRetailerButtonClass"
                    :disabled="elasticRetailerButtonFlag == true"
                  >
                    <i :class="elasticRetailerButtonIconClass"></i>
                    {{ elasticRetailerButtonText }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" style="text-align: center;">
          <h3>
            <code>Note: If you don't synchronize here, it will automatically synchronize dutchie and elastic search in every hour.</code>
          </h3>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script src="../js/cart-counter.js"></script>

<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>