<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Landing Three Section Ten Cms & Content List</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Landing Three Section Ten Cms & Content List</li>
            </ol>
          </nav>
        </div>
      </div>
      <div>
        <form @submit.prevent="updateCms()">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">CMS Settings</h5>
              <hr />
              <div class="form-body mt-4">
                <div class="row">
                  <div class="col-lg-12 mb-3">
                    <div class="border border-3 p-4 rounded">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label class="form-label">Section Title:</label>
                          <input type="text" v-model="cmsData.title" class="form-control" placeholder="Enter title" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="d-grid">
                      <button type="submit" class="btn btn-primary">Save</button>
                    </div>`
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <h6 class="mb-0 text-uppercase">Landing Three Section Ten Content List</h6>
        <div class="ms-auto">
          <div class="btn-group">
            <button data-bs-toggle="modal" data-bs-target="#addModal" type="button" class="btn btn-primary">
              <i class="bx bxs-plus-square"></i>Add New
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Question</th>
                  <th>Answer</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="contentList.length > 0">
                <tr v-for="(data, index) in contentList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.question.length > 50 ? `${data.question.slice(0, 50)}...` : data.question }}</td>
                  <td>{{ data.answer.length > 90 ? `${data.answer.slice(0, 90)}...` : data.answer }}</td>
                  <td>{{ $filters.formatDate(data.createdAt) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a href="javascript:void(0);" v-on:click="editContent(data)" data-bs-toggle="modal"
                        data-bs-target="#editModal" class="edit-button">
                        <i class="bx bxs-edit text-white"></i>
                      </a>
                      <a href="javascript:void(0);" v-on:click="deleteContent(data.id)" class="ms-3 delete-button"
                        :style="[data.id == id ? { 'pointer-events': 'none', 'background-color': '#e56f7a' } : '']">
                        <i class="bx bxs-trash text-white"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Question</th>
                  <th>Answer</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <!-- Add Modal -->
      <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
        <form @submit.prevent="save">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="addModalLabel">Add Content</h5>
                <button type="button" id="addCloseButton" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <div class="border border-3 p-4 rounded">
                <div class="row">
                  <div class="col-12 mb-3">
                    <label class="form-label">Question:</label>
                    <input type="text" v-model="question" class="form-control" placeholder="Enter question" />
                  </div>
                  <div class="col-12 mb-3">
                    <label class="form-label">Answer:</label>
                    <textarea class="form-control" rows="3" v-model="answer" placeholder="Enter answer"></textarea>
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" :class="addButtonClass" :disabled="addButtonFlag == true"><i
                      :class='addButtonIconClass'></i>{{ addButtonText }}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- Add Modal -->
      <!-- Edit Modal -->
      <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
        <form @submit.prevent="update">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="addModalLabel">Update Content</h5>
                <button type="button" id="editCloseButton" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>

              <div class="border border-3 p-4 rounded">
                <div class="row">
                  <div class="col-12 mb-3">
                    <label class="form-label">Question:</label>
                    <input type="text" v-model="singleContent.question" class="form-control"
                      placeholder="Enter question" />
                  </div>
                  <div class="col-12 mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Answer:</label>
                    <textarea class="form-control" rows="3" v-model="singleContent.answer"
                      placeholder="Enter answer"></textarea>
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" :class="updateButtonClass" :disabled="updateButtonFlag == true"><i
                      :class='updateButtonIconClass'></i>{{ updateButtonText }}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- Edit Modal -->
    </div>
  </div>
</template>

<style scoped>
@import "../../../../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}

.lv-component {
  z-index: 2000 !important;
}
</style>

<script src="../js/landing-three-section-ten-cms-content.js"></script>