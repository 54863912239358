import { authHeader } from "../../../../auth";

export default {
  name: "SocialLinks",
  data() {
    return {
      singleSocialLink: {},
      className: "",
      link: "",
      setURL: this.$serverURL + this.$api.cms.socialLinkURL,
      dataList: [],
      addButtonText: "Create",
      addButtonIconClass: "fa-solid fa-rotate",
      addButtonClass: "btn btn-primary",
      addButtonFlag: false,
      updateButtonText: "Update",
      updateButtonIconClass: "fa-solid fa-rotate",
      updateButtonClass: "btn btn-primary",
      updateButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Social Links";
    this.dataList = await this.getDataList(this.setURL);
    var scripts = [
      "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
      "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
      "/core/assets/js/table-datatable.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href =
      "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
    document.head.appendChild(style);
  },
  methods: {
    addSocialLink: async function () {
      if (!this.className) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter icon class name!",
        });
      } else if (!this.link) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter link!",
        });
      } else {
        this.addButtonText = "Creating...";
        this.addButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.addButtonClass = "btn btn-secondary";
        this.addButtonFlag = true;
        let data = {
          className: this.className,
          link: this.link,
        };
        let config = {
          method: "POST",
          url: this.setURL,
          data: data,
          headers: authHeader(),
        };
        let response = await this.postData(config);
        if (response.statusCode == 201) {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        }
        this.className = "";
        this.link = "";
        this.dataList = await this.getDataList(this.setURL);
        document.getElementById("closeButton").click();
        this.addButtonText = "Create";
        this.addButtonIconClass = "fa-solid fa-rotate";
        this.addButtonClass = "btn btn-primary";
        this.addButtonFlag = false;
      }
    },
    editSocialLink: async function (data) {
      this.singleSocialLink = data;
    },
    updateSocialLink: async function () {
      if (!this.singleSocialLink.className) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter icon class name!",
        });
      } else if (!this.singleSocialLink.link) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter link!",
        });
      } else {
        this.updateButtonText = "Updating...";
        this.updateButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.updateButtonClass = "btn btn-secondary";
        this.updateButtonFlag = true;
        let data = {
          className: this.singleSocialLink.className,
          link: this.singleSocialLink.link,
        };
        let config = {
          method: "PATCH",
          url: this.setURL + "/" + this.singleSocialLink.id,
          data: data,
          headers: authHeader(),
        };
        let response = await this.updateData(config);
        if (response.statusCode == 200) {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        }
        this.singleSocialLink = {};
        this.dataList = await this.getDataList(this.setURL);
        document.getElementById("buttonClose").click();
        this.updateButtonText = "Update";
        this.updateButtonIconClass = "fa-solid fa-rotate";
        this.updateButtonClass = "btn btn-primary";
        this.updateButtonFlag = false;
      }
    },
    deleteSocialLink: function (id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              let config = {
                method: "DELETE",
                url: this.setURL + "/" + id,
                headers: authHeader(),
              };
              await this.deleteData(config);
              this.dataList = await this.getDataList(this.setURL);
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                text: error.response.data.message,
              });
            }
          }
        });
    },
  },
};
