import { authHeader } from './auth';

export default {
    data() {
        return {
            cmsData: []
        }
    },
    methods: {
        updateDataToBackend: async function (config) {
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        deleteDataToBackend: async function (config) {
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }

            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        postDataToBackend: async function (config) {
            this.$swal.showLoading();
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.status == 201 || response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                else {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.hideLoading();
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        getAddressFromLatLng: async function ({lat,lng}) {
            try {
                let config = {
                    method: "GET",
                    url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.$credentials.GOOGLE_MAPS_API_KEY}`,
                    // headers: authHeader()
                }
                let response = await this.$axios(config);
                return response.data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        getCmsData: async function (url) {
            try {
                let config = {
                    method: "GET",
                    url: url,
                    headers: authHeader()
                }
                let response = await this.$axios(config);
                return response.data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        createUpdateCMS: async function (url, data) {
            try {
                let response = await this.$axios({
                    method: 'POST',
                    url: url,
                    data: data,
                    headers: authHeader()
                });
                return response.data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        getSingleData: async function (url) {
            try {
                let config = {
                    method: "GET",
                    url: url,
                    headers: authHeader()
                }
                let response = await this.$axios(config);
                return response.data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        getDataList: async function (url) {
            try {
                let config = {
                    method: "GET",
                    url: url,
                    headers: authHeader()
                }
                let response = await this.$axios(config);
                return response.data.data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        postData: async function (config) {
            try {
                let response = await this.$axios(config);
                return response.data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        updateData: async function (config) {
            try {
                let response = await this.$axios(config);
                return response.data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        deleteData: async function (config) {
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        createUrlSlug(title) {
            let slug = "";
        
            // Convert to lower case
            slug = title.toLowerCase();
        
            // Remove special characters
            slug = slug.replace(/`|~|!|@|#|\||\$|%|\^|&|\*|\(|\)|\+|=|,|\?|>|<|'|"|:|;|_/gi, '');
        
            // Replace spaces with dash symbols
            slug = slug.replace(/ /gi, "-");
        
            // Replace forward slash with dash symbols
            slug = slug.replace(/\//gi, "-");
        
            // Replace dot with dash symbols
            slug = slug.replace(/\./gi, "-");
        
            // Remove consecutive dash symbols 
            slug = slug.replace(/-----/gi, '-');
            slug = slug.replace(/----/gi, '-');
            slug = slug.replace(/---/gi, '-');
            slug = slug.replace(/--/gi, '-');
        
            // Remove the unwanted dash symbols at the beginning and the end of the slug
            slug = '@' + slug + '@';
            slug = slug.replace(/@-|-@|@/gi, '');
        
            return slug;
        }
    }
}