<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                <div class="col">
                    <div class="card radius-10 cp" @click="$router.push('/store')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Retailers</p>
                                    <h4 class="my-1">{{ totalRetailer }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-primary text-primary ms-auto">
                                    <i class="fa-solid fa-store"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card radius-10 cp" @click="$router.push('/career')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Career</p>
                                    <h4 class="my-1">{{ totalCareer }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                                    <i class="fa-solid fa-briefcase"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card radius-10 cp" @click="$router.push('/career-applicant')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Career Applicant</p>
                                    <h4 class="my-1">{{ totalCareerApplicant }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                                    <i class="fa-solid fa-user-tie"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="card radius-10 cp" @click="$router.push('/press-release')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Press Release</p>
                                    <h4 class="my-1">{{ totalPressRelease }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-info text-info ms-auto">
                                    <i class="fa-solid fa-newspaper"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card radius-10 cp" @click="$router.push('/blog-post')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Article</p>
                                    <h4 class="my-1">{{ totalArticle }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-info text-info ms-auto">
                                    <i class="fa-solid fa-pen-nib"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card radius-10 cp" @click="$router.push('/feedback')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Feedback</p>
                                    <h4 class="my-1">{{ totalFeedback }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-info text-info ms-auto">
                                    <i class="fa-solid fa-square-pen"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card radius-10 cp" @click="$router.push('/contact')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Contacts</p>
                                    <h4 class="my-1">{{ totalContact }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-info text-info ms-auto">
                                    <i class="fa-solid fa-address-book"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card radius-10 h-100">
                        <div class="card-body">
                            <div class="chart-container1">
                                <canvas id="retailerChart" style="position: relative; height:100vh; width:80vw;"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card radius-10 h-100">
                        <div class="card-body">
                            <h5 class="card-title mb-0 pb-0">Copy Links</h5>
                            <hr>
                            <LinkShortcut/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-xl-12 d-flex">
                    <div class="card radius-10 w-100">
                        <div class="card-body">
                            <h5 class="card-title mb-0 pb-0">Quick Links</h5>
                            <hr>
                            <div class="d-flex justify-content-around align-items-center">
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/new-drop-banner-cms')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;">
                                        <i class="fa-solid fa-link"></i> <br>Home New Drops
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/section-two-cms')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;">
                                        <i class="fa-solid fa-link"></i><br>Home Section Two 
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/section-five-cms')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;">
                                        <i class="fa-solid fa-link"></i> <br>Home Section Five
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/section-eight-cms')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;" >
                                        <i class="fa-solid fa-link"></i> <br>Home Section Eight
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/high-deals-cms')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;">
                                        <i class="fa-solid fa-link"></i> <br>Cart Page High Deals
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/how-to-enjoy-cms')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;" >
                                        <i class="fa-solid fa-link"></i> <br>Product Page How to Enjoy
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/dashboard.js"></script>

<style scoped>
.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}

.cp {
    cursor: pointer;
}
</style>