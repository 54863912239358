import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { authHeader } from "../../../auth";
import Editor from "@tinymce/tinymce-vue";
import Multiselect from "@vueform/multiselect";

export default {
  name: "BlogEditPost",
  components: {
    Editor,
    Multiselect,
  },
  data() {
    return {
      blogList: [],
      blogId: null,
      relatedOptions: [],
      blogCategory: "",
      blogTags: "",
      options: [],
      selectedUploaderImage: null,
      selectedPostImage: null,
      singleContent: [],
      selectedBlogTags: [],
      selectedRelatedPosts: [],
      category: {},
      editor: ClassicEditor,
      editorConfig: {
        ckfinder: {
          uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
        },
        mediaEmbed: {
          previewsInData: true,
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
          ],
        },
      },
      photo: "",
      previewImage: "",
      imageFile: "",
      categories: [
        "Education",
        "Diversity, Equity & Inclusion",
        "Culture",
        "Higher Self",
      ],
      updateButtonText: "Update",
      updateButtonIconClass: "fa-solid fa-rotate",
      updateButtonClass: "btn btn-primary",
      updateButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Article Post";
    this.blogId = this.$route.params.id;
    await this.getBlogData();
    await this.getBlogList();
    await this.getBlogCategory();
    // await this.getBlogTags();
    var scripts = [
      "/core/assets/js/jquery.min.js",
      "/core/assets/plugins/simplebar/js/simplebar.min.js",
      "/core/assets/plugins/metismenu/js/metisMenu.min.js",
      "./core/assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js",
      "/core/assets/plugins/smart-wizard/js/jquery.smartWizard.min.js",
      "/core/assets/js/form-wizard.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  methods: {
    onImageChange(event) {
      this.imageFile = event.target.files[0];
      let input = this.$refs.imageFile;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    getBlogList: function () {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.blog.postURL,
        headers: authHeader(),
      };
      this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.blogList = response.data.data;
            this.relatedOptions = response.data.data.map((el) => {
              return { value: el.title, label: el.title };
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again!",
          });
        });
    },
    getBlogData: async function () {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.blog.postURL + "/" + this.blogId,
        headers: authHeader(),
      };
      await this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.singleContent = response.data.data[0];
            this.category = this.singleContent?.categoryName;
            // let tagsData = this.singleContent?.tags;
            // if (tagsData) {
            //     this.selectedBlogTags = tagsData.map((el) => {
            //         return el.tagName;
            //     })
            // }
            let relatedPosts = this.singleContent.relatedPosts;
            if (relatedPosts) {
              this.selectedRelatedPosts = relatedPosts.map((el) => {
                return el.relatedPostTitle;
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again!",
          });
        });
    },

    getBlogCategory: async function () {
      let config = {
        method: "GET",
        // url: this.$serverURL + this.$api.blog.categoryURL,
        url: this.$serverURL + this.$api.blog.articleCategory,
        headers: authHeader(),
      };
      await this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.blogCategory = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again!",
          });
        });
    },
    getBlogTags: async function () {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.blog.tagURL,
        headers: authHeader(),
      };
      await this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.blogTags = response.data.data;
            this.options = response.data.data.map((el) => {
              return { value: el.tagName, label: el.tagName };
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again!",
          });
        });
    },

    onPostImageChange(event) {
      this.selectedPostImage = event.target.files[0];
    },
    validate: function () {
      if (!this.singleContent.title) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter article title!",
        });
        return false;
      }
      if (!this.singleContent.shortDetails) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter short details!",
        });
        return false;
      }
      if (!this.singleContent.longDetails) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter long details!",
        });
        return false;
      }
      if (!this.selectedPostImage && !this.singleContent.photo) {
        this.$swal.fire({
          icon: "error",
          text: "Please upload article image!",
        });
        return false;
      }
      if (!this.category) {
        this.$swal.fire({
          icon: "error",
          text: "Please select article category!",
        });
        return false;
      }
      // if (this.selectedBlogTags.length === 0) {
      //     this.$swal.fire({
      //         icon: "error",
      //         text: "Please select article tags!"
      //     });
      //     return false;
      // }
      // if (this.selectedRelatedPosts.length === 0) {
      //     this.$swal.fire({
      //         icon: "error",
      //         text: "Please select related article post!"
      //     });
      //     return false;
      // }
      return true;
    },
    updatePost: async function () {
      if (this.validate() == true) {
        this.updateButtonText = "Updating...";
        this.updateButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.updateButtonClass = "btn btn-secondary";
        this.updateButtonFlag = true;
        let token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("title", this.singleContent.title);
        formData.append(
          "urlSlug",
          this.singleContent.urlSlug
            ? this.singleContent.urlSlug
            : this.singleContent.title
        );
        formData.append("shortDetails", this.singleContent.shortDetails);
        formData.append("longDetails", this.singleContent.longDetails);
        formData.append("categoryId", this.category);
        formData.append("categoryName", this.category);
        formData.append(
          "photo",
          this.imageFile ? this.imageFile : this.singleContent.photo
        );
        formData.append(
          "relatedPosts",
          JSON.stringify(this.selectedRelatedPosts)
        );
        formData.append("tags", "");
        let config = {
          method: "PATCH",
          url: this.$serverURL + this.$api.blog.postURL + "/" + this.blogId,
          data: formData,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        await this.$axios(config)
          .then((response) => {
            if (response.status == 200) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
              this.photo = "";
              this.imageFile = "";
              this.$refs.imageFile.value = null;
              this.previewImage = "";
              this.updateButtonText = "Update";
              this.updateButtonIconClass = "fa-solid fa-rotate";
              this.updateButtonClass = "btn btn-primary";
              this.updateButtonFlag = false;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong. Please try again!",
            });
            this.updateButtonText = "Update";
            this.updateButtonIconClass = "fa-solid fa-rotate";
            this.updateButtonClass = "btn btn-primary";
            this.updateButtonFlag = false;
          });
        this.$router.push("/blog-post");
      }
    },
  },
};
