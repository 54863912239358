<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Category</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">State Wise Category</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group"></div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">State Wise Category List</h6>
      <hr />
      <div class="card">
        <div class="card-body" style="display: flex; align-items: center;">
          <h5 style="margin: 0;">{{stateName}}</h5>
          <div
            style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
            @click="$router.push({ path: '/Category-states'})"
          >
            <span style="margin-right: 5px;">
              <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
            </span>
            <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
          </div>
        </div>
      </div>
      <div
        class="page-breadcrumb d-none d-sm-flex align-items-center mb-3"
        v-if="dataList.length>0"
      >
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              @click="handleSetDefault"
              :disabled="isDefault"
            >
              <i class="bx bxs-plus-square"></i>Set Default
            </button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="p-2 mt-2" style="margin-right:5px">
          <div style="float:right">
            <input
              type="text"
              class="form-control"
              placeholder="Search category"
              v-model="searchKey"
            />
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table id class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Created At</th>
                  <th>Hide/Show</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <draggable
                v-model="categoryList"
                tag="tbody"
                item-key="data.id"
                @update="onDragUpdate"
              >
                <template #item="{ element: row }">
                  <tr :class="searchKey ? 'disable-row' : ''">
                    <td>{{ row.position }}</td>
                    <td>{{ row.categoryName }}</td>
                    <td>{{ $filters.formatDate(row.createdAt) }}</td>
                    <td>
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="row.isShown"
                          id="flexSwitchCheckDefault"
                          @change="handleUpdateHideShow(row)"
                        />
                      </div>
                    </td>
                    <td>
                        <img :src="row.featuredImage ? row.featuredImage : 'https://jars-dutchi.nyc3.digitaloceanspaces.com/default/category-image.png'" height="80" />
                    </td>
                    <td width="10">
                      <div class="d-flex">
                          <button type="button" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModal" v-on:click="editImage(row)" class="btn btn-success">
                              <i class="bx bxs-edit"></i>
                              Change Image
                          </button>
                      </div>
                    </td>
                  </tr>
                </template>
              </draggable>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Created At</th>
                  <th>Hide/Show</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Edit Image</h5>
                  <button type="button" class="btn-close" id="close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                  <div class="mb-3">
                      <label class="form-label">Image: [Preferred Image Size: 510X500, Max Upload Limit: 1MB]</label>
                      <input class="form-control" @change="onImageChange" type="file" ref="image"/>
                  </div>
                  <div class="mb-3">
                      <div class="col-md-12">
                          <img v-if="previewImage" class="img-thumbnail" :src="previewImage" style="height: 100px;"/>
                          <img v-else-if="singleData.featuredImage" class="img-thumbnail" :src="singleData.featuredImage" style="height: 100px;"/>
                          <img v-else class="img-thumbnail" src="../../../assets/images/default.png" style="height: 100px;" />
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary" @click="update">Update</button>
              </div>
          </div>
      </div>
  </div>
  </div>
</template>
  
<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>

<script src="../js/state-wise-category.js"></script>