import { authHeader } from "../../../auth";
import * as XLSX from "xlsx";

export default {
  name: "Customer",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      userType: "customer",
      password: null,
      confirmPassword: null,
      inputTypePassword: "password",
      inputTypeConfirmPassword: "password",
      iconStatus: true,
      iconFlag: true,
      setURL: "",
      singleCustomer: {},
      sheets: [],
      country: "United States of America",
      dataList: [],
      addButtonText: "Create",
      addButtonIconClass: "fa-solid fa-rotate",
      addButtonClass: "btn btn-primary",
      addButtonFlag: false,
      updateButtonText: "Update",
      updateButtonIconClass: "fa-solid fa-rotate",
      updateButtonClass: "btn btn-primary",
      updateButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Customer";
    this.setURL = this.$serverURL + this.$api.user.customerList;
    this.dataList = await this.getDataList(this.setURL);
    var scripts = [
      "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
      "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
      "/core/assets/js/table-datatable.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href =
      "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
    document.head.appendChild(style);
  },
  methods: {
    checkValidation: function () {
      if (!this.firstName) {
        this.$swal.fire({
          icon: "info",
          text: "Please enter first name!",
        });
        return false;
      }
      if (!this.lastName) {
        this.$swal.fire({
          icon: "info",
          text: "Please enter last name!",
        });
        return false;
      } else if (!this.email) {
        this.$swal.fire({
          icon: "info",
          text: "Please enter email!",
        });
        return false;
      } else if (!this.phone) {
        this.$swal.fire({
          icon: "info",
          text: "Please enter phone!",
        });
        return false;
      } else if (!this.password) {
        this.$swal.fire({
          icon: "info",
          text: "Please enter password!",
        });
        return false;
      } else if (!this.confirmPassword) {
        this.$swal.fire({
          icon: "info",
          text: "Please enter confirm password!",
        });
        return false;
      } else if (this.confirmPassword !== this.password) {
        this.$swal.fire({
          icon: "info",
          text: "Password and confirm password doesn't match!",
        });
        return false;
      }
      return true;
    },
    addCustomer: async function () {
      if (this.checkValidation() == true) {
        this.addButtonText = "Creating...";
        this.addButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.addButtonClass = "btn btn-secondary";
        this.addButtonFlag = true;
        let data = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          password: this.password,
        };
        let config = {
          method: "POST",
          url: this.$serverURL + this.$api.user.registrationURL,
          data: data,
          headers: authHeader(),
        };
        let response = await this.postData(config);
        if (response.statusCode == 201) {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          document.getElementById("addButton").click();
          this.firstName = "";
          this.lastName = "";
          this.phone = "";
          this.userType = "customer";
          this.password = "";
          this.confirmPassword = "";
          this.inputTypePassword = "password";
          this.inputTypeConfirmPassword = "password";
        }
        this.dataList = await this.getDataList(this.setURL);
        this.addButtonText = "Create";
        this.addButtonIconClass = "fa-solid fa-rotate";
        this.addButtonClass = "btn btn-primary";
        this.addButtonFlag = false;
      }
    },
    editCustomer: function (data) {
      this.singleCustomer = data;
    },
    checkUpdateValidation: function () {
      if (!this.singleCustomer.firstName) {
        this.$swal.fire({
          icon: "info",
          title: "Please enter first name!",
        });
        return false;
      }
      if (!this.singleCustomer.lastName) {
        this.$swal.fire({
          icon: "info",
          title: "Please enter last name!",
        });
        return false;
      }
      if (!this.singleCustomer.email) {
        this.$swal.fire({
          icon: "info",
          title: "Please enter email!",
        });
        return false;
      }
      if (!this.singleCustomer.phone) {
        this.$swal.fire({
          icon: "info",
          title: "Please enter phone number!",
        });
        return false;
      }
      if (this.singleCustomer.phone.length <= 9) {
        this.$swal.fire({
          icon: "info",
          title: "Phone number nust be at least 10 digits!",
        });
        return false;
      }
      return true;
    },
    updateCustomer: async function () {
      if (this.checkUpdateValidation()) {
        this.updateButtonText = "Updating...";
        this.updateButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.updateButtonClass = "btn btn-secondary";
        this.updateButtonFlag = true;
        let data = {
          firstName: this.singleCustomer.firstName,
          lastName: this.singleCustomer.lastName,
          phone: this.singleCustomer.phone,
          userType: this.singleCustomer.userType,
        };
        let config = {
          method: "PATCH",
          url:
            this.$serverURL +
            this.$api.user.singleUserURL +
            this.singleCustomer.id,
          data: data,
          headers: authHeader(),
        };
        let response = await this.updateData(config);
        if (response.statusCode == 200) {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        }
        document.getElementById("updateButton").click();
        this.singleCustomer = {};
        this.dataList = await this.getDataList(this.setURL);
        this.updateButtonText = "Update";
        this.updateButtonIconClass = "fa-solid fa-rotate";
        this.updateButtonClass = "btn btn-primary";
        this.updateButtonFlag = false;
      }
    },
    changePasswordType: function () {
      if (this.inputTypePassword == "password") {
        this.inputTypePassword = "text";
        this.iconStatus = false;
      } else if (this.inputTypePassword == "text") {
        this.inputTypePassword = "password";
        this.iconStatus = true;
      }
    },
    changeConfirmPasswordType: function () {
      if (this.inputTypeConfirmPassword == "password") {
        this.inputTypeConfirmPassword = "text";
        this.iconFlag = false;
      } else if (this.inputTypeConfirmPassword == "text") {
        this.inputTypeConfirmPassword = "password";
        this.iconFlag = true;
      }
    },
    deleteCustomer: async function (id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              let config = {
                method: "DELETE",
                url: this.$serverURL + this.$api.user.singleUserURL + id,
                headers: authHeader(),
              };
              await this.deleteData(config);
              this.dataList = await this.getDataList(this.setURL);
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                text: error.response.data.message,
              });
            }
          }
        });
    },
    exportUsers: function () {
      this.dataList.map((el) => {
        this.sheets.push({
          "Customer Name": el.firstName + " " + el.lastName,
          Email: el.email,
          Phone: el.phone,
          "Created At": this.$filters.formatDate(el.createdAt),
        });
      });
      const data = XLSX.utils.json_to_sheet(this.sheets);
      const wb = XLSX.utils.book_new();
      const date = new Date().toLocaleString();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "customer_report(" + date + ").xlsx");
      this.sheets = [];
    },
  },
};
