<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Landing One Section Five CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Landing One Section Five CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Landing One Section Five CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="row">
                      <div class="col-6 mb-3">
                        <label class="form-label">Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.title"
                          class="form-control"
                          placeholder="Enter title"
                        />
                      </div>

                      <div class="col-6 mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Description:</label>
                        <textarea
                          class="form-control"
                          rows="3"
                          v-model="cmsData.description"
                          placeholder="Enter description"
                        ></textarea>
                      </div>

                      <div class="col-6 mb-3">
                        <label class="form-label">Button Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.buttonText"
                          class="form-control"
                          placeholder="Enter button text"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Button Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.buttonLink"
                          class="form-control"
                          placeholder="Enter button link"
                        />
                      </div>
                      <div class="col-6 mt-3">
                        <label for="inputProductTitle" class="form-label">
                          Image One:
                          <strong>
                            [Preferred Image Size:499 × 394, Max Upload Limit:
                            1MB]
                          </strong>
                        </label>
                        <input
                          class="form-control"
                          type="file"
                          ref="cmsImage1"
                          accept="image/*"
                          @change="handleUploadImage('imageOne','imageOne','cmsImage1', $event)"
                        />
                        <div class="mt-3">
                          <img
                            style="position: relative; left: 11px"
                            v-if="preview.imageOne"
                            :src="preview.imageOne"
                            height="70"
                            width="100"
                          />
                          <img
                            style="position: relative; left: 11px"
                            v-else-if="cmsData.imageOne"
                            :src="cmsData.imageOne"
                            height="70"
                            width="100"
                          />
                        </div>
                      </div>
                      <div class="col-6 mt-3">
                        <label class="form-label">Image One Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.imageOneLink"
                          class="form-control"
                          placeholder="Enter image one link"
                        />
                      </div>
                      <div class="col-6 mt-3">
                        <label for="inputProductTitle" class="form-label">
                          Image Two:
                          <strong>
                            [Preferred Image Size: 717 × 394, Max Upload Limit:
                            1MB]
                          </strong>
                        </label>
                        <input
                          class="form-control"
                          type="file"
                          ref="cmsImage2"
                          accept="image/*"
                          @change="handleUploadImage('imageTwo','imageTwo','cmsImage2', $event)"
                        />
                        <div class="mt-3">
                          <img
                            style="position: relative; left: 11px"
                            v-if="preview.imageTwo"
                            :src="preview.imageTwo"
                            height="70"
                            width="100"
                          />
                          <img
                            style="position: relative; left: 11px"
                            v-else-if="cmsData.imageTwo"
                            :src="cmsData.imageTwo"
                            height="70"
                            width="100"
                          />
                        </div>
                      </div>
                      <div class="col-6 mt-3">
                        <label class="form-label">Image Two Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.imageTwoLink"
                          class="form-control"
                          placeholder="Enter image two link"
                        />
                      </div>
                      <div class="col-6 mt-3">
                        <label for="inputProductTitle" class="form-label">
                          Image Three:
                          <strong>
                            [Preferred Image Size: 1280 × 400, Max Upload Limit:
                            1MB]
                          </strong>
                        </label>
                        <input
                          class="form-control"
                          type="file"
                          ref="cmsImage3"
                          accept="image/*"
                          @change="handleUploadImage('imageThree','imageThree','cmsImage3', $event)"
                        />
                        <div class="mt-3">
                          <img
                            style="position: relative; left: 11px"
                            v-if="preview.imageThree"
                            :src="preview.imageThree"
                            height="70"
                            width="100"
                          />
                          <img
                            style="position: relative; left: 11px"
                            v-else-if="cmsData.imageThree"
                            :src="cmsData.imageThree"
                            height="70"
                            width="100"
                          />
                        </div>
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Image Three Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.imageThreeLink"
                          class="form-control"
                          placeholder="Enter image three link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/landing-one-section-five.js"></script>