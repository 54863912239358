<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Cart Page CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard">
                                    <i class="bx bx-home-alt"></i>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Cart Page CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <form @submit.prevent="updateCms()">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Cart Page CMS</h5>
                        <hr />
                        <div class="form-body mt-4">
                            <div class="row">
                                <div class="col-lg-12 mb-3">
                                    <div class="border border-3 p-4 rounded">
                                        <div class="row">
                                            <div class="col-12">
                                                <h5>Top Banner</h5>
                                                <hr>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label class="form-label">Top Banner Title:</label>
                                                <input type="text" v-model="cmsData.topBannerTitle" class="form-control"/>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label class="form-label">Top Banner Subtitle:</label>
                                                <input type="text" v-model="cmsData.topBannerSubtitle" class="form-control"/>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label class="form-label">Top Banner Button Text:</label>
                                                <input type="text" v-model="cmsData.topBannerButtonText" class="form-control"/>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label class="form-label">Top Banner Button Link:</label>
                                                <input type="text" v-model="cmsData.topBannerButtonLink" class="form-control"/>
                                            </div>
                                            <div class="mb-3">
                                                <div class="row">
                                                    <label for="inputProductTitle" class="form-label">
                                                        Top Banner Image:
                                                        <strong>
                                                            [Preferred Image Size: 1920X1280, Max Upload Limit: 1MB]
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="col-6">
                                                    <input class="form-control mt-3" type="file" ref="topBannerImage" accept="image/*" @change="handleUploadImage('topBannerImage', 'topBannerImage', 'topBannerImage', $event)"/>
                                                </div>
                                                <div class="mt-3">
                                                    <img style="position: relative; left: 11px" v-if="preview.topBannerImage" :src="preview.topBannerImage" height="70" />
                                                    <img style="position: relative; left: 11px" v-else-if="cmsData.topBannerImage" :src="cmsData.topBannerImage" height="70" />
                                                    <img style="position: relative; left: 11px" v-else src="../../../../assets/images/default.png" height="70" />
                                                </div>
                                            </div>
                                            <div class="col-12 mt-5">
                                                <h5>Bottom Banner</h5>
                                                <hr>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label class="form-label">Bottom Title One:</label>
                                                <input type="text" v-model="cmsData.bottomTitleOne" class="form-control"/>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label class="form-label">Bottom Subtitle One:</label>
                                                <input type="text" v-model="cmsData.bottomSubtitleOne" class="form-control"/>
                                            </div>

                                            <div class="col-6 mb-3">
                                                <label class="form-label">Bottom Button One Text:</label>
                                                <input type="text" v-model="cmsData.bottomButtonOneText" class="form-control"/>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label class="form-label">Bottom Button One Link:</label>
                                                <input type="text" v-model="cmsData.bottomButtonOneLink" class="form-control"/>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label class="form-label">Bottom Title Two:</label>
                                                <input type="text" v-model="cmsData.bottomTitleTwo" class="form-control"/>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label class="form-label">Bottom Subtitle Two:</label>
                                                <input type="text" v-model="cmsData.bottomSubtitleTwo" class="form-control"/>
                                            </div>

                                            <div class="col-6 mb-3">
                                                <label class="form-label">Bottom Button Two Text:</label>
                                                <input type="text" v-model="cmsData.bottomButtonTwoText" class="form-control"/>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label class="form-label">Bottom Button Two Link:</label>
                                                <input type="text" v-model="cmsData.bottomButtonTwoLink" class="form-control"/>
                                            </div>
                                            <div class="mb-3">
                                                <div class="row">
                                                    <label for="inputProductTitle" class="form-label">
                                                        Bottom Banner Image:
                                                        <strong>
                                                            [Preferred Image Size: 1920X1280, Max Upload Limit: 1MB]
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="col-6">
                                                    <input class="form-control mt-3" type="file" ref="bottomBannerImage" accept="image/*" @change="handleUploadImage('bottomBannerImage', 'bottomBannerImage', 'bottomBannerImage', $event)" />
                                                </div>
                                                <div class="mt-3">
                                                    <img style="position: relative; left: 11px" v-if="preview.bottomBannerImage" :src="preview.bottomBannerImage" height="70" />
                                                    <img style="position: relative; left: 11px" v-else-if="cmsData.bottomBannerImage" :src="cmsData.bottomBannerImage" height="70" />
                                                    <img style="position: relative; left: 11px" v-else src="../../../../assets/images/default.png" height="70" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-grid">
                                    <button type="submit" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script src="../js/cart-page-cms.js"></script>