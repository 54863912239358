<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Home Page Two CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Home Page Two CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Home Page Two CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label class="form-label">Your Preferable Price Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.yourPreferablePriceTitle"
                          class="form-control"
                          placeholder="Enter Your Preferable Price Title"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Flower Deals Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.flowerDealsTitle"
                          class="form-control"
                          placeholder="Enter Flower Deals Title"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Flower Deals Button Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.flowerDealsButtonText"
                          class="form-control"
                          placeholder="Enter Flower Deals Button Text"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Flower Deals Button Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.flowerDealsButtonLink"
                          class="form-control"
                          placeholder="Enter Flower Deals Button Link"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Vaporizer Deals Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.vaporizerDealsTitle"
                          class="form-control"
                          placeholder="Enter Vaporizer Deals Title"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Vaporizer Deals Button Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.vaporizerDealsButtonText"
                          class="form-control"
                          placeholder="Enter Vaporizer Deals Button Text"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Vaporizer Deals Button Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.vaporizerDealsButtonLink"
                          class="form-control"
                          placeholder="Enter Vaporizer Deals Button Link"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Top Deals Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.topDealsTitle"
                          class="form-control"
                          placeholder="Enter Top Deals Title"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Top Deals Description:</label>
                        <textarea class="form-control" v-model="cmsData.topDealsDescription" rows="3"></textarea>
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Top Deals Button Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.topDealsButtonText"
                          class="form-control"
                          placeholder="Enter Top Deals Button Text"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Top Deals Button Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.topDealsButtonLink"
                          class="form-control"
                          placeholder="Enter Top Deals Button Link"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Edibles Deals Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.ediblesDealsTitle"
                          class="form-control"
                          placeholder="Enter Edibles Deals Title"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Edibles Deals Button Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.ediblesDealsButtonText"
                          class="form-control"
                          placeholder="Enter Edibles Deals Button Text"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Edibles Deals Button Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.ediblesDealsButtonLink"
                          class="form-control"
                          placeholder="Enter Edibles Deals Button Link"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Pre Roll Deals Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.preRollDealsTitle"
                          class="form-control"
                          placeholder="Enter Pre Roll Deals Title"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Pre Roll Deals Button Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.preRollDealsButtonText"
                          class="form-control"
                          placeholder="Enter Pre Roll Deals Button Text"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Pre Roll Deals Button Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.preRollDealsButtonLink"
                          class="form-control"
                          placeholder="Enter Pre Roll Deals Button Link"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Features Products Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.featuresProductsTitle"
                          class="form-control"
                          placeholder="Enter Features Products Title"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-grid col-8">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/home-page-two-cms.js"></script>