// import { authHeader } from '../../../../../auth';

export default {
  name: "AddSlider",
  data() {
    return {
      titleOne: "",
      titleTwo: "",
      description: "",
      buttonText: "",
      buttonLink: "",
      imageFile: "",
      previewImage: null,
      addButtonText: "Create",
      addButtonIconClass: "fa-solid fa-rotate",
      addButtonClass: "btn btn-primary mt-2",
      addButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Home Slider";
  },
  methods: {
    onImageChange: function (event) {
      this.imageFile = event.target.files[0];
      let input = this.$refs.imageFile;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    validate: function () {
      if (!this.titleOne) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter title one!",
        });
        return false;
      }
      if (!this.titleTwo) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter title two!",
        });
        return false;
      }
      if (!this.description) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter description!",
        });
        return false;
      }
      if (!this.buttonText) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter button text!",
        });
        return false;
      }
      if (!this.buttonLink) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter button link!",
        });
        return false;
      }
      if (!this.imageFile) {
        this.$swal.fire({
          icon: "error",
          text: "Please upload image!",
        });
        return false;
      }
      return true;
    },
    save: async function () {
      if (this.validate() == true) {
        this.addButtonText = "Creating...";
        this.addButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.addButtonClass = "btn btn-secondary mt-2";
        this.addButtonFlag = true;
        let token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("titleOne", this.titleOne);
        formData.append("titleTwo", this.titleTwo);
        formData.append("description", this.description);
        formData.append("buttonText", this.buttonText);
        formData.append("buttonLink", this.buttonLink);
        formData.append("image", this.imageFile);
        let config = {
          method: "POST",
          url: this.$serverURL + this.$api.cms.home.homeSliderURL,
          data: formData,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        await this.$axios(config)
          .then((response) => {
            if (response.status == 201) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            }
            this.titleOne = "";
            this.titleTwo = "";
            this.description = "";
            this.buttonText = "";
            this.buttonLink = "";
            this.imageFile = "";
            this.$refs.imageFile.value = null;
            this.$router.push("/home-slider");

            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary mt-2";
            this.addButtonFlag = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });
            this.addButtonText = "Create";
            this.addButtonIconClass = "fa-solid fa-rotate";
            this.addButtonClass = "btn btn-primary mt-2";
            this.addButtonFlag = false;
          });
      }
    },
  },
};
