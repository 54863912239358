<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Event List</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Event List</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              @click="$router.push('/add-event')"
            >
              <i class="bx bxs-plus-square"></i>Add New Event
            </button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Event List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Title</th>
                  <th>Image</th>
                  <th>Address</th>
                  <th>Event Date</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in contentList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ (row.title.length > 25) ? row.title.slice(0, 25) + '...' : row.title }}</td>
                  <td v-if="row.image != null">
                    <img :src="row.image" height="50" width="80" />
                  </td>
                  <td v-else>
                    <img src="/core/assets/images/blog.png" width="50" height="80" />
                  </td>
                  <td>{{ row.address }}</td>
                  <td>{{ $filters.formatDate(row['eventDate']) }}</td>
                  <td>{{ $filters.formatDate(row['createdAt']) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <router-link
                        :to="{ name: 'UpdateEvent', params: { id: row.id } }"
                        class="edit-button"
                      >
                        <i class="bx bxs-edit text-white"></i>
                      </router-link>
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteContent(row.id)"
                        class="ms-3 delete-button"
                      >
                        <i class="bx bxs-trash text-white"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Title</th>
                  <th>Image</th>
                  <th>Address</th>
                  <th>Event Date</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "/public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>

<script src="../js/events.js"></script>