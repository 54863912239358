import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "EditDeliveryPage",
  components: { Editor },
  data() {
    return {
      setURL: this.$serverURL + this.$api.deliveryPages.requestURL,
      editor: ClassicEditor,
      editorConfig: {
        ckfinder: {
          uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
        },
      },
      deliveryPageId: "",
      address: "",
      center: { lat: 0, lng: 0 },
      markers: [{ position: { lat: 0, lng: 0 } }],

      previewImage: "",
      bannerBackgroundImage: "",
      title: "",
      subTitle: "",
      description: "",
      buttonText: "",
      buttonLink: "",
      lattitude: "",
      longitude: "",
      bodyTitle: "",
      bodyDescription: "",
      urlSlug: "",

      pageTitle: "",
      metaDescription: "",
      metaKeyword: "",

      updateButtonText: "Update",
      updateButtonIconClass: "fa-solid fa-rotate",
      updateButtonClass: "btn btn-primary",
      updateButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Edit Delivery Page";
    this.deliveryPageId = this.$route.params.id;
    await this.getDeliveryPageDetails();

    var scripts = [
      "/core/assets/js/jquery.min.js",
      "/core/assets/plugins/simplebar/js/simplebar.min.js",
      "/core/assets/plugins/metismenu/js/metisMenu.min.js",
      "./core/assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js",
      "/core/assets/plugins/smart-wizard/js/jquery.smartWizard.min.js",
      "/core/assets/js/form-wizard.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  methods: {
    async setPlace(e) {
      this.lattitude = e.latLng.lat();
      this.longitude = e.latLng.lng();
      const address = await this.getAddressFromLatLng({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
      if (address && address?.results?.length > 0)
        this.address = address?.results?.[0]?.formatted_address;
    },
    handleAddressLatLng(locationData) {
      let location = locationData.formatted_address;
      let locationSplit = location.split(",");
      if (locationSplit[0].trim() != locationData.name) {
        location = locationData.name + ", " + location;
      }
      this.lattitude = locationData.geometry.location.lat();
      this.longitude = locationData.geometry.location.lng();
      this.center = { lat: this.lattitude, lng: this.longitude };
      this.markers = [
        { position: { lat: this.lattitude, lng: this.longitude } },
      ];
      this.address = location;
    },
    onImageChange(event) {
      this.bannerBackgroundImage = event.target.files[0];
      let input = this.$refs.image;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    getDeliveryPageDetails: async function () {
      const data = await this.getDataList(
        this.setURL + "/" + this.$route.params.id
      );
      if (data?.length > 0) {
        for (const [key, value] of Object.entries(data?.[0]))
          this[`${key}`] = value;
        if (data?.[0]?.lattitude && data?.[0]?.longitude) {
          this.center.lat = data?.[0]?.lattitude
            ? parseFloat(data?.[0].lattitude)
            : 33.4251595;
          this.center.lng = data?.[0]?.longitude
            ? parseFloat(data?.[0].longitude)
            : -112.0350472;
          this.markers[0].position.lat = parseFloat(data?.[0].lattitude);
          this.markers[0].position.lng = parseFloat(data?.[0].longitude);
        }
      } else {
        this.$swal.fire({
          icon: "error",
          text: "Something went wrong. Please try again!",
        });
      }
    },
    validate: function () {
      if (!this.title) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page title!",
        });
        return false;
      }
      if (!this.subTitle) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page subtitle!",
        });
        return false;
      }
      if (!this.description) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page banner description!",
        });
        return false;
      }
      if (!this.bannerBackgroundImage) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page banner image!",
        });
        return false;
      }
      if (!this.buttonText) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page banner button text!",
        });
        return false;
      }
      if (!this.buttonLink) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page banner button link!",
        });
        return false;
      }
      if (!this.address) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter address!",
        });
        return false;
      }
      if (!this.lattitude || !this.longitude) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter address correctly!",
        });
        return false;
      }
      // if (!this.lattitude) {
      //     this.$swal.fire({
      //         icon: "error",
      //         text: "Please enter delivery page lattitude!"
      //     });
      //     return false;
      // }
      // if (!this.longitude) {
      //     this.$swal.fire({
      //         icon: "error",
      //         text: "Please enter delivery page longitude!"
      //     });
      //     return false;
      // }
      if (!this.bodyTitle) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page body title!",
        });
        return false;
      }
      if (!this.bodyDescription) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page body description!",
        });
        return false;
      }
      if (
        this.urlSlug &&
        !this.urlSlug.startsWith("https://jarscannabis.com/delivery/")
      ) {
        this.$swal.fire({
          icon: "error",
          text: "URL should be start with 'https://jarscannabis.com/delivery/' !",
        });
        return false;
      }

      if (!this.metaDescription) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page meta description!",
        });
        return false;
      }
      if (!this.metaKeyword) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page meta keyword!",
        });
        return false;
      }
      return true;
    },
    updatePost: async function () {
      if (this.validate()) {
        this.updateButtonText = "Updating...";
        this.updateButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.updateButtonClass = "btn btn-secondary";
        this.updateButtonFlag = true;
        try {
          this.$swal.showLoading();
          let formData = new FormData();
          formData.append("title", this.title);
          formData.append("subTitle", this.subTitle);
          formData.append("description", this.description);
          formData.append("bannerBackgroundImage", this.bannerBackgroundImage);
          formData.append("buttonText", this.buttonText);
          formData.append("buttonLink", this.buttonLink);
          formData.append("address", this.address);
          formData.append("lattitude", this.lattitude);
          formData.append("longitude", this.longitude);
          formData.append("bodyTitle", this.bodyTitle);
          formData.append("bodyDescription", this.bodyDescription);
          formData.append(
            "urlSlug",
            this.urlSlug
              ? this.urlSlug
              : "https://jarscannabis.com/delivery/" + this.title
          );
          formData.append(
            "pageTitle",
            this.pageTitle ? this.pageTitle : this.title
          );
          formData.append("metaDescription", this.metaDescription);
          formData.append("metaKeyword", this.metaKeyword);

          const token = localStorage.getItem("token");
          let config = {
            method: "PATCH",
            url: this.setURL + "/" + this.deliveryPageId,
            data: formData,
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "multipart/form-data",
            },
          };
          await this.postDataToBackend(config);
          this.$swal.hideLoading();
          this.$router.push("/delivery-pages");
          this.updateButtonText = "Update";
          this.updateButtonIconClass = "fa-solid fa-rotate";
          this.updateButtonClass = "btn btn-primary";
          this.updateButtonFlag = false;
        } catch (error) {
          this.$swal.hideLoading();
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again! " + error,
          });
          this.updateButtonText = "Update";
          this.updateButtonIconClass = "fa-solid fa-rotate";
          this.updateButtonClass = "btn btn-primary";
          this.updateButtonFlag = false;
        }
      }
      // if (this.validate() == true) {
      //     let token = localStorage.getItem('token');
      //     const formData = new FormData();
      //     formData.append("title", this.singleContent.title);
      //     formData.append("urlSlug", this.singleContent.urlSlug ? this.singleContent.urlSlug : this.singleContent.title);
      //     formData.append("shortDetails", this.singleContent.shortDetails);
      //     formData.append("longDetails", this.singleContent.longDetails);
      //     formData.append("categoryId", this.category);
      //     formData.append("categoryName", this.category);
      //     formData.append("photo", this.imageFile ? this.imageFile : this.singleContent.photo);
      //     formData.append("relatedPosts", JSON.stringify(this.selectedRelatedPosts));
      //     formData.append("tags", "");
      //     let config = {
      //         method: "PATCH",
      //         url: this.$serverURL + this.$api.blog.postURL + '/' + this.blogId,
      //         data: formData,
      //         headers: {
      //             "Authorization": "Bearer " + token,
      //             "Content-Type": "multipart/form-data"
      //         }
      //     };
      //     await this.$axios(config).then((response) => {
      //         if (response.status == 200) {
      //             this.$swal.fire({
      //                 toast: true,
      //                 position: 'top-end',
      //                 text: response.data.message,
      //                 icon: "success",
      //                 showConfirmButton: false,
      //                 timer: 3000,
      //                 animation: false,
      //                 timerProgressBar: true,
      //                 didOpen: (toast) => {
      //                     toast.addEventListener('mouseenter', this.$swal.stopTimer)
      //                     toast.addEventListener('mouseleave', this.$swal.resumeTimer)
      //                 }
      //             });
      //             this.photo = '';
      //             this.imageFile = '';
      //             this.$refs.imageFile.value = null;
      //             this.previewImage = '';
      //         }
      //     }).catch(error => {
      //         console.log(error);
      //         this.$swal.fire({
      //             icon: "error",
      //             text: "Something went wrong. Please try again!"
      //         });
      //     });
      //     this.$router.push('/blog-post');
      // }
    },
  },
};
