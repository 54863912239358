import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Editor from "@tinymce/tinymce-vue";
import Loading from "vue-loading-overlay";

export default {
  name: "AddDeliveryPage",
  components: { Editor, Loading },
  data() {
    return {
      isLoading: false,
      setURL: this.$serverURL + this.$api.deliveryPages.requestURL,
      editor: ClassicEditor,
      editorConfig: {
        ckfinder: {
          uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
        },
      },
      address: "",
      center: { lat: 33.4251595, lng: -112.0350472 },
      markers: [{ position: { lat: 33.4251595, lng: -112.0350472 } }],
      previewImage: "",

      bannerBackgroundImage: "",
      title: "",
      subTitle: "",
      description: "",
      buttonText: "",
      buttonLink: "",
      lattitude: "",
      longitude: "",
      bodyTitle: "",
      bodyDescription: "",
      urlSlug: "",

      pageTitle: "",
      metaDescription: "",
      metaKeyword: "",

      addButtonText: "Create",
      addButtonIconClass: "fa-solid fa-rotate",
      addButtonClass: "btn btn-primary",
      addButtonFlag: false,
    };
  },
  async created() {
    document.title = "JARS - Add Delivery Page";
    var scripts = [
      "/core/assets/js/jquery.min.js",
      "/core/assets/plugins/simplebar/js/simplebar.min.js",
      "/core/assets/plugins/metismenu/js/metisMenu.min.js",
      "./core/assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js",
      "/core/assets/plugins/smart-wizard/js/jquery.smartWizard.min.js",
      "/core/assets/js/form-wizard.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  methods: {
    async setPlace(e) {
      this.lattitude = e.latLng.lat();
      this.longitude = e.latLng.lng();
      const address = await this.getAddressFromLatLng({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
      if (address && address?.results?.length > 0)
        this.address = address?.results?.[0]?.formatted_address;
    },
    handleAddressLatLng(locationData) {
      let location = locationData.formatted_address;
      let locationSplit = location.split(",");
      if (locationSplit[0].trim() != locationData.name) {
        location = locationData.name + ", " + location;
      }
      this.lattitude = locationData.geometry.location.lat();
      this.longitude = locationData.geometry.location.lng();
      this.center = { lat: this.lattitude, lng: this.longitude };
      this.markers = [
        { position: { lat: this.lattitude, lng: this.longitude } },
      ];
      this.address = location;
    },
    onImageChange(event) {
      this.bannerBackgroundImage = event.target.files[0];
      let input = this.$refs.image;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    validate: function () {
      if (!this.title) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page title!",
        });
        return false;
      }
      if (!this.subTitle) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page subtitle!",
        });
        return false;
      }
      if (!this.description) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page banner description!",
        });
        return false;
      }
      if (!this.bannerBackgroundImage) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page banner image!",
        });
        return false;
      }
      if (!this.buttonText) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page banner button text!",
        });
        return false;
      }
      if (!this.buttonLink) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page banner button link!",
        });
        return false;
      }
      if (!this.address) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter address!",
        });
        return false;
      }
      if (!this.lattitude || !this.longitude) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter address correctly!",
        });
        return false;
      }
      if (!this.bodyTitle) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page body title!",
        });
        return false;
      }
      if (!this.bodyDescription) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page body description!",
        });
        return false;
      }
      if (
        this.urlSlug &&
        !this.urlSlug.startsWith("https://jarscannabis.com/delivery/")
      ) {
        this.$swal.fire({
          icon: "error",
          text: "URL should be start with 'https://jarscannabis.com/delivery/' !",
        });
        return false;
      }
      // if (!this.pageTitle) {
      //     this.$swal.fire({
      //         icon: "error",
      //         text: "Please enter delivery page title (for SEO)!"
      //     });
      //     return false;
      // }
      if (!this.metaDescription) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page meta description!",
        });
        return false;
      }
      if (!this.metaKeyword) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter delivery page meta keyword!",
        });
        return false;
      }
      return true;
    },
    save: async function () {
      if (this.validate()) {
        this.addButtonText = "Creating...";
        this.addButtonIconClass = "fa-solid fa-rotate fa-spin";
        this.addButtonClass = "btn btn-secondary";
        this.addButtonFlag = true;
        this.isLoading = true;
        try {
          this.$swal.showLoading();
          let formData = new FormData();
          formData.append("title", this.title);
          formData.append("subTitle", this.subTitle);
          formData.append("description", this.description);
          formData.append("bannerBackgroundImage", this.bannerBackgroundImage);
          formData.append("buttonText", this.buttonText);
          formData.append("buttonLink", this.buttonLink);
          formData.append("address", this.address);
          formData.append("lattitude", this.lattitude);
          formData.append("longitude", this.longitude);
          formData.append("bodyTitle", this.bodyTitle);
          formData.append("bodyDescription", this.bodyDescription);
          formData.append(
            "urlSlug",
            this.urlSlug
              ? this.urlSlug
              : "https://jarscannabis.com/delivery/" + this.title
          );
          formData.append(
            "pageTitle",
            this.pageTitle ? this.pageTitle : this.title
          );
          formData.append("metaDescription", this.metaDescription);
          formData.append("metaKeyword", this.metaKeyword);

          const token = localStorage.getItem("token");
          let config = {
            method: "POST",
            url: this.setURL,
            data: formData,
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "multipart/form-data",
            },
          };
          await this.postDataToBackend(config);
          this.$swal.hideLoading();
          this.$router.push("/delivery-pages");
          this.isLoading = false;
          this.addButtonText = "Create";
          this.addButtonIconClass = "fa-solid fa-rotate";
          this.addButtonClass = "btn btn-primary";
          this.addButtonFlag = false;
        } catch (error) {
          this.isLoading = false;
          this.$swal.hideLoading();
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again! " + error,
          });
          this.addButtonText = "Create";
          this.addButtonIconClass = "fa-solid fa-rotate";
          this.addButtonClass = "btn btn-primary";
          this.addButtonFlag = false;
        }
      }
    },
  },
};
