export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.landingUrlSlug,
            cmsData: {
                urlSlugOne: "",
                urlSlugTwo: "",
                urlSlugThree: "",
            },

        }
    },
    async created() {
        document.title = "Jars - Landing Url Slug Settings";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {
                    urlSlugOne: "",
                    urlSlugTwo: "",
                    urlSlugThree: "",
                };
            }
            else {
                this.cmsData = {
                    urlSlugOne: "",
                    urlSlugTwo: "",
                    urlSlugThree: "",
                };
            }
        },

        async updateCms() {
            try {
                const data = {
                    urlSlugOne: this.cmsData?.urlSlugOne,
                    urlSlugTwo: this.cmsData?.urlSlugTwo,
                    urlSlugThree: this.cmsData?.urlSlugThree,
                }
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },

        copyLink: function (data) {
            let path = "";
            if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
                path = `http://localhost:3002/landing/${data}`;
            } else {
                path = `https://jarscannabis.com/landing/${data}`;
            }
            navigator.clipboard.writeText(path).then(function () {
                alert("Link copied...");
            }, function (error) {
                console.log(error);
            });
        },


    }
}